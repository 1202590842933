import { ErrorMessage } from "formik";
import React, { useContext } from "react";
import { AppContext } from "../../../context/app.context";
import { getKeyFromString } from "../../../utils/common.helper";
import "./style.scss";

interface Props {
  name: string;
  message?: string;
  customValidation?: boolean;
}

const ValidationMessage: React.FC<Props> = ({
  name,
  message,
  customValidation,
}) => {
  const { descEnFile, descFile }: any = useContext(AppContext);
  let textKey,
    value = customValidation ? message : "";

  if (customValidation && message) {
    textKey = getKeyFromString(message);
    value = message;
  }
  if (textKey) {
    value =
      descFile[textKey.toLowerCase()] ||
      descEnFile[textKey.toLowerCase()] ||
      value;
    console.log("cust", textKey, value);
  }

  const renderMsg = (msg: string) => {
    let textKey = getKeyFromString(msg);
    let value = msg;
    if (textKey) {
      value =
        descFile[textKey.toLowerCase()] ||
        descEnFile[textKey.toLowerCase()] ||
        value;
    }
    console.log("normal", textKey, value);

    return <div>{value}</div>;
  };
  return (
    <div className="validationError">
      <div className="error">
        {customValidation ? (
          value
        ) : (
          <ErrorMessage
            name={name}
            render={(errorMessage: string) => {
              return renderMsg(errorMessage);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ValidationMessage;

import { Plugins } from "@capacitor/core";
import { IonContent, IonImg, IonPage, useIonRouter } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Loader from "../../../components/common/loader";
import RegisterHeader from "../../../components/common/register-header";
import ExpertCard from "../../../components/expertcard";
import LanguageAppText from "../../../components/languageapptext";
import { INIT_STATE_ONBOARD } from "../../../config/appconfig";
import Messages from "../../../config/messages";
import { AppContext } from "../../../context/app.context";
import { getProgramList } from "../../../services/program.service";
import "./style.scss";
import Logo from "../../../assets/images/ovante-logo.png";
const { App } = Plugins;

const Experts: React.FC = () => {
  const ionRouter = useIonRouter();
  const { toastMessage, initAppState }: any = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [currentProgram, setCurrentProgram]: any = useState([]);
  const [progrmas, setPrograms] = useState([]);
  const [completedProgrmas, setCompletedProgram] = useState([]);
  const [recommended, setRecommended]: any = useState(null);
  const [title, setTitle]: any = useState("");
  const [isAllCompleted, setIsAllCompleted] = useState(false);
  const history: any = useHistory();
  const location = useLocation();

  useEffect(() => {
    getData();
    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      App.exitApp();
    });
  };

  const getData = async () => {
    const resp = await getProgramList();
    // const recommendedResp: any = await getRecommendation();
    // const recommendedProgResp: any = await getRecommendedProgramList();

    // console.log(resp);
    if (!resp.error) {
      const expertList = resp.experts;
      const activeProgram = expertList.filter((item: any) => {
        return item.isActive == 1 && item.isComplete != 1;
      });
      const completedProgram = expertList.filter((item: any) => {
        return item.isComplete == 1;
      });
      const otherProgram = expertList.filter((item: any) => {
        return item.isComplete != 1 && item.isActive != 1;
      });
      // console.log("activeProgram", activeProgram);
      // console.log("completedProgram", completedProgram);
      // console.log("otherProgram", otherProgram);
      if (activeProgram && activeProgram.length > 0) {
        setTitle({
          titleKey: "current_program",
          titleValue: "Your current program",
        });
        setCurrentProgram(activeProgram);
        setPrograms(otherProgram);
        setCompletedProgram(completedProgram);
      } else {
        if (completedProgram.length == expertList.length) {
          setTitle({
            titleKey: "completed_all_programs",
            titleValue: "You've completed all the programs!",
          });
          setIsAllCompleted(true);
          setCompletedProgram(completedProgram);
        } else {
          setTitle("");
          setRecommended(otherProgram.shift());
          setPrograms(otherProgram);
          setCompletedProgram(completedProgram);
          // if (recommendedProgResp && recommendedProgResp.length) {
          //   for (let index = 0; index < recommendedProgResp.length; index++) {
          //     const element = recommendedProgResp[index];
          //     const isComplete = completedProgram.some(function (arrVal: any) {
          //       return element === arrVal.id;
          //     });
          //     if (!isComplete) {
          //       let recommendedProgram = otherProgram.filter((item: any) => {
          //         return item.id === element;
          //       });
          //       if (recommendedProgram && recommendedProgram.length > 0) {
          //         setTitle({
          //           titleKey: "recommended_for_you",
          //           titleValue: "Recommended for you",
          //         });
          //         setRecommended(recommendedProgram[0]);
          //         let index = otherProgram.findIndex((item: any) => {
          //           return item.id === recommendedProgram[0].id;
          //         });
          //         if (index !== -1) {
          //           otherProgram.splice(index, 1);
          //         }
          //         setPrograms(otherProgram);
          //         setCompletedProgram(completedProgram);
          //       } else {
          //         setTitle("");
          //         setRecommended(otherProgram.shift());
          //         setPrograms(otherProgram);
          //         setCompletedProgram(completedProgram);
          //       }
          //       break;
          //     }
          //   }
          // } else {
          //   setTitle("");
          //   setRecommended(otherProgram.shift());
          //   setPrograms(otherProgram);
          //   setCompletedProgram(completedProgram);
          // }
        }
      }
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
    setLoading(false);
  };

  const onCurrentProgram = (data: any) => {
    history.push({
      pathname: `/learnings/${data.id}`,
    });
  };

  const onOtherProgram = (data: any, isOtherActive?: boolean) => {
    if (isOtherActive) {
      history.push({
        pathname: `/learnings/${data.id}`,
      });
    } else {
      if (initAppState === INIT_STATE_ONBOARD) {
        history.push({
          pathname: "/programintro",
          state: {
            program: data,
            isCurrent:
              currentProgram && currentProgram.length > 0 ? true : false,
          },
        });
        return;
      }
      history.push({
        pathname: "/programintro",
        state: {
          program: data,
          currentProgram,
          isCurrent: currentProgram && currentProgram.length > 0 ? true : false,
        },
      });
    }
  };

  const onRecommendedProgram = (data: any) => {
    if (initAppState === INIT_STATE_ONBOARD) {
      history.push({
        pathname: "/programintro",
        state: {
          program: data,
          isCurrent: currentProgram && currentProgram.length > 0 ? true : false,
        },
      });
      return;
    }
    history.push({
      pathname: "/programintro",
      state: {
        program: data,
        currentProgram,
        isCurrent: currentProgram && currentProgram.length > 0 ? true : false,
      },
    });
  };

  const onCompletedProgram = (data: any) => {
    history.push({
      pathname: `/review/program/${data.id}`,
    });
  };

  return (
    <IonPage className="page-experts">
      {/* <RegisterHeader pageName={"Experts"} textKey={"experts"}></RegisterHeader> */}
      <IonContent className="page-content pt-0 pb-85">
        <IonImg src={Logo} style={{ height: 80, width: 120 }} />
        {loading ? (
          <Loader></Loader>
        ) : (
          <div>
            <div
              className={`mt-10 ${
                process.env.REACT_APP_WEB ? "bg-white" : "bg-light-orange"
              }`}
            >
              <LanguageAppText
                tag={"div"}
                className="page-content__title mb-5"
                value={title.titleValue || title}
                textKey={title.titleKey || ""}
              />
              {currentProgram && currentProgram.length > 0 && (
                <LanguageAppText
                  tag={"div"}
                  className="page-content__info text-gray mb-10"
                  value={"This is who you’re currently working with"}
                  textKey={"currently_working_with"}
                />
              )}
              {currentProgram &&
                currentProgram.length > 0 &&
                currentProgram.map((item: any, index: number) => {
                  return (
                    <div key={index} className="page-content__current-expert">
                      <ExpertCard
                        isCurrent
                        data={item}
                        onButtonClick={(data: any) => {
                          onCurrentProgram(data);
                        }}
                      />
                    </div>
                  );
                })}
              {recommended && (
                <ExpertCard
                  isRecommended
                  data={recommended}
                  onButtonClick={(data: any, isOtherActive?: boolean) => {
                    onRecommendedProgram(data);
                  }}
                />
              )}
            </div>
            {!isAllCompleted && (
              <div className="page-content__expert-list">
                <LanguageAppText
                  tag={"div"}
                  className="page-content__title mb-15"
                  value={"Other programs"}
                  textKey={"other_programs"}
                />
                {progrmas &&
                  progrmas.length > 0 &&
                  progrmas.map((item: any, index: number) => {
                    return (
                      <ExpertCard
                        key={index}
                        data={item}
                        onButtonClick={(data: any, isOtherActive?: boolean) => {
                          onOtherProgram(data, isOtherActive);
                        }}
                      />
                    );
                  })}

                {completedProgrmas &&
                  completedProgrmas.length > 0 &&
                  completedProgrmas.map((item: any, index: number) => {
                    return (
                      <ExpertCard
                        isCompleted
                        key={index}
                        data={item}
                        onButtonClick={(data: any) => {
                          onCompletedProgram(data);
                        }}
                      />
                    );
                  })}
              </div>
            )}

            {isAllCompleted &&
              completedProgrmas &&
              completedProgrmas.length > 0 &&
              completedProgrmas.map((item: any, index: number) => {
                return (
                  <ExpertCard
                    isCompleted
                    key={index}
                    data={item}
                    onButtonClick={(data: any) => {
                      onCompletedProgram(data);
                    }}
                  />
                );
              })}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Experts;

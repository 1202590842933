import {
  IonCard,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ButtonComponent from "../../components/common/button-component";
import RegisterHeader from "../../components/common/register-header";
import ExtraActivityCard from "../../components/extraactivitycard";
import { useHistory, useLocation } from "react-router";
import BusinessRecoveryPlan from "../../assets/images/icons/ic_businessrecoveryplan.svg";
import Coin from "../../assets/images/coins.png";
import Community from "../../assets/images/community.svg";
import "./style.scss";
import Loader from "../../components/common/loader";
import LanguageText from "../../components/languagetext";
import LanguageAppText from "../../components/languageapptext";
import { isConnectedToInternet } from "../../services/network.service";
import { Capacitor } from "@capacitor/core";
import { getFormattedUrl } from "../../utils/common.helper";
import { AppContext } from "../../context/app.context";
import RewardAnimationData from "../../assets/reward.json";

const ActivityRewards: React.FC = () => {
  const { state }: any = useLocation();
  const { playLottieAnimation, stopLottieAnimation }: any =
    useContext(AppContext);
  const history = useHistory();
  const data: any = state.data;
  const programId: any = state.programId;
  const activityTitle: any = state.activityTitle;
  const pendingActivities: any = state.pendingActivities;
  const programCompleted: any = state.programCompleted || false;
  const [loading, setLoading] = useState(true);
  const [reward, setReward]: any = useState(null);
  const [rewardIndex, setRewardIndex]: any = useState(0);
  const [rewardData, setRewardData]: any = useState([]);

  useEffect(() => {
    getRewardDate();

    return () => {
      stopLottieAnimation();
    };
  }, []);

  const getRewardDate = () => {
    let awardData = JSON.parse(data);
    let rewardObj = awardData[0];
    setRewardIndex(0);
    setReward(rewardObj);
    setRewardData(awardData);
    // if (awardData.length > 1) {
    //   awardData.shift();
    //   awardData.map((award: any, index: number) => {
    //     let timeout = 2000 * (index + 1);
    //     setTimeout(() => {
    //       setReward(award);
    //     }, timeout);
    //   });
    // }
    if (rewardObj && rewardObj.type === "coins") {
      playLottieAnimation(RewardAnimationData);
    }
    setLoading(false);
  };
  const onContinueClick = () => {
    stopLottieAnimation();
    if (rewardIndex + 1 === rewardData.length) {
      history.push({
        pathname: `/activitycomplete`,
        state: {
          programId: programId,
          activityTitle: activityTitle,
          pendingActivities: pendingActivities,
          programCompleted: programCompleted,
        },
      });
    } else {
      if (rewardData[rewardIndex + 1].type === "coins") {
        playLottieAnimation(RewardAnimationData);
      }
      setReward(rewardData[rewardIndex + 1]);
      setRewardIndex(rewardIndex + 1);
    }
  };
  return (
    <IonPage
      className={`page-tool large-header ${
        reward
          ? reward.type == "coins"
            ? "abstract-img-blue"
            : reward.type == "badge"
            ? "abstract-img-green"
            : "abstract-img-purple"
          : ""
      }`}
    >
      <RegisterHeader
        pageName={
          reward
            ? reward.type == "coins"
              ? "You've earned coins!"
              : reward.type == "badge"
              ? "Badge unlocked"
              : "Tools unlocked"
            : ""
        }
        textKey={
          reward
            ? reward.type == "coins"
              ? "earned_coins"
              : reward.type == "badge"
              ? "badge_unlocked"
              : "tool_unlocked"
            : ""
        }
        backBtn={true}
      ></RegisterHeader>
      <IonContent className="page-content pb-0">
        <div className="reward-content">
          {loading ? (
            <Loader></Loader>
          ) : (
            <div>
              {reward.type == "coins" ? (
                <div>
                  <IonImg src={Coin} className="reward__img"></IonImg>
                  <LanguageAppText
                    tag={"div"}
                    className="reward__title"
                    value={`${reward.metadata?.value} coins earned`}
                    textKey={"earned_activity_coin"}
                    metaData={{ activityCoins: reward.metadata?.value }}
                  />
                  <LanguageAppText
                    tag={"p"}
                    className="reward__info"
                    value={`You now have a total of ${
                      reward.metadata?.earnedCoins
                    } ${" "} coins! Use your <br /> coins to redeem any of our rewards.`}
                    textKey={"total_coins_available"}
                    metaData={{
                      userCoinsAvailable: reward.metadata?.earnedCoins,
                    }}
                  />
                  {/* <p className="reward__info">
                    You now have a total of {reward.metadata?.earnedCoins}{" "}
                    coins! Use your <br />
                    coins to redeem any of our rewards.
                  </p> */}
                </div>
              ) : reward.type == "badge" ? (
                <div>
                  {isConnectedToInternet() ? (
                    <IonImg
                      src={
                        reward.metadata?.image
                          ? getFormattedUrl(reward.metadata?.image.uri)
                          : ""
                      }
                      className="reward__img"
                    ></IonImg>
                  ) : (
                    <IonImg
                      src={Capacitor.convertFileSrc(reward.metadata?.localFile)}
                      className="reward__img"
                    ></IonImg>
                  )}
                  <div className="reward__title">
                    {reward.metadata?.badgeName}
                  </div>
                  <p className="reward__info">
                    You’ve completed your first activity!
                  </p>
                </div>
              ) : (
                <div>
                  <div className="capsul-card__prevent-overflow">
                    <IonCard className="ion-no-margin capsul-card capsul-card__nothum mb-20 capsul-card__generator">
                      <IonItem className="ion-block ion-text-center">
                        <IonLabel className="capsul-card__label ">
                          {/* <span className="text-green font-medium">
                            Whats App tool
                          </span> */}
                          <LanguageText
                            tag={"span"}
                            className="text-green font-medium"
                            value={reward.metadata?.name}
                          ></LanguageText>
                        </IonLabel>
                      </IonItem>

                      {/* <IonItem className="ion-text-center">
                        <div className="user_group">
                          <div className="user_group_img">
                            <img
                              src="../../../assets/images/users/user1.png"
                              className=""
                            />
                            <img
                              src="../../../assets/images/users/user1.png"
                              className="user_group_overlap_1"
                            />
                            <img
                              src="../../../assets/images/users/user1.png"
                              className="user_group_overlap_2"
                            />
                          </div>
                        </div>
                        <LanguageAppText
                          tag={"div"}
                          className="size-8 text-orange font-medium"
                          value={"+423 earned this week"}
                          textKey={"activity_unlocked_count"}
                          metaData={{ userActivityUnlockedCount:+423 }}
                        /> 
                         <div className="size-8 text-orange font-medium">
                          +423 earned this week
                        </div> 
                      </IonItem> */}
                    </IonCard>
                    <div className="capsul-card__top-img">
                      {isConnectedToInternet() ? (
                        <IonImg
                          src={
                            reward.metadata?.iconImage
                              ? getFormattedUrl(
                                  JSON.parse(reward.metadata?.iconImage).uri
                                )
                              : ""
                          }
                        ></IonImg>
                      ) : (
                        <IonImg
                          src={Capacitor.convertFileSrc(
                            reward.metadata?.localFile
                          )}
                        ></IonImg>
                      )}
                    </div>
                  </div>

                  <LanguageText
                    tag={"div"}
                    className="reward__title"
                    value={reward.metadata?.name}
                  ></LanguageText>
                  {/* <div className="reward__title">Whats App tool</div> */}
                  <LanguageText
                    tag={"p"}
                    className="reward__info"
                    value={reward.metadata?.description}
                  ></LanguageText>
                  {/* <p className="reward__info">
                    Use this tool to customize and create a sales pitch for a
                    new customer, a new product, or new promotion. You can
                    access this via the “Tools” section
                  </p> */}
                </div>
              )}
              <div className="stretch-container bg-light-orange bottom-box">
                <ButtonComponent
                  onClick={onContinueClick}
                  name="Continue"
                  textKey={"continue"}
                ></ButtonComponent>
              </div>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ActivityRewards;

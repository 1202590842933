import { Capacitor, Plugins } from "@capacitor/core";
import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import Loader from "../../components/common/loader";
import RegisterHeader from "../../components/common/register-header";

import VideoPlayer from "../../components/videoplayer";

import {
  getStoryDetails,
  getWatchCommunityVideo,
} from "../../services/community.service";
import { getFormattedUrl } from "../../utils/common.helper";
import RecorderVideo from "../recordedvideo";
import "./style.scss";

let isFullVideo = false,
  isCommentVideo: any;
const CommunityStory: React.FC = () => {
  let { id }: any = useParams();
  const [textValue, setTextValue] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [storyDetails, setStoryDetails]: any = useState(null);
  const [likeCount, setLikeCount] = useState(0);
  const [commentVideo, setCommentVideo]: any = useState(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const history: any = useHistory();

  useEffect(() => {
    fetchData();
    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
      isFullVideo = false;
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      if (isFullVideo) {
        isFullVideo = false;
        setIsFullScreen(false);
        if (isCommentVideo) {
          isCommentVideo = null;
          setCommentVideo(null);
        }
      } else {
        history.goBack();
      }
    });
  };

  const fetchData = async () => {
    const response: any = await getStoryDetails(id);
    if (!response.error) {
      setStoryDetails(response);
      setLikeCount(response.likeCount);
    }
    setLoading(false);
    // await getComments();
  };

  // const getComments = async (event?: any) => {
  //   if (!isFirstLoad && (lastCommentRecieved || isCommentLoading)) return;
  //   setIsCommentLoading(true);
  //   const commentResp: any = await getStoryComments({
  //     storyId: parseInt(id),
  //     lastCommentId: lastCommentId,
  //   });
  //   if (!commentResp.error) {
  //     if (event) {
  //       event.target.complete();
  //     }
  //     if (commentResp.length > 0) {
  //       setLastCommentId(commentResp[commentResp.length - 1].id);
  //       setComments(comments.concat(commentResp));
  //     } else {
  //       if (event) {
  //         event.target.disabled = true;
  //       }
  //       setLastCommentRecieved(true);
  //     }
  //   }
  //   // console.log(commentResp[commentResp.length - 1].id, lastCommentId)

  //   setIsCommentLoading(false);
  //   setIsFirstLoad(false);
  // };

  const onChangeText = (text: string) => {
    setTextValue(text);
  };

  // const storyLike = async () => {
  //   const response: any = await makeStoryLike({
  //     id: parseInt(id),
  //   });

  //   if (!response.error) {
  //     if (response == "liked") {
  //       let likes = likeCount ? likeCount + 1 : 1;
  //       setLikeCount(likes);
  //     } else {
  //       let likes = likeCount && likeCount > 0 ? likeCount - 1 : 0;
  //       setLikeCount(likes);
  //     }
  //     return true;
  //   }
  //   return false;
  // };

  const getWatchedCommunityVideo = () => {
    if (!isVideoWatched) {
      setIsVideoWatched(true);
      getWatchCommunityVideo(parseInt(id));
    }
  };

  return (
    <>
      <IonPage
        className="page-community-story"
        style={isFullScreen ? { backgroundColor: "black" } : {}}
      >
        {!isFullScreen && (
          <div className="page-community-story__header">
            <RegisterHeader
              pageName="Community Story"
              backBtn={true}
              textKey={"community_story"}
            ></RegisterHeader>
            {/* <IonButton className="page-community__share-btn ion-button__transparent-with-icon">
              <IonImg src={ShareIcon} className="share-btn" />
            </IonButton> */}
          </div>
        )}
        <IonContent
          fullscreen={true}
          className="page-content pb-0"
          style={isFullScreen ? { maxWidth: "none" } : {}}
        >
          {loading ? (
            <Loader customClass="loader-page-center" />
          ) : (
            <>
              {!isFullScreen && (
                <div className="mb-10 page-content__title">
                  {storyDetails.userName
                    ? `${storyDetails.userName}'s Story`
                    : storyDetails.title}
                </div>
              )}
              <div className="community-story__container">
                <div className="community-story__video-container stretch-container mb-40">
                  <VideoPlayer
                    url={getFormattedUrl(storyDetails.video.uri)}
                    thumbnail={getFormattedUrl(storyDetails.thumbnail.uri)}
                    isLiked={storyDetails.isLiked}
                    likeCount={likeCount}
                    isFullScreen={isFullScreen}
                    onFullScreenChange={(isFullScreen: boolean) => {
                      setIsFullScreen(isFullScreen);
                      isFullVideo = isFullScreen;
                    }}
                    onVideoPlay={getWatchedCommunityVideo}
                    disableOrientation={commentVideo ? true : false}
                  />
                </div>
              </div>
            </>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default CommunityStory;

import { IonContent, IonImg, IonPage, useIonRouter } from "@ionic/react";
import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router";
import BackButton from "../../components/common/button-component/back";
import RegisterHeader from "../../components/common/register-header";
import { AppContext } from "../../context/app.context";
import { phoneCheck } from "../../services/auth.service";
import PolygonIcon from "../../assets/images/icons/icon-polygon.svg";
import { formatPhoneNumber } from "../../utils/common.helper";
import LanguageAppText from "../../components/languageapptext";

const PrefferedNumber: React.FC = () => {
  const { toastMessage }: any = useContext(AppContext);
  const history: any = useHistory();
  const { state }: any = useLocation();
  const [phoneNumbers, setPhoneNumbers]: any = useState(
    state && JSON.parse(state.phoneNumbers)
  );

  const handlePrefferedNumberSelection = async (
    phoneNumber: string,
    key: string
  ) => {
    const resp: any = await phoneCheck({
      phoneNumber: phoneNumber,
      sendOtp: true,
    });
    if (!resp.error) {
      history.replace({
        pathname: "/confirmOtp",
        state: {
          from: "not-matched",
          phoneNumber: phoneNumber,
          otp: resp.otp,
          phoneNumbers: JSON.stringify(phoneNumbers),
        },
      });
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  return (
    <IonPage className="page-preffer-number">
      <RegisterHeader
        pageName="Create your account"
        textKey={"create_your_acc"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen className="page-content">
        <LanguageAppText
          tag={"h1"}
          className="page-content__title"
          value={"Which is your preferred phone number?"}
          textKey={"preferred_phone"}
        />
        <div className="numbers-container">
          <div
            className="page-content__whitebox"
            onClick={() => {
              handlePrefferedNumberSelection(
                phoneNumbers.phoneNumber1,
                "phoneNumber1"
              );
            }}
          >
            <p className="whitebox__content p0">
              {"+91 " + formatPhoneNumber(phoneNumbers.phoneNumber1)}
            </p>
            <IonImg src={PolygonIcon} />
          </div>
          {phoneNumbers.phoneNumber2 && (
            <div
              className="page-content__whitebox"
              onClick={() => {
                handlePrefferedNumberSelection(
                  phoneNumbers.phoneNumber2,
                  "phoneNumber2"
                );
              }}
            >
              <p className="whitebox__content p0">
                {"+91 " + formatPhoneNumber(phoneNumbers.phoneNumber2)}
              </p>
              <IonImg src={PolygonIcon} />
            </div>
          )}
          {phoneNumbers.phoneNumber3 && (
            <div
              className="page-content__whitebox"
              onClick={() => {
                handlePrefferedNumberSelection(
                  phoneNumbers.phoneNumber3,
                  "phoneNumber3"
                );
              }}
            >
              <p className="whitebox__content p0">
                {"+91 " + formatPhoneNumber(phoneNumbers.phoneNumber3)}
              </p>
              <IonImg src={PolygonIcon} />
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PrefferedNumber;

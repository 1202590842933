import {
  IonButton,
  IonContent,
  IonImg,
  IonLabel,
  IonList,
  IonPage,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ButtonComponent from "../../components/common/button-component";
import RewardDefault from "../../assets/images/global-default_new.jpeg";
import Coin from "../../assets/images/coin.svg";
import "./style.scss";
import { useHistory, useParams } from "react-router";
import { getRedeemRewardInfo } from "../../services/reward.service";
import { AppContext } from "../../context/app.context";
import Loader from "../../components/common/loader";
import LanguageText from "../../components/languagetext";
import parse from "html-react-parser";
import { getFormattedUrl } from "../../utils/common.helper";
import LanguageImage from "../../components/languageimage";
import VideoPlayer from "../../components/videoplayer";
import Back from "../../assets/images/icons/back.svg";
import Copy from "../../assets/images/icons/copy.svg";
import { Clipboard } from "@ionic-native/clipboard";
import Messages from "../../config/messages";
import LanguageAppText from "../../components/languageapptext";
let isShowVideo = false;
const RewardRedeemInfo: React.FC = () => {
  const { type, id }: any = useParams();
  const history: any = useHistory();
  const { toastMessage, language }: any = useContext(AppContext);
  const [rewardInfo, setRewardInfo]: any = useState(null);
  const [instructions, setInstructions]: any = useState(null);
  const [bannerImage, setBannerImage]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [rewardData, setRewardData]: any = useState(null);
  const [videoUrl, setVideoUrl]: any = useState(null);

  useEffect(() => {
    fetchRedeemedRewardInfo();
    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
      isShowVideo = false;
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(101, () => {
      if (isShowVideo) {
        isShowVideo = false;
        setVideoUrl(null);
      } else {
        history.goBack();
      }
    });
  };

  const fetchRedeemedRewardInfo = async () => {
    const resp: any = await getRedeemRewardInfo(id);
    if (!resp.error) {
      try {
        const parsedData = JSON.parse(resp.rewardData);
        setRewardData(parsedData);
      } catch (error) {}
      setRewardInfo(resp);
      setBannerImage(JSON.parse(resp.bannerImage));
      setInstructions(JSON.parse(resp.instructions));
    } else {
      toastMessage(resp.message, "primary");
    }
    setLoading(false);
  };

  const onVideoClick = (videoUrl: string) => {
    setVideoUrl(videoUrl);
    isShowVideo = true;
  };

  const copyToClipboard = (text: string) => {
    Clipboard.copy(text);
    toastMessage(Messages.COUPON_COPY, "success");
  };
  return (
    <IonPage className="page-reward-congratulation large-header abstract-img-light-blue">
      <IonContent className="page-content pb-80">
        {loading ? (
          <Loader customClass="loader-page-center"></Loader>
        ) : (
          <>
            {videoUrl ? (
              <div className="page-reward-video stretch-container">
                <IonButton
                  className="ion-button__back-btn"
                  onClick={() => {
                    setVideoUrl(null);
                    isShowVideo = false;
                  }}
                >
                  <IonImg src={Back} className="back-icon" />
                </IonButton>
                <VideoPlayer
                  url={getFormattedUrl(videoUrl)}
                  isPlaying
                  isFullScreen
                  hideFullScreen={true}
                />
              </div>
            ) : (
              <>
                <div className="reward-congratulation__container">
                  {/* <div className="reward-congratulation__title text-white font-medium mt-20">
                    {type != "view" ? "Congratulations!" : "Reward details"}
                  </div> */}
                  <LanguageAppText
                    tag={"div"}
                    className="reward-congratulation__title text-white font-medium mt-20"
                    value={
                      type != "view" ? "Congratulations!" : "Reward details"
                    }
                    textKey={
                      type != "view" ? "congratulations" : "reward_details"
                    }
                  />
                  {type != "view" && (
                    <LanguageAppText
                      tag={"div"}
                      className="reward-congratulation__redeemed text-white mt-10"
                      value={"You have redeemed a reward!"}
                      textKey={"redeemed_reward_success"}
                    />
                  )}
                  <div className="mt-25">
                    {rewardInfo.type == "tool" ? (
                      <LanguageImage
                        value={rewardInfo.iconImage}
                        className="reward-congratulation__img"
                      />
                    ) : (
                      <LanguageImage
                        value={bannerImage}
                        className="reward-congratulation__img"
                      />
                    )}
                    {/* <IonImg
                  src={
                    getFormattedUrl(bannerImage[language]["uri"]) ||
                    RewardDefault
                  }
                  className="reward-congratulation__img"
                /> */}
                  </div>
                  <LanguageText
                    tag="div"
                    className="reward-congratulation__decription text-white mt-10 font-medium"
                    value={rewardInfo.name}
                  ></LanguageText>

                  <div className="reward-congratulation__coins mt-15">
                    <IonImg src={Coin} className="coin-img" />
                    <LanguageAppText
                      tag={"div"}
                      className="congratulation-coins__text text-white"
                      value={`Purchased for ${rewardInfo.coinsRequired} Coins`}
                      textKey={"purchased_reward"}
                      metaData={{ rewardCoin: rewardInfo.coinsRequired }}
                    />
                  </div>
                  {rewardData && (
                    <div className="reward-discount__code text-green bg-white font-medium mt-15">
                      {
                        rewardInfo.type == "discount" ? (
                          <>
                            <span>
                              Discount Code:{" "}
                              {rewardData.link && rewardData.link != "" ? (
                                <a
                                  href={rewardData.link}
                                  target="_blank"
                                  className="text-green"
                                >
                                  {rewardData.discountCode}
                                </a>
                              ) : (
                                rewardData.discountCode
                              )}
                            </span>
                            <IonImg
                              onClick={() => {
                                copyToClipboard(rewardData.discountCode);
                              }}
                              src={Copy}
                              style={{ marginLeft: 10, width: 20, height: 18 }}
                            />
                          </>
                        ) : rewardInfo.type == "tool" ? (
                          <a
                            className="text-green"
                            href={
                              rewardData.link[language] || rewardData.link["en"]
                            }
                          >
                            {rewardData.title[language] ||
                              rewardData.title["en"]}
                          </a>
                        ) : rewardInfo.type == "video" ? (
                          <span
                            onClick={() => {
                              onVideoClick(
                                rewardData.video[language].uri ||
                                  rewardData.video["en"].uri
                              );
                            }}
                          >
                            {rewardData.title[language] ||
                              rewardData.title["en"]}
                          </span>
                        ) : (
                          <div></div>
                        )
                        // <IonImg
                        //   src={rewardInfo.couponImage}
                        //   className="reward-discount__img"
                        // />
                      }
                    </div>
                  )}
                  <div className="reward-congratulation__instructions text-white mt-25 pb-60">
                    <p className="mb-10 mt-0">Instructions</p>
                    {parse(instructions[language] || instructions["en"])}
                  </div>
                </div>
                <div className="page-reward__btn-container stretch-container bg-light-orange mt-0">
                  <ButtonComponent
                    name="Done"
                    textKey={"done"}
                    customClass="page-reward__btn"
                    onClick={() => {
                      type == "view"
                        ? history.goBack()
                        : history.replace("/redeemedreward");
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default RewardRedeemInfo;

import "./style.scss";

import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";

import Loader from "../../../components/common/loader";
import RegisterHeader from "../../../components/common/register-header";
import ToolCard from "../../../components/toolcard";
import ButtonComponent from "../../../components/common/button-component";
import Popup from "../../../components/common/popup";
import {
  getLockTools,
  getToolsUsedOn,
  getUnlockTools,
  toggleToolFavourite,
  toolUsedOn,
} from "../../../services/tools.service";
import LanguageText from "../../../components/languagetext";
import {
  formatDateToSingleChar,
  getFormattedUrl,
} from "../../../utils/common.helper";
import moment from "moment";
import { AppContext } from "../../../context/app.context";
import LanguageAppText from "../../../components/languageapptext";
import { useHistory } from "react-router";
import { Plugins } from "@capacitor/core";
import TodoListIcon from "../../../assets/images/todo-list.png";

const { App } = Plugins;

let isShowDetail = false;
const Tools: React.FC = () => {
  const history = useHistory();
  const { language }: any = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [unlockedTools, setUnlockdeTools]: any = useState([]);
  const [lockedTools, setLockdeTools]: any = useState([]);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modalContent, setModalContent]: any = useState(null);
  useEffect(() => {
    Promise.all([fetchUnlockTools(), fetchLockTools()]).then(() => {
      setLoading(false);
    });
    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
      isShowDetail = false;
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      if (isShowDetail) {
        hidePopup();
      } else {
        App.exitApp();
      }
    });
  };

  const fetchUnlockTools = async () => {
    const resp: any = await getUnlockTools();
    if (!resp.error) {
      const ids = resp.map((item: any) => item.id);
      console.log("unlock", JSON.stringify(resp));
      setUnlockdeTools(resp);
      setTimeout(() => {
        fetchUnlockToolsUsedOn(ids, resp);
      }, 500);
    }
  };

  const fetchUnlockToolsUsedOn = async (ids: Array<any>, data: any) => {
    const resp: any = await getToolsUsedOn(ids);
    if (!resp.error) {
      resp.map((item: any) => {
        const index = data.findIndex((tool: any) => tool.id == item.toolsId);
        data[index]["usedOn"] = item.updatedAt;
      });
      setUnlockdeTools([...data]);
    }
  };

  const fetchLockTools = async () => {
    const resp: any = await getLockTools();
    if (!resp.error) {
      setLockdeTools(resp);
    }
  };

  const handleShowDetails = (data: any) => {
    setModalContent(data);
    hidePopup();
  };

  const handleToggleFav = async (id: number) => {
    const resp: any = await toggleToolFavourite(id);
  };

  const handleViewCard = async (item: any) => {
    const configData = JSON.parse(item.config);
    const url = configData[language]
      ? configData[language].url
      : configData["en"].url;
    // const toolName = JSON.parse(item.name);
    if (url.startsWith("internal")) {
      history.push({
        pathname: `/activity/${url.split("internal://")[1]}?toolName=${
          item.machineName
        }`,
      });
    } else {
      window.open(url);
    }

    const resp: any = await toolUsedOn(item.id);
  };

  const hidePopup = () => {
    isShowDetail = !isShowDetail;
    setShowDetailModal(isShowDetail);
  };

  const renderUnlockedTools = () => {
    const unlockedItems = unlockedTools.map((item: any, index: any) => {
      return (
        <ToolCard
          key={"unlocked-" + item.id}
          imgPath={item.iconImage}
          cardName={item.name}
          // otherText={`+${item.usedByCount} others using now`}

          otherText={
            item.usedByCount > 0 ? (
              <LanguageAppText
                tag={"div"}
                className={`other-user__text text-orange font-medium pl-0`}
                value={"{userToolUnlockedCount} others using now"}
                textKey={"tool_using_count"}
                metaData={{ userToolUnlockedCount: item.usedByCount }}
              />
            ) : (
              ""
            )
          }
          toolLikeText={
            item.createdAt
              ? formatDateToSingleChar(
                  moment(item.createdAt).fromNow(),
                  item.createdAt
                )?.replace("ago", "")
              : ""
          }
          isLikeCard={true}
          isFavourite={item.isFavourite}
          onFavClick={() => {
            handleToggleFav(item.id);
          }}
          onClick={() => {
            handleViewCard(item);
          }}
        />
      );
    });
    // const todoListItem = (
    //   <ToolCard
    //     key={"unlocked-todo-item"}
    //     imgPath={TodoListIcon}
    //     cardName={"To-Do List"}
    //     onClick={() => {
    //       history.push(`/todo`);
    //     }}
    //   />
    // );

    return [unlockedItems];
  };

  const renderLockedTools = () => {
    return lockedTools.map((item: any, index: any) => {
      // const otherText = item.unlockedSourceName
      //   ? `Complete "${
      //       JSON.parse(item.unlockedSourceName)[language] ||
      //       JSON.parse(item.unlockedSourceName)["en"]
      //     }" to unlock`
      //   : "";
      return (
        <IonCol key={"locked-" + item.id} size="6" className="lock-tools-card">
          <ToolCard
            imgPath={item.iconImage}
            cardName={item.name}
            isCountVisible={false}
            otherText={
              item.unlockedSourceName ? (
                <LanguageAppText
                  tag={"div"}
                  className={`other-user__text text-orange font-medium pl-0`}
                  value={"Complete {toolUnlockBy} to unlock"}
                  textKey={"tool_unlock_by"}
                  metaData={{ toolUnlockBy: item.unlockedSourceName }}
                />
              ) : (
                ""
              )
            }
            onClick={() => {
              handleShowDetails(item);
            }}
          />
        </IonCol>
      );
    });
  };

  return (
    <IonPage className="page-tools">
      <RegisterHeader pageName={"Tools"} textKey={"tools"}></RegisterHeader>
      <IonContent className="page-content pb-85 pt-0">
        {loading ? (
          <Loader customClass="loader-page-center"></Loader>
        ) : (
          <>
            {unlockedTools.length > 0 && (
              <div className="page-content_unlock-tools stretch-container">
                <LanguageAppText
                  tag={"div"}
                  className="unlock-tools__title font-medium text-blue mb-5"
                  value={"Unlocked tools"}
                  textKey={"unlocked_tools"}
                />
                <LanguageAppText
                  tag={"div"}
                  className="unlock-tools__desc text-gray pb-10"
                  value={"Tools you’ve unlocked through programs so far!"}
                  textKey={"tools_unlocked_programs"}
                />
                <div className="unlock-tools">{renderUnlockedTools()}</div>
              </div>
            )}
            <div className="page-content_lock-tools bg-white">
              <LanguageAppText
                tag={"div"}
                className="lock-tools__title font-medium text-green mb-10"
                value={"Locked tools"}
                textKey={"locked_tools"}
              />
              <LanguageAppText
                tag={"div"}
                className="lock-tools__desc text-gray pb-5"
                value={"Complete more programs to unlock these tools"}
                textKey={"complete_more_program"}
              />
              <div className="lock-tools">
                <IonGrid className="ion-no-margin ion-no-padding">
                  <IonRow>{renderLockedTools()}</IonRow>
                </IonGrid>
              </div>
            </div>
          </>
        )}
        {showDetailModal && (
          <Popup customClass="tools-popup">
            <IonImg
              src={getFormattedUrl(
                JSON.parse(modalContent.descriptionImage)["uri"]
              )}
              className="ion-popup__success-icon mb-20"
            />
            <LanguageText
              tag="div"
              value={modalContent.name}
              className="tools-popup__title text-deep-black font-bold mb-5"
            />
            <LanguageText
              tag="div"
              value={modalContent.description}
              className="tools-popup__detail-text text-light-black font-medium mb-20"
            />
            {modalContent.unlockedSourceName && (
              <LanguageAppText
                tag={"div"}
                className="tools-popup__get-online text-orange font-medium mb-20"
                value={`Complete {toolUnlockBy} to unlock`}
                textKey={"tool_unlock_by"}
                metaData={{ toolUnlockBy: modalContent.unlockedSourceName }}
              />
              // <div className="tools-popup__get-online text-orange font-medium mb-20">
              //   Complete "
              //   {JSON.parse(modalContent.unlockedSourceName)[language] ||
              //     JSON.parse(modalContent.unlockedSourceName)["en"]}
              //   " to unlock
              // </div>
            )}

            <ButtonComponent
              name="Okay"
              textKey={"okay"}
              onClick={hidePopup}
              customClass="tool-popup-btn"
            ></ButtonComponent>
          </Popup>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Tools;

import "./style.scss";

import {
  IonButton,
  IonContent,
  IonImg,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import React, { Fragment, useContext, useEffect, useState } from "react";
import RegisterHeader from "../../components/common/register-header";
import { getHelpCenter, getHelpCenterImage } from "../../services/user.service";
import ButtonComponent from "../../components/common/button-component";
import AcoordionDummyImg from "../../assets/images/global-default_new.jpeg";
import { getInfo } from "../../services/app.service";
import { Capacitor } from "@capacitor/core";
import Loader from "../../components/common/loader";
import LanguageAppText from "../../components/languageapptext";
import LanguageText from "../../components/languagetext";
import { AppContext } from "../../context/app.context";
import { getFormattedUrl } from "../../utils/common.helper";

const HelpCenter: React.FC = () => {
  const { language }: any = useContext(AppContext);
  const [helpData, setHelpData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDesc, setShowDesc] = useState(true);
  const [contactUs, setContactUs]: any = useState(null);
  const [hideExpert, setHideExpert] = useState(false);
  const [image, setImage] = useState("");

  useEffect(() => {
    Promise.all([getHelpCenterData(), getAppInfo()]);
  }, []);
  const getHelpCenterData = async () => {
    const resp: any = await getHelpCenter();
    if (!resp.error) {
      resp.map((item: any) => {
        let description = item.description;
        try {
          let parsed = JSON.parse(description);
          item.description = parsed[language] || parsed["en"];
        } catch (error) {}
      });
      setHelpData(resp);
      await getHelpCenterImageData();
    }
    setLoading(false);
  };

  const getHelpCenterImageData = async () => {
    const resp: any = await getHelpCenterImage();
    if (!resp.error && resp.value) {
      try {
        let parseData = JSON.parse(resp.value);
        setImage(
          parseData.image[language]
            ? parseData.image[language].uri
            : parseData.image["en"].uri
        );
      } catch (error) {}
    }
  };

  const getAppInfo = async () => {
    const resp: any = await getInfo();
    if (!resp.error) {
      const number = resp.helpCenter.contactUs;
      setContactUs(
        Capacitor.getPlatform() == "web"
          ? `https://web.whatsapp.com/send?phone=${number}`
          : `whatsapp://send?phone=${number}`
      );
    }
  };

  const onItemClick = (index: number) => {
    if (currentIndex !== index) {
      setShowDesc(true);
    }
    setCurrentIndex(index);
  };

  const handleOnScroll = async (event: any) => {
    const scrollElement = await event.target.getScrollElement();
    console.log(scrollElement.scrollHeight, scrollElement.clientHeight);
    const offsetY = event.detail.currentY;
    if (scrollElement.scrollHeight > 400 && offsetY < 20) {
      setHideExpert(false);
    } else if (offsetY > 20) {
      setHideExpert(true);
    }
  };

  const handleContactUs = () => {};

  return (
    <IonPage className="page-help-center">
      <div className={`page-help__header ${hideExpert ? "hide-expert" : ""}`}>
        <RegisterHeader
          pageName="Help centre"
          textKey={"help_centre"}
          backBtn={true}
          customStyle={
            !hideExpert
              ? {
                  backgroundImage: `url(${
                    image ? getFormattedUrl(image) : ""
                  })`,
                  backgroundColor: "#73CBE5",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "bottom",
                  bottom: "0%",
                  display: "block",
                  marginTop: 0,
                  marginBottom: 0,
                  marginRight: "auto",
                  marginLeft: "auto",
                }
              : {}
          }
        ></RegisterHeader>
        {/* <IonImg
          src={image ? getFormattedUrl(image) : ""}
          className="help-center-image"
        /> */}
      </div>
      <IonContent
        className={`page-content ${hideExpert ? "pt-50" : ""}`}
        scrollEvents={true}
        onIonScroll={handleOnScroll}
      >
        <LanguageAppText
          tag={"div"}
          className="page-content__intro text-green font-medium mb-10"
          value={"Welcome to the help centre"}
          textKey={"help_centre_welcome"}
        />
        <LanguageAppText
          tag={"div"}
          className="page-content__instruction text-gray ion-margin-bottom"
          value={
            "Read our Frequently Asked Questions, or contact us through WhatsApp if you need more help."
          }
          textKey={"frequently_asked_questions"}
        />
        {loading ? (
          <Loader></Loader>
        ) : (
          <div>
            {helpData.length > 0 &&
              helpData.map((item: any, index: number) => {
                return (
                  <div
                    className={`page-content__accordion-btn bg-white mb-20 ${
                      showDesc && currentIndex === index
                        ? "accordion-btn__active"
                        : ""
                    }`}
                    key={index}
                  >
                    <div
                      className="page-content__title mb-0"
                      onClick={() => {
                        onItemClick(index);
                      }}
                    >
                      <LanguageText tag={"span"} value={item.title} />
                    </div>
                    {showDesc && currentIndex === index && (
                      <>
                        {item.image && (
                          <div className="page-content__img mt-10">
                            <IonImg src={getFormattedUrl(item.image.uri)} />
                          </div>
                        )}
                        <div
                          className="page-content__description mt-10 text-gray"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        )}

        {contactUs && (
          <div className="page-content__contact-btn">
            <a target="_blank" href={contactUs}>
              <ButtonComponent
                outline={true}
                name="Contact Us"
                customClass="btn-contact-us"
                textKey={"contact_us"}
              />
            </a>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default HelpCenter;

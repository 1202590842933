import {
  IonList,
  IonThumbnail,
  IonImg,
  IonCard,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import RegisterHeader from "../../components/common/register-header";

import "./style.scss";
import StepBar from "../../components/common/stepbar";
import { getProgramById } from "../../services/program.service";
import Loader from "../../components/common/loader";
import { AppContext } from "../../context/app.context";
import { getFormattedUrl } from "../../utils/common.helper";
import LanguageText from "../../components/languagetext";
import LanguageImage from "../../components/languageimage";
import LanguageAppText from "../../components/languageapptext";

const Learnings: React.FC = () => {
  const { language }: any = useContext(AppContext);
  const history = useHistory();
  let { id }: any = useParams();
  const [loading, setLoading] = useState(true);
  const [program, setProgram]: any = useState(null);
  const [otherTopics, setOtherTopics]: any = useState([]);
  const [topicsCompleted, setTopicsCompleted]: any = useState([]);
  const [stepBarData, setStepBarData]: any = useState([]);
  const [headerTitle, setHeaderTitle]: any = useState("");
  const { state }: any = useLocation();
  const fromPage = state?.fromPage || "";
  useEffect(() => {
    getProgramDetails();
    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(101, () => {
      if (fromPage && fromPage === "activityCompleted") {
        history.replace({
          pathname: "/tabs/experts",
        });
      } else {
        history.goBack();
      }
    });
  };

  const getProgramDetails = async () => {
    const resp: any = await getProgramById(id);
    if (!resp.error) {
      const topicsCompleted: any = resp.program.topicsCompleted;
      let topicsCompletedLength = 0;
      let topicsCompletedArray: any[] = [];
      if (topicsCompleted && topicsCompleted != "") {
        topicsCompletedArray = topicsCompleted.split(",");
        topicsCompletedLength = topicsCompletedArray.length;
      }

      //step bar data and completed topic
      let stepBar = [],
        completedTopics = [],
        otherTopics = [];
      let activities = resp.activities;
      let activitiesCompleted: any = resp.program.activitiesCompleted;
      let activitiesCompletedArray: any[] = [];
      if (activitiesCompleted && activitiesCompleted != "") {
        activitiesCompletedArray = activitiesCompleted.split(",");
      }
      for (let i = 1; i <= resp.topics.length; i++) {
        let topicObj = resp.topics[i - 1];
        let stepObj = {
          completed: i <= topicsCompletedLength,
          active: i == topicsCompletedLength + 1,
        };
        stepBar.push(stepObj);
        if (topicsCompletedArray.includes(topicObj.id.toString())) {
          let filterActivity = [];
          if (activities && activities.length > 0) {
            filterActivity = activities.filter((activity: any) => {
              return activity.parent === topicObj.id;
            });
          }
          let completedActivity = [];
          if (filterActivity && filterActivity.length > 0) {
            completedActivity = filterActivity.filter((activity: any) => {
              return activitiesCompletedArray.includes(activity.id.toString());
            });
          }
          completedTopics.push({
            topic: topicObj,
            activitiesLength: filterActivity.length,
            activitiesCompletedLength: completedActivity.length,
          });
        } else {
          otherTopics.push(topicObj);
        }
      }

      let nodeTitle = "";
      try {
        let parsedData = JSON.parse(resp.program.nodeTitle);
        if (parsedData) {
          nodeTitle = parsedData[language] || parsedData["en"];
        }
      } catch (error) {}
      setHeaderTitle(nodeTitle);
      setProgram(resp.program);
      setOtherTopics(otherTopics);
      setTopicsCompleted(completedTopics);
      setStepBarData(stepBar);
    }
    setLoading(false);
  };

  const onTopicClick = (topicId: any) => {
    history.push({
      pathname: `/topic/${topicId}`,
    });
  };

  const onCompleteTopicClick = (topic: any) => {
    history.push({
      pathname: `/review/topic/${topic.id}`,
    });
  };

  return (
    // <div className="page-learning">
    // <RegisterHeader pageName="Make more money" backBtn={true}></RegisterHeader>

    // <div className="page-content">
    // <div className="page-content__title">Recommended topic</div>
    // <p>Based on your business profile, we think you’ll like this activity best!</p>
    // <IonCard className="ion-no-margin">
    // <IonCardHeader>
    // <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
    // <IonCardTitle>Card Title</IonCardTitle>
    // </IonCardHeader>

    // <IonCardContent>
    // Keep close to Nature's heart... and break clear away, once in awhile,
    // and climb a mountain or spend a week in the woods. Wash your spirit clean.
    // </IonCardContent>
    // </IonCard>
    // </div>

    // </div>
    <IonPage className="page-learning">
      <RegisterHeader
        pageName={headerTitle}
        backBtn={true}
        onBack={() => {
          if (fromPage && fromPage === "activityCompleted") {
            history.replace({
              pathname: "/tabs/experts",
            });
          } else {
            history.goBack();
          }
        }}
      ></RegisterHeader>
      <StepBar data={stepBarData}></StepBar>

      <IonContent className="page-content pb-0 pt-0">
        {loading ? (
          <Loader></Loader>
        ) : (
          <div>
            {otherTopics.length > 0 && (
              <div className="page-learning-recommended-container bg-light-orange stretch-container">
                <LanguageAppText
                  tag={"div"}
                  className="page-content__title"
                  value={"Recommended topic"}
                  textKey={"recommended_topic"}
                />
                <LanguageAppText
                  tag={"p"}
                  className="text-gray"
                  value={
                    "Based on your business profile, we think you’ll like this topic best!"
                  }
                  textKey={"recommended_activity"}
                />
                <div className="activity-card__wrapper button-position">
                  <IonCard className="ion-no-margin capsul-card capsul-card__large ion-flex ion-align-items-center">
                    <IonList className="ion-no-padding">
                      <IonItem className="">
                        <div className="capsul-card__thum-group">
                          <IonThumbnail
                            slot="start"
                            className="ion-no-padding ion-no-margin ion-margin-end"
                          >
                            <LanguageImage
                              value={otherTopics[0].nodeFieldData.teaserImage}
                            />
                          </IonThumbnail>
                          <div className="capsul-card__time">
                            {otherTopics[0].seatTime}
                          </div>
                        </div>
                        <div className="">
                          <LanguageText
                            tag={"label"}
                            className="capsul-card__label"
                            value={otherTopics[0].nodeFieldData.title}
                          ></LanguageText>

                          {/* <div className="capsul-card__other">
                            <div className="user_group">
                              <div className="user_group_img">
                                <img
                                  src="../../../assets/images/users/user1.png"
                                  className=""
                                />
                                <img
                                  src="../../../assets/images/users/user1.png"
                                  className="user_group_overlap_1"
                                />
                                <img
                                  src="../../../assets/images/users/user1.png"
                                  className="user_group_overlap_2"
                                />
                              </div>
                            </div>
                            <p>+423 others currently attending</p>
                          </div> */}
                        </div>
                      </IonItem>
                    </IonList>
                  </IonCard>
                  <button
                    onClick={() => onTopicClick(otherTopics[0].id)}
                    className="custom-button custom-button__arrow button-position__bottom ion-text-left"
                  >
                    <LanguageAppText
                      tag={"span"}
                      value={"Start topic"}
                      textKey={"start_topic"}
                    />
                  </button>
                </div>
              </div>
            )}

            <div className="bg-white stretch-container">
              {otherTopics.length > 1 && (
                <div>
                  <LanguageAppText
                    tag={"div"}
                    className="mb-20 page__section-title"
                    value={"Other available topics"}
                    textKey={"available_topic"}
                  />
                  {otherTopics.map((topic: any, i: number) => {
                    if (i == 0) return; // to remove recomended topic
                    return (
                      <IonCard
                        key={i}
                        onClick={() => {
                          onTopicClick(topic.id);
                        }}
                        className="ion-no-margin capsul-card"
                      >
                        <IonList className="ion-no-padding">
                          <IonItem className="">
                            <div className="capsul-card__thum-group">
                              <IonThumbnail
                                slot="start"
                                className="ion-no-padding ion-no-margin ion-margin-end"
                              >
                                <LanguageImage
                                  value={topic.nodeFieldData.teaserImage}
                                />
                              </IonThumbnail>
                              <div className="capsul-card__time">
                                {" "}
                                {topic.seatTime}
                              </div>
                            </div>

                            <LanguageText
                              tag={"label"}
                              className="capsul-card__label"
                              value={topic.nodeFieldData.title}
                            ></LanguageText>

                            {/* <div className="user_group">
                              <div className="user_group_img">
                                <img
                                  src="../../../assets/images/users/user1.png"
                                  className="back-icon "
                                />
                                <img
                                  src="../../../assets/images/users/user1.png"
                                  className="user_group_overlap_1"
                                />
                                <img
                                  src="../../../assets/images/users/user1.png"
                                  className="user_group_overlap_2"
                                />
                              </div>
                            </div> */}
                          </IonItem>
                        </IonList>
                      </IonCard>
                    );
                  })}
                </div>
              )}
              {topicsCompleted && topicsCompleted.length > 0 && (
                <div className="completed-card">
                  <LanguageAppText
                    tag={"div"}
                    className="page__section-title"
                    value={"Completed Topics"}
                    textKey={"completed_topics"}
                  />

                  {topicsCompleted.map((item: any, index: number) => {
                    const topic = item.topic;
                    return (
                      <div
                        onClick={() => {
                          onCompleteTopicClick(topic);
                        }}
                        key={index}
                      >
                        <div className="custom-button">
                          {item.activitiesCompletedLength}/
                          {item.activitiesLength} ACTIVITIES COMPLETED
                        </div>
                        <IonCard className="ion-no-margin capsul-card ">
                          <IonList className="ion-no-padding">
                            <IonItem className="">
                              <div className="capsul-card__thum-group ">
                                <IonThumbnail
                                  slot="start"
                                  className="ion-no-padding ion-no-margin ion-margin-end capsul-card__thum-group_overlay relative"
                                >
                                  <LanguageImage
                                    value={topic.nodeFieldData.teaserImage}
                                  />
                                </IonThumbnail>
                                <div className="icon__completed"></div>
                              </div>
                              <LanguageText
                                tag={"label"}
                                className="capsul-card__label"
                                value={topic.nodeFieldData.title}
                              ></LanguageText>
                            </IonItem>
                          </IonList>
                        </IonCard>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        {/* <IonCard className="ion-no-margin capsul-card">
                <IonList className="ion-no-padding">
                    {items.map((image, i) => (
                    <IonItem key={i}>
                        <IonThumbnail slot="start" className="ion-no-padding ion-no-margin ion-margin-end">
                            <IonImg src={image.src} />
                        </IonThumbnail>
                        <IonLabel>{image.text}</IonLabel>
                    </IonItem>
                    ))}
                </IonList>
            </IonCard> */}
      </IonContent>
    </IonPage>
  );
};

export default Learnings;

import { Plugins } from "@capacitor/core";
import {
  createAnimation,
  IonContent,
  IonPage,
  useIonRouter,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import BottomDrawer from "../../../components/bottomdrawer";
import ButtonComponent from "../../../components/common/button-component";
import Loader from "../../../components/common/loader";
import RegisterHeader from "../../../components/common/register-header";
import LanguageAppText from "../../../components/languageapptext";
import StoryCard from "../../../components/storycard";
import {
  getCommunity,
  getStoryUserCount,
} from "../../../services/community.service";
import observerService from "../../../services/observer.service";
import { getFormattedUrl } from "../../../utils/common.helper";
import "./style.scss";
const { App } = Plugins;

const Stories: React.FC = () => {
  const SUBMIT_STORY_TYPE = "SUBMIT_STORY";
  let bottomViewState = "open";
  const [refreshing, setRefreshing] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [addHeight, setaddHeight] = useState(true);
  const [community, setCommunity]: any = useState([]);
  const [userCount, setUserCount] = useState("");
  const history: any = useHistory();
  const ionRouter = useIonRouter();

  useEffect(() => {
    Promise.all([getCommunityData(), fetchStories()]).finally(() => {
      setRefreshing(false);
    });

    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      App.exitApp();
    });
  };

  const getCommunityData = async () => {
    const userCountRes: any = await getStoryUserCount();
    setUserCount(userCountRes);
    setRefreshing(false);
  };

  const fetchStories = async (offset?: number) => {
    offset && setFetching(true);
    const storyRes: any = await getCommunity(offset);
    if (storyRes.length != 10) {
      setHasMoreData(false);
    }
    setFetching(false);
    setCommunity((stories: any) => [...stories, ...storyRes]);
  };

  const handlePageRedirect = (route: any) => {
    history.push({
      pathname: route,
    });
  };

  const handleOnScroll = async (event: any) => {
    const scrollElement = await event.target.getScrollElement();
    console.log(scrollElement.scrollHeight, scrollElement.clientHeight);
    const offsetY = event.detail.currentY;
    if (offsetY < 50 && bottomViewState == "close") {
      bottomViewState = "open";
      observerService.handleEvents(SUBMIT_STORY_TYPE, { state: "open" });
      setaddHeight(true);
    } else if (offsetY > 50 && bottomViewState == "open") {
      bottomViewState = "close";
      observerService.handleEvents(SUBMIT_STORY_TYPE, { state: "close" });
      setaddHeight(false);
    }

    // check if scroll reach to the end of scroll
    const scrollHeight =
      scrollElement.scrollHeight - scrollElement.clientHeight;
    const currentScrollDepth = event.detail.scrollTop;
    const targetPercent = 80;
    let triggerDepth = (scrollHeight / 100) * targetPercent;

    if (
      currentScrollDepth > triggerDepth &&
      !fetching &&
      hasMoreData &&
      community.length
    ) {
      fetchStories(community.length);
      // console.log(`Scrolled to ${targetPercent}%`);
    }
  };

  const renderStoryCard = () => {
    return community.map((item: any, index: number) => (
      <StoryCard
        key={item.id}
        imgPath={item.thumbnail && getFormattedUrl(item.thumbnail.uri)}
        isNew={!item.isViewed}
        cardName={item.category.name}
        title={item.title}
        onClick={() => {
          handlePageRedirect(`/communityStory/${item.id}`);
        }}
      ></StoryCard>
    ));
  };

  return (
    <>
      <IonPage className="page-stories">
        <RegisterHeader pageName="Stories" textKey={"stories"}></RegisterHeader>
        {refreshing ? (
          <Loader customClass="loader-page-center" />
        ) : (
          <>
            <IonContent
              fullscreen={true}
              className="page-content pb-85"
              scrollEvents={true}
              onIonScroll={handleOnScroll}
            >
              {/* <IonContent fullscreen={true} className="page-content">
                    <IonContent scrollEvents={true} onIonScroll={handleOnScroll}> */}
              <LanguageAppText
                tag={"div"}
                className="page-content__title mb-5"
                value={"Latest stories"}
                textKey={"latest_stories"}
              />
              <LanguageAppText
                tag={"div"}
                className="page-stories__description mb-20 text-gray"
                value={`Take a look at the latest stories from the community of over ${userCount} people!`}
                textKey={"look_at_latest_stories"}
                metaData={{ userCount: userCount }}
              />
              {/* <div className="page-stories__description mb-20 text-gray">
                {userCount}
              </div> */}
              <div className="page-stories__card-container">
                {renderStoryCard()}
              </div>
              {/* </IonContent>
                  </IonContent> */}
              {/* <div
                    style={{
                      position: "absolute",
                      bottom: 15,
                      zIndex: 101,
                      width: "100%",
                    }}
                  >
                    <BottomDrawer type={SUBMIT_STORY_TYPE}>
                      <div className="page-stories__bottom-container bg-green stretch-container">
                        <div className="bottom-container_details text-white">
                          <div className="page-stories__badge font-medium mb-3">
                            EARN A BADGE!
                          </div>
                          <div className="page-stories__badge-desc font-medium">
                            What is one thing you do that helps your business
                            succeed?
                          </div>
                        </div>
                        <ButtonComponent
                          name="Submit Story"
                          customClass="page-stories__submit-btn"
                          onClick={() => {
                            handlePageRedirect("/submitstory");
                          }}
                        ></ButtonComponent>
                      </div>
                    </BottomDrawer>
                  </div> */}
            </IonContent>
          </>
        )}
        {/* <div
          className="page-stories__bottom-drawer"
          style={{
            position: "absolute",
            bottom: 15,
            zIndex: 999,
            width: "100%",
          }}
        >
          <BottomDrawer type={SUBMIT_STORY_TYPE}>
            <div
              className={`page-stories__bottom-container bg-green stretch-container ${
                addHeight ? "mb-40" : ""
              }`}
            >
              <div className="bottom-container_details text-white">
                <LanguageAppText
                  tag={"div"}
                  className="page-stories__badge font-medium mb-3"
                  value={"EARN A BADGE!"}
                  textKey={"earn_a_badge"}
                />
                <LanguageAppText
                  tag={"div"}
                  className="page-stories__badge-desc font-medium"
                  value={
                    "What is one thing you do that helps your business succeed?"
                  }
                  textKey={"helps_business_succeed"}
                />
              </div>
              <ButtonComponent
                name="Submit Story"
                textKey={"submit_story"}
                customClass="page-stories__submit-btn"
                onClick={() => {
                  handlePageRedirect("/submitstory");
                }}
              ></ButtonComponent>
            </div>
          </BottomDrawer>
        </div> */}
      </IonPage>
    </>
  );
};

export default Stories;

import { socialSharing } from "./common.helper";

export const receiveMessage = async (data: any) => {
  switch (data.type) {
    case "share":
      socialSharing(data.value.message, undefined, data.value.file, undefined);
      break;

    default:
      return data;
  }
};

import "./style.scss";

import React, { useContext, useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonThumbnail,
} from "@ionic/react";
import { useParams } from "react-router-dom";
import RegisterHeader from "../../components/common/register-header";
import { getProgramById, getTopicById } from "../../services/program.service";
import ExtraActivityCard from "../../components/extraactivitycard";
import CirclePlay from "../../assets/images/icons/circle-play.svg";
import Coins from "../../assets/images/icons/coins.svg";
import PlayOrange from "../../assets/images/icons/play-orange.svg";
import Refresh from "../../assets/images/icons/refresh.svg";
import { useHistory } from "react-router";
import Loader from "../../components/common/loader";
import { AppContext } from "../../context/app.context";
import LanguageImage from "../../components/languageimage";
import LanguageText from "../../components/languagetext";
import { File } from "@ionic-native/file";
import {
  getFileDirectory,
  getFormattedUrl,
  unZipDownloadedFile,
} from "../../utils/common.helper";
import { Capacitor } from "@capacitor/core";
import { downloadFile } from "../../services/filetransfer.service";
import VideoPlayer from "../../components/videoplayer";
import Back from "../../assets/images/icons/back.svg";
import LanguageAppText from "../../components/languageapptext";
import { isConnectedToInternet } from "../../services/network.service";
import Popup from "../../components/common/popup";
import Certificate from "../../components/certificate";
import { getUserCertificateById } from "../../services/user.service";
let isFullVideo = false;

const ReviewProgram: React.FC = () => {
  const { language }: any = useContext(AppContext);
  let { id, type }: any = useParams();
  const [loading, setLoading] = useState(true);
  const history: any = useHistory();
  const [otherActivities, setOtherActivities]: any = useState([]);
  const [activitiesCompleted, setActivitiesCompleted]: any = useState([]);
  const [topicsCompleted, setTopicsCompleted]: any = useState([]);
  const [title, setTitle]: any = useState("");
  const [introVideo, setIntroVideo]: any = useState("");
  const [showIntro, setShowIntro] = useState(false);
  const [certificateImage, setCertificateImage]: any = useState("");
  const [certificateImageLoading, setCertificateImageLoading]: any =
    useState(false);
  useEffect(() => {
    getDetails();

    document.addEventListener("ionBackButton", handleBack);
    return () => {
      document.removeEventListener("ionBackButton", handleBack);
      isFullVideo = false;
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      if (isFullVideo) {
        isFullVideo = false;
        setShowIntro(false);
      } else {
        history.goBack();
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    setIntroVideo("");
    getDetails();
  }, [type]);

  const getDetails = async () => {
    let resp: any;
    if (type === "topic") {
      resp = await getTopicById(id);
      if (resp && !resp.error) {
        const activities = resp.activities;
        const activitiesCompleted: any = resp.progress.activitiesCompleted;
        let activitiesCompletedArray: any[] = [];
        if (activitiesCompleted && activitiesCompleted != "") {
          activitiesCompletedArray = activitiesCompleted.split(",");
        }
        let otherActivities: any = [],
          completedActivities: any = [];
        if (activities) {
          activities.map((activity: any) => {
            if (activitiesCompletedArray.includes(activity.id.toString())) {
              completedActivities.push(activity);
            } else {
              otherActivities.push(activity);
            }
          });
        }

        let title = "",
          introVideo = "";
        if (resp.topic) {
          try {
            let parsedData = JSON.parse(resp.topic.nodeFieldData.title);
            title = parsedData[language] || parsedData["en"];
            let parsedIntroVideo = JSON.parse(resp.topic.nodeFieldData.video);
            introVideo = parsedIntroVideo[language] || parsedIntroVideo["en"];
          } catch (error) {}
        }
        setTitle(title);
        setOtherActivities(otherActivities);
        setActivitiesCompleted(completedActivities);
        setIntroVideo(introVideo);
      }
    } else {
      resp = await getProgramById(id);
      if (resp && !resp.error) {
        const activities = resp.activities;
        const activitiesCompleted: any = resp.program.activitiesCompleted;
        let activitiesCompletedArray: any[] = [];
        if (activitiesCompleted && activitiesCompleted != "") {
          activitiesCompletedArray = activitiesCompleted.split(",");
        }
        let otherActivities: any = [],
          completedActivities: any = [];
        if (activities) {
          activities.map((activity: any) => {
            if (activitiesCompletedArray.includes(activity.id.toString())) {
              completedActivities.push(activity);
            } else {
              otherActivities.push(activity);
            }
          });
        }

        const topics = resp.topics;
        const topicsCompleted: any = resp.program.topicsCompleted;
        let topicsCompletedArray: any[] = [];
        if (topicsCompleted && topicsCompleted != "") {
          topicsCompletedArray = topicsCompleted.split(",");
        }
        let otherTopics: any = [],
          completedTopics: any = [];
        if (topics) {
          topics.map((topic: any) => {
            if (topicsCompletedArray.includes(topic.id.toString())) {
              completedTopics.push(topic);
            } else {
              otherTopics.push(topic);
            }
          });
        }

        let nodeTitle = "",
          videoData: any = "";
        try {
          let parsedData = JSON.parse(resp.program.nodeTitle);
          if (parsedData) {
            nodeTitle = parsedData[language] || parsedData["en"];
          }

          let parsedVideoData = JSON.parse(resp.program.video);
          if (parsedVideoData) {
            videoData = parsedVideoData[language] || parsedVideoData["en"];
          }
        } catch (error) {}

        setOtherActivities(otherActivities);
        setTopicsCompleted(completedTopics);
        setTitle(nodeTitle);
        setIntroVideo(videoData);
      }
    }

    setLoading(false);
  };

  const getCertificateById = async () => {
    if (certificateImageLoading) return;
    setCertificateImageLoading(true);
    const certificateImage: any = await getUserCertificateById(id);
    if (!certificateImage.error) {
      setCertificateImage(certificateImage);
    }
    setCertificateImageLoading(false);
  };

  const onActivityClick = (activity: any) => {
    history.push({
      pathname: `/activity/${activity.id}`,
    });
  };

  const onRestartClick = () => {
    if (type === "topic") {
      history.push({
        pathname: `/topic/${id}`,
      });
    } else {
      history.push({
        pathname: `/learnings/${id}`,
      });
    }
  };

  const onTopicCompleted = (topic: any) => {
    history.push({
      pathname: `/review/topic/${topic.id}`,
    });
  };
  return (
    <IonPage className="page-review-program ">
      {!showIntro && (
        <RegisterHeader
          pageName={title || ""}
          backBtn={true}
          customClass="header-green"
        ></RegisterHeader>
      )}
      <IonContent className="page-content pb-0 pt-0">
        {loading ? (
          <Loader></Loader>
        ) : showIntro ? (
          <div className="page-reward-video stretch-container">
            <IonButton
              className="ion-button__back-btn"
              onClick={() => {
                isFullVideo = false;
                setShowIntro(false);
              }}
            >
              <IonImg src={Back} className="back-icon" />
            </IonButton>
            <VideoPlayer
              url={introVideo}
              onVideoEnd={() => {
                isFullVideo = false;
                setShowIntro(false);
              }}
              isFullScreen={true}
              hideFullScreen
              hideControl
              hideProgress
              showBottomBack={false}
            />
          </div>
        ) : (
          <div>
            <div className="topic-program__container bg-light-orange stretch-container">
              <div className="page-content__title">{`Review ${
                type == "topic" ? "Topic" : "program"
              }`}</div>
              <IonCard className="ion-no-margin capsul-card capsul-card__nothum">
                <IonList className="">
                  <IonItem
                    onClick={() => {
                      if (isConnectedToInternet()) {
                        isFullVideo = true;
                        setShowIntro(true);
                      }
                    }}
                  >
                    {/* <IonThumbnail slot="start" className="ion-no-padding ion-no-margin ion-margin-end"> */}
                    <IonImg
                      src={CirclePlay}
                      className="capsul-card__img_right-space"
                    />
                    {/* </IonThumbnail> */}
                    <IonLabel>Watch Introduction</IonLabel>
                    <IonImg src={PlayOrange} />
                  </IonItem>
                </IonList>
              </IonCard>

              <IonCard className="ion-no-margin capsul-card capsul-card__nothum">
                <IonList className="">
                  <IonItem
                    onClick={() => {
                      onRestartClick();
                    }}
                  >
                    {/* <IonThumbnail slot="start" className="ion-no-padding ion-no-margin ion-margin-end"> */}
                    <IonImg
                      src={Refresh}
                      className="capsul-card__img_right-space"
                    />
                    {/* </IonThumbnail> */}
                    <IonLabel>{`Restart ${
                      type === "topic" ? "topic" : "program"
                    }`}</IonLabel>
                    <IonImg src={PlayOrange} />
                  </IonItem>
                </IonList>
              </IonCard>

              {type !== "topic" ? (
                <IonCard className="ion-no-margin capsul-card capsul-card__nothum">
                  <IonList className="">
                    <IonItem
                      onClick={async () => {
                        await getCertificateById();
                      }}
                    >
                      {/* <IonThumbnail slot="start" className="ion-no-padding ion-no-margin ion-margin-end"> */}
                      {/* <IonImg
                        src={Refresh}
                        className="capsul-card__img_right-space"
                      /> */}
                      {/* </IonThumbnail> */}
                      <IonLabel>{`View Certificate`}</IonLabel>
                      {certificateImageLoading ? (
                        <IonSpinner
                          color="primary"
                          className="certificate-loader"
                        />
                      ) : (
                        <IonImg src={PlayOrange} />
                      )}
                    </IonItem>
                  </IonList>
                </IonCard>
              ) : null}
            </div>
            <div className="bg-white stretch-container">
              {otherActivities && otherActivities.length > 0 && (
                <div>
                  <div className="page__section-title">
                    {type == "topic" ? "Available" : "New"} activities
                  </div>
                  <LanguageAppText
                    tag={"p"}
                    className="page-content__infotext"
                    value={"Extra activities to help you learn more"}
                    textKey={"extra_activities"}
                  />
                  {otherActivities.map((activity: any, index: number) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          onActivityClick(activity);
                        }}
                      >
                        <ExtraActivityCard
                          label={activity.nodeFieldData.title}
                          thumbnail={activity.nodeFieldData.teaserImage}
                          //   midIcon={Coins}
                          //   rightIcon={PlayOrange}
                        ></ExtraActivityCard>
                      </div>
                    );
                  })}
                </div>
              )}

              {type === "topic" &&
                activitiesCompleted &&
                activitiesCompleted.length > 0 && (
                  <div className="">
                    <LanguageAppText
                      tag={"div"}
                      className="page__section-title"
                      value={"Completed activities"}
                      textKey={"completed_activities"}
                    />
                    <LanguageAppText
                      tag={"p"}
                      className="page-content__infotext"
                      value={"Go back to activities you already completed"}
                      textKey={"back_to_activity"}
                    />
                    {activitiesCompleted.map((activity: any, index: number) => {
                      return (
                        <IonCard
                          key={index}
                          className="ion-no-margin capsul-card capsul-card__extra-activities mb-20"
                          onClick={() => {
                            onActivityClick(activity);
                          }}
                        >
                          <IonList className="ion-no-padding">
                            <IonItem>
                              <div className="capsul-card__thum-group">
                                <IonThumbnail
                                  slot="start"
                                  className="ion-no-padding ion-no-margin ion-margin-end capsul-card__thum-group_overlay relative"
                                >
                                  <LanguageImage
                                    value={activity.nodeFieldData.teaserImage}
                                  />
                                </IonThumbnail>
                                <div className="icon__completed"></div>
                              </div>

                              <div className="capsul-card__mid">
                                <LanguageText
                                  tag={"label"}
                                  className="capsul-card__label"
                                  value={activity.nodeFieldData.title}
                                ></LanguageText>
                              </div>
                              <div className="capsul-card__last">
                                <img src={PlayOrange} />
                              </div>
                            </IonItem>
                          </IonList>
                        </IonCard>
                      );
                    })}
                  </div>
                )}
              {type === "program" &&
                topicsCompleted &&
                topicsCompleted.length > 0 && (
                  <div className="">
                    <LanguageAppText
                      tag={"div"}
                      className="page__section-title"
                      value={"Completed Topics"}
                      textKey={"completed_topics"}
                    />
                    <p>Go back to topics you already completed</p>
                    {topicsCompleted.map((topic: any, index: number) => {
                      return (
                        <IonCard
                          key={index}
                          className="ion-no-margin capsul-card capsul-card__extra-activities mb-20"
                          onClick={() => {
                            onTopicCompleted(topic);
                          }}
                        >
                          <IonList className="ion-no-padding">
                            <IonItem>
                              <div className="capsul-card__thum-group">
                                <IonThumbnail
                                  slot="start"
                                  className="ion-no-padding ion-no-margin ion-margin-end capsul-card__thum-group_overlay relative"
                                >
                                  <LanguageImage
                                    value={topic.nodeFieldData.teaserImage}
                                  />
                                </IonThumbnail>
                                <div className="icon__completed"></div>
                              </div>

                              <div className="capsul-card__mid">
                                <LanguageText
                                  tag={"label"}
                                  className="capsul-card__label"
                                  value={topic.nodeFieldData.title}
                                ></LanguageText>
                              </div>
                              <div className="capsul-card__last">
                                <img src={PlayOrange} />
                              </div>
                            </IonItem>
                          </IonList>
                        </IonCard>
                      );
                    })}
                  </div>
                )}
            </div>
          </div>
        )}
        {certificateImage && certificateImage != "" && (
          <Popup
            customClass="certificate-popup"
            onClose={() => {
              setCertificateImage("");
            }}
          >
            <div>
              <Certificate
                onBackClick={() => {
                  setCertificateImage("");
                }}
                imgPath={certificateImage}
              />
            </div>
          </Popup>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ReviewProgram;

import { IonContent, IonImg, IonLabel, IonList, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ButtonComponent from "../../components/common/button-component";
import PlayVideo from "../../assets/images/icons/play-story.svg";
import "./style.scss";
import BackButton from "../../components/common/button-component/back";
import ReactPlayer from "react-player";
import VideoPlayer from "../../components/videoplayer";

interface Props {
  screenType?: string;
  url?: string;
  thumbnail?: string;
  onRedo?: () => void;
  onPost?: () => void;
}

const RecorderVideo: React.FC<Props> = ({
  screenType,
  url,
  thumbnail,
  onRedo,
  onPost,
}) => {
  const [loading, setLoading] = useState(true);
  let postBtnText = {
    btnKey: "submit",
    btnValue: "Submit",
  };
  if (screenType) {
    postBtnText =
      screenType === "submitStory"
        ? {
            btnKey: "submit_story",
            btnValue: "Submit Story",
          }
        : {
            btnKey: "post_video_comment",
            btnValue: "Post Video Comment",
          };
  }

  return (
    <IonPage className="page-recorder-video">
      <BackButton />
      <IonContent className="page-content pb-0 pt-0">
        <div className="recorder-video__container stretch-container">
          <VideoPlayer
            url={url}
            thumbnail={thumbnail}
            isFullScreen={true}
            hideFullScreen={true}
            hideControl={true}
            hideProgress={true}
          />
        </div>
        <div className="recorder-video__btn-container stretch-container bg-light-orange mt-0">
          <ButtonComponent
            name="Redo"
            textKey={"redo"}
            customClass="redo__btn mr-10"
            outline
            onClick={() => {
              onRedo && onRedo();
            }}
          />
          <ButtonComponent
            name={postBtnText.btnValue}
            textKey={postBtnText.btnKey}
            customClass="post-video__btn ml-10"
            onClick={() => {
              onPost && onPost();
            }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RecorderVideo;

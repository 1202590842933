import React, { useEffect, useRef, useState } from "react";
import { IonContent, IonPage, IonSpinner } from "@ionic/react";

import { useHistory } from "react-router";
import RegisterHeader from "../../components/common/register-header";
import ButtonComponent from "../../components/common/button-component";
import { Chooser } from "@ionic-native/chooser";
import { imageCapture } from "../../utils/media.helper";
interface Props {
  getMedia: (fileObj: any) => void;
}
const UploadMedia = (props: Props) => {
  const { getMedia } = props;
  const onTakePicture = async () => {
    const imageData = await imageCapture();
    if (imageData) {
      getMedia(imageData);
    }
  };

  const chooseFromGallery = async () => {
    const respFile = await Chooser.getFile("image/*");
    getMedia(respFile);
  };
  return (
    <IonPage className="page-learning">
      <RegisterHeader pageName={"Upload Media"} backBtn={true}></RegisterHeader>
      <IonContent className="page-content pb-0">
        <ButtonComponent
          name={"Take a picture"}
          textKey={"take_a_picture"}
          customClass={"mt-30"}
          onClick={onTakePicture}
        />
        <ButtonComponent
          name={"Choose from gallery"}
          textKey={"choose_from_gallery"}
          customClass={"mt-30"}
          onClick={chooseFromGallery}
        />
      </IonContent>
    </IonPage>
  );
};
export default UploadMedia;

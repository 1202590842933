import { Capacitor } from "@capacitor/core";
import { File } from "@ionic-native/file";
import { IonContent, IonImg, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import WebView from "../../components/webview";
import { uploadFile } from "../../services/filetransfer.service";
import {
  getActivityById,
  getProgramActivityStatus,
  getTopicCompleted,
  markActivityComplete,
  programActivityStatus,
} from "../../services/program.service";
import {
  convertLocalToUTC,
  getFileDirectory,
  getFormattedUrl,
  getQueryStrings,
} from "../../utils/common.helper";

import { Http } from "@capacitor-community/http";
import { FileTransfer, FileTransferObject } from "@ionic-native/file-transfer";
import moment from "moment";
import ActivityExit from "../../assets/images/icons/activity-exit.svg";
import RewardAnimationData from "../../assets/reward.json";
import ButtonComponent from "../../components/common/button-component";
import Loader from "../../components/common/loader";
import ModalPopUp from "../../components/common/modalpopup";
import Popup from "../../components/common/popup";
import LanguageAppText from "../../components/languageapptext";
import UploadVideo from "../../components/uploadVideo";
import Messages from "../../config/messages";
import { AppContext } from "../../context/app.context";
import { analytics, commit, fetch } from "../../services/contentapi.service";
import localnotificationService from "../../services/localnotification.service";
import { isConnectedToInternet } from "../../services/network.service";
import {
  addOvanteTask,
  changeOvanteTaskStatus,
} from "../../services/todo.service";
import { addCoins, reduceCoins } from "../../services/user.service";
import {
  createCreditProfile,
  getCreditProfile,
  updateCreditProfile,
} from "../../services/usercreditprofile.service";
import { receiveMessage } from "../../utils/iframe.helper";
import UploadMedia from "../uploadmedia";
let eventData: any;
let iframeRef: any;
let programId: any,
  topicId: any,
  sessionId = 0;
let isExitModal = false;
let totalTime: any = 0,
  visitCount = 0,
  startTime: any = null,
  completeTime: any = null;

const Activity: React.FC = () => {
  let param: any = useParams();
  const { pathname }: any = useLocation();
  // let url = state.url;
  const toolName = getQueryStrings(pathname).toolName;
  let id = typeof param.id === "number" ? param.id : parseInt(param.id);
  const history = useHistory();
  const {
    toastMessage,
    language,
    playLottieAnimation,
    stopLottieAnimation,
  }: any = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [launchUrl, setLaunchUrl] = useState("");
  const [showExitModal, setShowExitModal] = useState(false);

  let interval: any;
  useEffect(() => {
    onLoad();

    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
      console.log(visitCount, totalTime);

      if (visitCount && totalTime) {
        postProgramActivityStatus();
      }
      isExitModal = false;
      iframeRef = null;
      programId = null;
      topicId = null;
      sessionId = 0;
      totalTime = 0;
      visitCount = 0;
      startTime = null;
      completeTime = null;
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(101, () => {
      if (isExitModal) {
        isExitModal = false;
        setShowExitModal(false);
      } else {
        // isExitModal = true;
        // setShowExitModal(true);
        iframeRef.contentWindow.postMessage(
          {
            type: "backclick",
          },
          "*"
        );
      }
    });
  };

  const onLoad = async () => {
    console.log("onLoad");
    let resp: any = await getActivityById(id);
    console.log("activity", resp);
    if (resp && !resp.error) {
      programId = resp.programId;
      topicId = resp.parent;
      let parsedData = JSON.parse(resp.topicPackageLink);
      let packageLink = parsedData[language] || parsedData["en"];
      let activityFolder = resp.nodeFieldData.activityKey;
      const activityURL = packageLink.endsWith("/")
        ? `${packageLink}${activityFolder}/index.html?random=${new Date().getTime()}`
        : `${packageLink}/${activityFolder}/index.html?random=${new Date().getTime()}`;
      setLaunchUrl(activityURL);
      await addProgramSessionTime();
    }
  };

  const addProgramSessionTime = async () => {
    if (programId && topicId) {
      const res: any = await getProgramActivityStatus(id);
      if (!res.error) {
        if (res) {
          totalTime = res.totalTime;
          visitCount = res.visitCount;
          if (visitCount) {
            visitCount = visitCount + 1;
          } else {
            visitCount = 1;
          }
          startTime = res.startTime || moment().format("YYYY-MM-DD hh:mm:ss");
          if (res.completedTime) {
            completeTime = res.completedTime;
          }
        } else {
          visitCount = 1;
          startTime = moment().format("YYYY-MM-DD hh:mm:ss");
        }
        if (interval) {
          clearInterval(interval);
          interval = null;
        }
        interval = setInterval(async () => {
          totalTime = totalTime + 5;
        }, 5000);
      }
    }
  };

  const postProgramActivityStatus = async () => {
    let statusReq = {
      progress: [
        {
          programId: programId,
          topicId: topicId,
          activityId: id,
          startTime: startTime,
          completedTime: completeTime || null,
          totalTime: totalTime,
          visitCount: visitCount,
        },
      ],
    };

    const statusRes: any = await programActivityStatus(statusReq);
  };
  const onReceiveMessage = async (event: any) => {
    if (!event.type) return;
    eventData = await receiveMessage(event);
    if (eventData) {
      if (eventData.type === "File-Upload") {
        setShowModal(true);
      } else if (eventData.type == "Activity-Completed") {
        let data = eventData.value.data;
        await commitData(data, true);
        await markActivityCompleted();
      } else if (eventData.type == "fetchData") {
        let resp: any = null;
        if (isConnectedToInternet()) {
          resp = await fetch(id);
          sessionId = resp.sessionId;
          resp["toolname"] = toolName || "";
        }
        if (resp && !resp.error) {
          sessionId = resp.sessionId;
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: resp,
            },
            "*"
          );
        } else {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: { toolname: toolName || "" },
            },
            "*"
          );
        }
      } else if (eventData.type == "commitData") {
        let data = eventData.value.data;
        await commitData(data);
      } else if (eventData.type == "closeActivity") {
        setShowExitModal(true);
        isExitModal = true;
      } else if (eventData.type == "addTask") {
        addOvanteTodo(eventData.value);
      } else if (eventData.type == "completeTask") {
        changeTodoStatus(eventData.value);
      } else if (eventData.type == "recordVideo") {
        setShowRecordModal(true);
      } else if (eventData.type == "analytics") {
        let data = eventData.value;
        let req = {
          type: data.analyticsType,
          programId: programId,
          topicId: topicId,
          activityId: id,
          key:
            typeof data.analyticsKey === "string"
              ? data.analyticsKey
              : JSON.stringify(data.analyticsKey),
          value:
            typeof data.analyticsValue === "string"
              ? data.analyticsValue
              : JSON.stringify(data.analyticsValue),
        };
        if (isConnectedToInternet()) {
          analytics(req);
        }
      } else if (eventData.type == "addCoins") {
        const { coins } = eventData.value;
        if (isConnectedToInternet()) {
          await addCoins({
            coins,
          });
        }
        playLottieAnimation(RewardAnimationData);
        setTimeout(() => {
          stopLottieAnimation();
        }, 3000);
      } else if (eventData.type == "reduceCoins") {
        const { coins } = eventData.value;
        await reduceCoins({
          coins,
        });
      } else if (eventData.type == "showToast") {
        const { message, variant } = eventData.value;
        toastMessage(message, variant);
      } else if (eventData.type == "openAppPage") {
        if (isConnectedToInternet()) {
          const { redirectObj } = eventData.value;
          history.push({
            pathname: redirectObj.page,
            state: redirectObj.state || {},
          });
        }
      } else if (eventData.type == "downloadCSVFromData") {
        const { data, fileName } = eventData.value;
        toastMessage("Downloading Started...");
        File.writeFile(`${File.externalRootDirectory}Download`, fileName, data)
          .then((_) => {
            console.log("Success ;-)");
            setTimeout(() => {
              toastMessage("File saved to Download folder");
            }, 3000);
          })
          .catch((err) => {
            File.writeExistingFile(
              `${File.externalRootDirectory}Download`,
              fileName,
              data
            )
              .then((_) => {
                console.log("Success ;-)");
                setTimeout(() => {
                  toastMessage("File saved to Download folder");
                }, 3000);
              })
              .catch((err) => {
                console.log("Failure", err);
              });
          });
      } else if (eventData.type == "downloadCSVFromURL") {
        const { data, fileName } = eventData.value;
        await downloadCSVFromURL(data, fileName);
      } else if (eventData.type == "createCreditProfile") {
        const { data } = eventData.value;
        let req = {
          jsonData: typeof data === "string" ? data : JSON.stringify(data),
        };
        let resp: any = await createCreditProfile(req);

        if (!resp.error) {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: resp.jsonData,
            },
            "*"
          );
        } else {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: "",
            },
            "*"
          );
        }
      } else if (eventData.type == "updateCreditProfile") {
        const { data } = eventData.value;
        let req = {
          jsonData: typeof data === "string" ? data : JSON.stringify(data),
        };
        let resp: any = await updateCreditProfile(req);

        if (!resp.error) {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: resp.jsonData,
            },
            "*"
          );
        } else {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: "",
            },
            "*"
          );
        }
      } else if (eventData.type == "getCreditProfile") {
        let resp: any = await getCreditProfile();
        console.log("resp", resp);
        if (!resp.error) {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: resp.jsonData,
            },
            "*"
          );
        } else {
          iframeRef.contentWindow.postMessage(
            {
              type: eventData.cbEvent,
              data: {},
            },
            "*"
          );
        }
      } else if (eventData.type == "getIsTopicCompleted") {
        await getIsTopicCompleted();
      }
    }
  };

  const downloadCSVFromURL = async (url: string, fileName: string) => {
    console.log("down", ` ${File.externalRootDirectory}Download/${fileName}`);
    try {
      if (Capacitor.getPlatform() == "ios") {
        const fileTransfer: FileTransferObject = FileTransfer.create();
        const path: string = getFileDirectory() + `${fileName}`;
        const res = await fileTransfer.download(url, path);
        // return {
        //   data: res.nativeURL,
        //   message: "Download success",
        // };
      } else {
        const res = await Http.downloadFile({
          url: `${url}`,
          filePath: `${File.externalRootDirectory}Download/${fileName}`,
          // fileDirectory: FilesystemDirectory.Documents,
        });
        console.log("res", res);
        toastMessage("File saved to Download folder");
      }
    } catch (error) {
      console.log("download error", error);
    }
  };

  const onActivityExitConfirm = () => {
    let data = eventData?.value?.data;
    if (data) {
      commitData(data);
    }
    isExitModal = false;
    setShowExitModal(false);
    history.goBack();
  };
  const commitData = async (data: any, isCompleted = false) => {
    let reqObj = {
      activityId: id,
      bookmarkData: data.bookmarkData || {},
      sessionStore: data.sessionStore || {},
      isCompleted: isCompleted,
      sessionId: sessionId,
    };
    const resp = await commit(reqObj);
  };

  const markActivityCompleted = async () => {
    let reqObj = {
      id: parseInt(id),
      responseNumber: 3,
    };
    const resp: any = await markActivityComplete(reqObj);
    if (!resp.error) {
      await markSessionComplete();
      if (resp.data && resp.data.length > 0) {
        history.push({
          pathname: `/activityrewards`,
          state: {
            data: JSON.stringify(resp.data),
            programId: resp.programId,
            activityTitle: resp.activityTitle,
            pendingActivities: JSON.stringify(resp.pendingActivities),
            programCompleted: resp.programCompleted,
          },
        });
      } else {
        history.push({
          pathname: `/activitycomplete`,
          state: {
            programId: resp.programId,
            activityTitle: resp.activityTitle,
            pendingActivities: JSON.stringify(resp.pendingActivities),
            programCompleted: resp.programCompleted,
          },
        });
      }
    } else if (resp.statusCode && resp.statusCode === 400) {
      // await markSessionComplete();
      // history.replace({
      //   pathname: `/tabs/experts`,
      // });
      history.goBack();
    }
  };

  const markSessionComplete = async () => {
    clearInterval(interval);
    interval = null;
    let statusReq = {
      progress: [
        {
          programId: programId,
          topicId: topicId,
          activityId: id,
          startTime: startTime,
          completedTime: moment().format("YYYY-MM-DD hh:mm:ss"),
          totalTime: totalTime,
          visitCount: visitCount,
        },
      ],
    };

    const statusRes: any = await programActivityStatus(statusReq);
    totalTime = 0;
    visitCount = 0;
  };
  const uploadFileToServer = async (fileObj: any) => {
    if (fileObj) {
      const resp: any = await uploadFile(
        fileObj.uri || fileObj.path,
        fileObj.mediaType || fileObj.type,
        fileObj.name || ""
      );
      if (!resp.error) {
        const parsedJson = JSON.parse(resp);
        iframeRef.contentWindow.postMessage(
          {
            type: eventData.cbEvent,
            data: {
              fileId: parsedJson.id,
              file: getFormattedUrl(parsedJson.uri),
            },
          },
          "*"
        );
        setShowModal(false);
      }
    }
  };

  const addOvanteTodo = async (data: any) => {
    let dueDate = data.dueDate ? convertLocalToUTC(data.dueDate) : null;
    let reqObj = {
      title: data.title || "",
      dueDate: dueDate ? dueDate : null,
      machineName: data.machineName,
      activityId: id,
      type: "task",
      isReminder: data.isReminder || false,
    };

    if (isConnectedToInternet()) {
      const resp: any = await addOvanteTask(reqObj);
      if (!resp.error) {
        if (data.isReminder && dueDate) {
          localnotificationService.schedule({
            id: resp.id,
            title: "Reminder",
            text: data.title,
            autoClear: true,
            data: { type: "reminder", id },
            trigger: {
              at: new Date(data.dueDate),
            },
            priority: 2,
          });
        }
        // toastMessage(Messages.ADD_TODO_SUCCESS, "success");
        iframeRef.contentWindow.postMessage(
          { type: eventData.cbEvent, data: true },
          "*"
        );
      } else {
        toastMessage(Messages.SERVER_ERROR, "primary");
        iframeRef.contentWindow.postMessage(
          { type: eventData.cbEvent, data: false },
          "*"
        );
      }
    }
  };

  const changeTodoStatus = async (data: any) => {
    const resp: any = await changeOvanteTaskStatus({
      machineName: data.machineName,
      isCompleted: true,
    });
    if (!resp.error) {
      localnotificationService.cancel(resp.id);
      // toastMessage(Messages.COMPLETED_TODO_SUCCESS, "success");
      iframeRef.contentWindow.postMessage(
        { type: eventData.cbEvent, data: true },
        "*"
      );
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
      iframeRef.contentWindow.postMessage(
        { type: eventData.cbEvent, data: false },
        "*"
      );
    }
  };

  const getIsTopicCompleted = async () => {
    const resp: any = await getTopicCompleted();
    if (!resp.error) {
      iframeRef.contentWindow.postMessage(
        { type: eventData.cbEvent, data: resp },
        "*"
      );
    }
  };

  return (
    <IonPage className="page-learning">
      {/* <RegisterHeader pageName={"Topic"} backBtn={true}></RegisterHeader> */}
      <IonContent>
        {launchUrl !== "" ? (
          <WebView
            getRef={(ref: any) => {
              iframeRef = ref;
            }}
            launchUrl={launchUrl}
            onReceiveMessage={onReceiveMessage}
          />
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: "100%",
            }}
          >
            <Loader></Loader>
            <br />
            <LanguageAppText
              tag={"span"}
              value={"Loading, Please Wait..."}
              textKey={"loading_please_wait"}
            />
          </div>
        )}
        {showModal && (
          <ModalPopUp
            showModal={showModal}
            onCloseModal={() => {
              setShowModal(false);
            }}
          >
            <UploadMedia
              getMedia={(fileObj: any) => {
                uploadFileToServer(fileObj);
              }}
            />
          </ModalPopUp>
        )}
        {showRecordModal && (
          <ModalPopUp
            showModal={showRecordModal}
            onCloseModal={() => {
              setShowRecordModal(false);
            }}
          >
            <UploadVideo
              onClose={() => {
                setShowRecordModal(false);
              }}
              onSuccess={(resObj: any) => {
                iframeRef.contentWindow.postMessage(
                  { type: eventData.cbEvent, data: resObj },
                  "*"
                );
                setShowRecordModal(false);
              }}
            />
          </ModalPopUp>
        )}
        {showExitModal && (
          <Popup
            customClass="program-intro-popup"
            onClose={() => {
              setShowExitModal(false);
              isExitModal = false;
            }}
          >
            <div className="program-popup-container">
              <IonImg src={ActivityExit} className="success-icon mb-10" />
              <LanguageAppText
                tag={"div"}
                className="program-popup-title text-deep-black font-bold mb-5"
                value={"Exit topic"}
                textKey={"exit_topic"}
              />
              <LanguageAppText
                tag={"div"}
                className="program-popup-description text-light-black font-medium mb-20"
                value={
                  "Please confirm that you want to exit the current topic. You can always pick back up later!"
                }
                textKey={"confirm_exit_topic"}
              />
              <ButtonComponent
                name="Confirm"
                onClick={onActivityExitConfirm}
                customClass="program-popup-btn mb-10"
                textKey={"confirm"}
              />
              <div
                onClick={() => {
                  setShowExitModal(false);
                  isExitModal = false;
                }}
                className=""
              >
                <LanguageAppText
                  tag={"span"}
                  className="program-popup__no-change text-light-blue font-medium"
                  value={"No, I don’t want to exit"}
                  textKey={"dont_exit"}
                />
              </div>
            </div>
          </Popup>
        )}
      </IonContent>
    </IonPage>
  );
};
export default Activity;

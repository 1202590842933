import HttpService, { HttpMethod } from "./http.service";

export const getCommunity = async (offset?: number) => {
  const response = await HttpService.execute({
    url: `/community/story${offset ? `?offset=${offset}` : ``}`,
  });
  return response;
};

export const getStoryUserCount = async () => {
  const response = await HttpService.execute({
    url: "/community/story/user-count",
  });
  return response;
};

export const getStoryDetails = async (id: number) => {
  const response = await HttpService.execute({
    url: `/community/story/${id}`,
  });
  return response;
};

export const getStoryComments = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/community/story/get-comments`,
    data: reqObj,
  });
  return response;
};

export const addStoryComment = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: "/community/story/comment",
    data: reqObj,
  });
  return response;
};

export const addStoryMediaComment = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: "/community/story/community/story/media-comment",
    data: reqObj,
  });
  return response;
};

export const editStoryComment = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: "/community/story/comment/edit",
    data: reqObj,
  });
  return response;
};

export const makeStoryLike = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: "/community/story/like",
    data: reqObj,
  });
  return response;
};

export const makeStoryCommetLike = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: "/community/story/comment/like",
    data: reqObj,
  });
  return response;
};

export const deleteStoryComment = async (id: number) => {
  const repsonse = await HttpService.execute({
    method: HttpMethod.DELETE,
    url: `/community/story/comment/${id}`,
  });
  return repsonse;
};

export const getHighlightedStories = async () => {
  const response = await HttpService.execute({
    url: "/community/story/highlighted",
  });
  return response;
};

export const getWatchCommunityVideo = async (id: number) => {
  const response = await HttpService.execute({
    url: `/community/story/watch-video/${id}`,
  });
  return response;
};

export const submitTempStory = async (reqObj: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/community/temp-story`,
    data: reqObj,
  });
  return response;
};

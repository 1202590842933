import {
  IonAvatar,
  IonButton,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
} from "@ionic/react";
import React from "react";
import InputText from "../common/inputtext";
import ProfileImage from "../common/profileimg";
import moment from "moment";
import "./style.scss";
import { getFormattedUrl } from "../../utils/common.helper";
import CoinsBg from "../../assets/images/icons/coins-bg.svg";
import LanguageAppText from "../languageapptext";

interface Props {
  data: any;
  onEditPick: any;
  profileImg: any;
}

const ProfileDetails: React.FC<Props> = ({ data, onEditPick, profileImg }) => {
  const level = (data && data.level) || 0;
  const name = (data && data.name) || "Your name";
  const coinCount = (data && data.availableCoin) || 0;
  const joinedAgo = (data && moment(data.joiningDate).fromNow(true)) || null;
  return (
    <div className="profile__details ion-padding">
      <div className="profile__level ion-margin-top ion-margin-start">
        <InputText name="level" value={level} readonly></InputText>
        <IonLabel className="ion-item__label">
          <LanguageAppText
            tag={"span"}
            //className=""
            value={"Level"}
            textKey={"level"}
          />
        </IonLabel>
      </div>
      <div className="ion-avatar__container">
        <ProfileImage
          imgPath={profileImg}
          isEditable={true}
          onEditPick={onEditPick}
        ></ProfileImage>
        <IonLabel className="ion-label__profile">
          <div className="profile__name">{name}</div>
          {joinedAgo && (
            <LanguageAppText
              tag={"div"}
              className="profile__joining"
              value={`Joined ${joinedAgo} ago`}
              textKey={"joined_ago_duration"}
              metaData={{ joinDuration: joinedAgo }}
            />
          )}
        </IonLabel>
      </div>
      <div className="profile__coins ion-margin-top ion-margin-end">
        <IonImg src={CoinsBg} className="coins-bg-image" />
        <InputText name="count" value={coinCount} readonly></InputText>
        <IonLabel className="ion-item__label">
          <LanguageAppText tag={"span"} value={"Coins"} textKey={"coins"} />
        </IonLabel>
      </div>
    </div>
  );
};
export default ProfileDetails;

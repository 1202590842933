import {
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonToggle,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import RegisterHeader from "../../components/common/register-header";
import PolygonIcon from "../../assets/images/icons/icon-polygon.svg";

import "./style.scss";
import { AppContext } from "../../context/app.context";
import { changeSettings, deleteAccount } from "../../services/user.service";
import storageSevice from "../../services/storage.sevice";
import { USER_SETTINGS } from "../../config/appconfig";
import BottomDrawer from "../../components/bottomdrawer";
import BackDrop from "../../components/common/backdrop";
import { getLanguages } from "../../services/app.service";
import ObserverService from "../../services/observer.service";
import { getLangDescriptor } from "../../services/lang-descriptor.service";
import Loader from "../../components/common/loader";
import LanguageAppText from "../../components/languageapptext";
import Popup from "../../components/common/popup";
import ButtonComponent from "../../components/common/button-component";
import ModalPopUp from "../../components/common/modalpopup";
import CheckBox from "../../components/common/checkbox";
import { getStringByLanguage } from "../../utils/common.helper";
import Messages from "../../config/messages";

let isShowRemove = false;
const ProfileSettings: React.FC = () => {
  const LANGUAGE = "language";
  const {
    toastMessage,
    settings,
    setSettings,
    lanChange,
    language,
    getDescriptorFile,
    descEnFile,
    descFile,
    setHasToken,
  }: any = useContext(AppContext);
  const history: any = useHistory();
  const [isBackDropVisible, setIsBackDropVisible] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [notificationSetting, setNotificationSetting] = useState(null);
  const [languageSetting, setLanguageSetting] = useState(null);
  const [isBackDropOpen, setIsBackDropOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [confirmCheck, setConfirmCheck] = useState(false);
  const [showConfirmError, setShowConfirmError] = useState(false);

  useEffect(() => {
    if (!notificationSetting && !languageSetting && settings) {
      setNotificationSetting(settings.notifications);
      setLanguageSetting(settings.languageCode || "en");
    }
  }, [settings]);

  useEffect(() => {
    setTimeout(() => {
      fetchLangauages();
    }, 250);
  }, []);

  useEffect(() => {
    fetchLatestLangDescriptor();
  }, [language]);

  useEffect(() => {
    isShowRemove = showDeletePopup;
  }, [showDeletePopup]);

  const fetchLatestLangDescriptor = async () => {
    setLoading(true);
    await getLangDescriptor(language);
    await getDescriptorFile(language);
    setLoading(false);
  };

  const fetchLangauages = async () => {
    let resp: any = await getLanguages();
    setLoading(false);
    if (!resp.error) {
      resp = resp.filter((item: any) => {
        return item.isActive;
      });
      setLanguages(resp);
    }
  };

  const postSettings = async (notification?: any, langauge?: any) => {
    const data = {
      notifications: notification == null ? notificationSetting : notification,
      languageCode: langauge == null ? languageSetting : langauge,
    };
    const resp: any = await changeSettings(data);
    if (!resp.error) {
      lanChange(langauge == null ? languageSetting : langauge);
      setSettings(data);
      await storageSevice.set(USER_SETTINGS, JSON.stringify(data));
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  const handleNotificationToggle = (e: any) => {
    setNotificationSetting(e.detail.checked);
    postSettings(e.detail.checked, null);
  };

  const handleTOCClick = () => {
    history.push("/tocdetails");
  };

  const handlePreferredNumber = () => {
    history.push("/changeprefferednumber");
  };

  const handleEditProfileClick = () => {
    history.push("/editprofile");
  };

  const handleBackDrop = (state: boolean) => {
    setIsBackDropVisible(state);
  };

  const handleLanguageChangeClick = () => {
    if (languages.length > 2) {
      ObserverService.handleEvents(LANGUAGE, { state: "open" });
      setIsBackDropOpen(true);
    } else if (languages.length == 2) {
      let temp: any = [...languages];
      const index = temp.findIndex((val: any) => val.code == languageSetting);
      temp.splice(index, 1);
      setLanguageSetting(temp[0].code);
      postSettings(null, temp[0].code);
      toastMessage("Language changed to " + temp[0].title, "success");
    }
  };

  const handleLanguageSelect = (e: any) => {
    if (isBackDropOpen) {
      ObserverService.handleEvents(LANGUAGE, { state: "close" });
      setIsBackDropOpen(false);
    }
    if (languageSetting != e.detail.value) {
      setLanguageSetting(e.detail.value);
      postSettings(null, e.detail.value);
    }
  };

  const renderLanguageItems = () => {
    return languages.map((item: any, index: any) => {
      return (
        <IonItem key={item.name}>
          <IonLabel>{item.title}</IonLabel>
          <IonRadio slot="end" value={item.code} />
        </IonItem>
      );
    });
  };

  const renderLanguages = () => {
    return (
      <IonList className="ion-list">
        <IonRadioGroup
          className="ion-radio-group"
          value={languageSetting}
          onIonChange={handleLanguageSelect}
        >
          {renderLanguageItems()}
        </IonRadioGroup>
      </IonList>
    );
  };

  const onDeleteConfirmation = async () => {
    if (!confirmCheck) {
      setShowConfirmError(true);
    } else {
      const resp: any = await deleteAccount();
      if (!resp.error) {
        toastMessage(Messages.ACCOUNT_DELETE_SUCCESS, "primary");
        setShowDeletePopup(false);
        setTimeout(async () => {
          await storageSevice.clearAll();
          setHasToken("false");
          history.replace({
            pathname: `/introduction`,
          });
        }, 1000);
      } else {
        toastMessage(Messages.SERVER_ERROR, "primary");
      }
    }
  };
  return (
    // <div className="page-profile-setting-container">
    <div>
      <IonPage className="page-profile-setting">
        <RegisterHeader
          pageName="Settings"
          textKey={"settings"}
          backBtn={true}
        ></RegisterHeader>
        {loading ? (
          <Loader customClass="loader-page-center" />
        ) : (
          <>
            <IonContent fullscreen={true} className="page-content">
              <LanguageAppText
                tag={"h1"}
                className="page-profile-setting__title text-green ion-no-margin mb-20"
                value={"Notifications"}
                textKey={"notifications"}
              />
              <div className="setting__container mb-20">
                <LanguageAppText
                  tag={"p"}
                  className="setting__name text-black ion-no-margin"
                  value={"Allow notifications"}
                  textKey={"allow_notifications"}
                />
                <IonItem className="ion-item">
                  <IonToggle
                    checked={
                      notificationSetting != null
                        ? notificationSetting || false
                        : true
                    }
                    onIonChange={handleNotificationToggle}
                  />
                </IonItem>
              </div>
              <LanguageAppText
                tag={"h1"}
                className="page-profile-setting__title text-green ion-no-margin mb-20 ion-padding-top"
                value={"Profile"}
                textKey={"profile"}
              />
              <div
                className="setting__container mb-20"
                onClick={handleEditProfileClick}
              >
                <LanguageAppText
                  tag={"p"}
                  className="setting__name text-black ion-no-margin"
                  value={"Edit profile"}
                  textKey={"edit_profile"}
                />
                <IonImg src={PolygonIcon} className="setting__icon" />
              </div>
              {/* {languages && languages.length > 1 && ( */}
              <div
                className="setting__container mb-20"
                onClick={handlePreferredNumber}
              >
                <LanguageAppText
                  tag={"p"}
                  className="setting__name text-black ion-no-margin"
                  value={"Change phone number"}
                  textKey={"change_phone"}
                />
                <IonImg src={PolygonIcon} className="setting__icon" />
              </div>
              {/* )} */}
              <div
                className="setting__container mb-20"
                onClick={handleLanguageChangeClick}
              >
                <LanguageAppText
                  tag={"p"}
                  className="setting__name text-black ion-no-margin"
                  value={"Change language"}
                  textKey={"change_language"}
                />
                <IonImg src={PolygonIcon} className="setting__icon" />
              </div>
              <div
                className="setting__container mb-20"
                onClick={handleTOCClick}
              >
                <LanguageAppText
                  tag={"p"}
                  className="setting__name text-black ion-no-margin"
                  value={"Terms and conditions"}
                  textKey={"tnc"}
                />
                <IonImg src={PolygonIcon} className="setting__icon" />
              </div>
              <div
                className="setting__container mb-20"
                onClick={() => {
                  setShowDeletePopup(true);
                }}
              >
                <LanguageAppText
                  tag={"p"}
                  className="setting__name text-black ion-no-margin"
                  value={"Delete My Account"}
                  textKey={"delete_my_account"}
                />
              </div>
              {showDeletePopup && (
                <ModalPopUp
                  showModal={true}
                  customClass="delete-pop-up"
                  onCloseModal={() => {
                    setShowDeletePopup(false);
                    setShowConfirmError(false);
                    setConfirmCheck(false);
                  }}
                >
                  <div className="delete-account-container">
                    <LanguageAppText
                      tag="div"
                      className="delete-popup-text text-light-black font-medium mb-10"
                      textKey={"delete_account_msg"}
                      value="Are you sure that you want to delete your account?"
                    />
                    <LanguageAppText
                      tag="div"
                      className="delete-popup-text text-light-black font-medium mb-10"
                      textKey={"delete_account_msg_1"}
                      value="This process is irreversible."
                    />
                    <CheckBox
                      label={getStringByLanguage(
                        "I Confirm",
                        descFile,
                        descEnFile
                      )}
                      isChecked={confirmCheck}
                      onClick={(e: any) => {
                        setConfirmCheck(e.target.checked);
                        if (e.target.checked) {
                          setShowConfirmError(false);
                        } else {
                          setShowConfirmError(true);
                        }
                      }}
                    />
                    {showConfirmError && (
                      <LanguageAppText
                        tag="div"
                        className="validationError error"
                        textKey={"delete_account_error_msg"}
                        value="Please Check The Confirmation"
                      />
                    )}
                    <div className="delete-account-button-wrapper mt-20">
                      <ButtonComponent
                        name="Yes"
                        textKey={"yes"}
                        onClick={() => {
                          onDeleteConfirmation();
                        }}
                        customClass="delete-popup-button mr-10"
                      ></ButtonComponent>
                      <ButtonComponent
                        name="No"
                        textKey={"no"}
                        outline
                        onClick={() => {
                          setShowDeletePopup(false);
                        }}
                        customClass="delete-popup-button ml-10"
                      ></ButtonComponent>
                    </div>
                  </div>
                </ModalPopUp>
              )}
            </IonContent>
            <BackDrop
              backdropVisible={isBackDropVisible}
              isOutsideClick={true}
            ></BackDrop>
          </>
        )}
      </IonPage>
      {languages.length > 2 && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            zIndex: 101,
            width: "100%",
          }}
        >
          <BottomDrawer
            type={LANGUAGE}
            hideBackDrop={handleBackDrop}
            backBtnText={"Change language"}
          >
            {renderLanguages()}
          </BottomDrawer>
        </div>
      )}
    </div>
  );
};

export default ProfileSettings;

import {
  IonCard,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import Popup from "../../components/common/popup";
import RegisterHeader from "../../components/common/register-header";
import VideoPlayer from "../../components/videoplayer";
import { AppContext } from "../../context/app.context";
import { markProgramSelect } from "../../services/program.service";
import ExpertDefault from "../../assets/images/global-default_new.jpeg";
import Success from "../../assets/images/icons/success-icon.svg";
import "./style.scss";
import Loader from "../../components/common/loader";
import LanguageText from "../../components/languagetext";
import { INIT_STATE_ONBOARD } from "../../config/appconfig";
import { getFormattedUrl } from "../../utils/common.helper";
import BackButton from "../../components/common/button-component/back";
import LanguageAppText from "../../components/languageapptext";
import Messages from "../../config/messages";

const ProgramIntro: React.FC = () => {
  const { toastMessage, language, initAppState }: any = useContext(AppContext);
  const { state }: any = useLocation();
  const history: any = useHistory();
  const program = state.program;
  const isCurrent = state.isCurrent;
  const currentProgram = state.currentProgram;
  const [showIntro, setShowIntro] = useState(program.video); //program.video
  const [showPopup, setShowPopup] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  let metaData = null;
  let videoData = null;
  let currentProgramTitle = null;
  try {
    let parsedData = JSON.parse(program.metaData);
    if (parsedData) {
      metaData = parsedData[language] || parsedData["en"];
    }
    let parsedVideoData = JSON.parse(program.video);
    if (parsedVideoData) {
      videoData = parsedVideoData[language] || parsedVideoData["en"];
    }

    if (currentProgram && currentProgram.length > 0) {
      let parsedCurrentNodeData = JSON.parse(currentProgram[0].nodeTitle);
      if (parsedCurrentNodeData) {
        currentProgramTitle =
          parsedCurrentNodeData[language] || parsedCurrentNodeData["en"];
      }
    }
  } catch (error) {}

  const onVideoEnd = () => {
    console.log("on end");
    setShowIntro(false);
  };

  const onStartAccess = async () => {
    if (isCurrent) {
      setShowPopup(true);
    } else {
      await markExpertSelect();
    }
  };

  const onConfirmChange = async () => {
    await markExpertSelect();
  };

  const markExpertSelect = async () => {
    setButtonLoading(true);
    const resp: any = await markProgramSelect({
      id: program.id,
    });
    setButtonLoading(false);
    if (!resp.error) {
      // if (initAppState === INIT_STATE_ONBOARD) {
      //   history.push({
      //     pathname: `/congratulation`,
      //   });
      //   return;
      // }
      history.push({
        pathname: `/learnings/${program.id}`,
      });
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
  };
  return (
    <IonPage className="page-program-intro">
      {!showIntro && (
        <div
          className="program-header"
          style={{
            backgroundColor: `${
              metaData && metaData.bgColor ? metaData.bgColor : "#1e9454"
            }`,
          }}
        >
          <BackButton></BackButton>
          {/* <RegisterHeader pageName="" backBtn={true}></RegisterHeader> */}
          {/* <IonImg src={ExpertDefault} className="expert-default" /> */}
          <IonImg
            src={
              metaData && metaData.expertImage
                ? getFormattedUrl(metaData.expertImage.uri)
                : ExpertDefault
            }
            className="expert-default"
          />
        </div>
      )}
      <IonContent className="page-content pb-0">
        {showIntro ? (
          <div className="page-reward-video stretch-container">
            <VideoPlayer
              isPlaying={true}
              url={getFormattedUrl(videoData.uri || videoData)}
              isFullScreen={true}
              hideFullScreen
              hideControl
              hideProgress
              showBottomBack={false}
              onVideoEnd={onVideoEnd}
            />
          </div>
        ) : (
          <div className="page-content__container">
            <LanguageText
              tag={"div"}
              className="program-title text-deep-black font-bold"
              value={program.nodeTitle}
            ></LanguageText>

            <div className="expert-name text-blue mb-10">
              {metaData ? metaData.expertName : ""}
            </div>

            {program.description && (
              <LanguageText
                tag={"div"}
                className="program-description text-light-black font-medium mb-20"
                value={program.description}
              ></LanguageText>
            )}

            {buttonLoading ? (
              <Loader></Loader>
            ) : (
              <ButtonComponent
                name="Start Program"
                textKey={"start_program"}
                onClick={onStartAccess}
                customClass="program-start-btn"
                metaData={{ programName: "Program" }}
              />
            )}
            <div
              onClick={() => {
                history.goBack();
              }}
            >
              <LanguageAppText
                tag={"div"}
                className="other-program-back text-light-blue font-medium"
                value={"Go back to other programs"}
                textKey={"back_to_program"}
              />
            </div>
          </div>
        )}
        {showPopup && (
          <Popup
            customClass="program-intro-popup"
            onClose={() => {
              setShowPopup(false);
            }}
          >
            <div className="program-popup-container">
              <IonImg src={Success} className="success-icon mb-10" />
              <LanguageAppText
                tag={"div"}
                className="program-popup-title text-deep-black font-bold mb-5"
                value={"Confirm change"}
                textKey={"confirm_change"}
              />
              <div className="program-popup-description text-light-black font-medium mb-20">
                <LanguageAppText
                  tag={"span"}
                  value={
                    "Please confirm that you want to change the expert you are working with."
                  }
                  textKey={"confirm_expert_change"}
                />
                <LanguageAppText
                  tag={"span"}
                  value={`Your progress in ${currentProgramTitle} won’t be lost and you can always continue again!`}
                  textKey={"continue_program"}
                  metaData={{ programName: currentProgramTitle }}
                />
              </div>
              {buttonLoading ? (
                <Loader></Loader>
              ) : (
                <ButtonComponent
                  name="Confirm"
                  textKey={"confirm"}
                  onClick={onConfirmChange}
                  customClass="program-popup-btn mb-10"
                />
              )}
              <div
                onClick={() => {
                  setShowPopup(false);
                  setTimeout(() => {
                    history.goBack();
                  }, 500);
                }}
              >
                <LanguageAppText
                  tag={"div"}
                  className="program-popup__no-change text-light-blue font-medium"
                  value={"No, I don’t want to change experts"}
                  textKey={"dont_change_expert"}
                />
              </div>
            </div>
          </Popup>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ProgramIntro;

import { IonContent, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import RegisterHeader from "../../components/common/register-header";

import { AppContext } from "../../context/app.context";
import { checkNumber, deleteAccount } from "../../services/user.service";

import LanguageAppText from "../../components/languageapptext";
import ButtonComponent from "../../components/common/button-component";
import ModalPopUp from "../../components/common/modalpopup";
import CheckBox from "../../components/common/checkbox";
import Messages from "../../config/messages";
import InputText from "../../components/common/inputtext";
import { formatPhoneNumber } from "../../utils/common.helper";
import { resendOTP } from "../../services/auth.service";
let interval: any = null;

const DeleteAccount: React.FC = () => {
  const { toastMessage }: any = useContext(AppContext);

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [confirmCheck, setConfirmCheck] = useState(false);
  const [showConfirmError, setShowConfirmError] = useState(false);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [showOTPError, setShowOTPError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [isCheckNumber, setIsCheckNumber] = useState(false);
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [remainingTime, setRemainingTime]: any = useState("01:00");
  const optTime = 60 * 1;

  const startTimer = (duration: any) => {
    let timer: any = duration;
    let minutes: any, seconds: any;
    interval = setInterval(function () {
      const min: any = timer / 60;
      const sec: any = timer % 60;
      minutes = parseInt(min, 10);
      seconds = parseInt(sec, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      if (--timer < 0) {
        timer = duration;
      }
      setRemainingTime(minutes + ":" + seconds);
      if (interval != null && minutes == "00" && seconds == "00") {
        clearInterval(interval);
        interval = null;
      }
    }, 1000);
  };

  const confirmNumber = async () => {
    setIsCheckNumber(true);
    const resp: any = await checkNumber("+91" + phoneNumber);
    if (!resp.error) {
      setShowPhoneError(false);
      setShowDeletePopup(true);
    } else {
      setShowPhoneError(true);
    }
    setIsCheckNumber(false);
  };
  const onDeleteConfirmation = async () => {
    if (!confirmCheck) {
      setShowConfirmError(true);
    } else {
      const resp: any = await deleteAccount();
      if (!resp.error) {
        toastMessage(Messages.ACCOUNT_DELETE_SUCCESS, "primary");
        setShowDeletePopup(false);
      } else {
        toastMessage(Messages.SERVER_ERROR, "primary");
      }
    }
  };

  const handleResendClick = async () => {
    const resp: any = await resendOTP({
      phoneNumber: "+91" + phoneNumber,
    });
    if (!resp.erro) {
      startTimer(optTime);
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  return (
    // <div className="page-profile-setting-container">
    <div>
      <IonPage className="page-profile-setting">
        <RegisterHeader
          pageName="Delete My Account"
          textKey={"delete_my_account"}
          backBtn={false}
        ></RegisterHeader>
        <>
          <IonContent fullscreen={true} className="page-content">
            <div>
              <InputText
                label="Enter your phone number"
                labelKey={"enter_your_phone"}
                name="phoneNumber"
                value={phoneNumber}
                onChange={(val: string) => {
                  setPhoneNumber(val);
                }}
                placeholder="Enter phone number"
                placeholderKey={"enter_phone"}
                maxlength={10}
                prefix={"+91"}
                inputMode={"numeric"}
              ></InputText>
              {showPhoneError && (
                <div className="validationError error ">
                  Phone Number Not Found
                </div>
              )}
              <ButtonComponent
                name="Submit"
                textKey={"submit"}
                isEnabled={phoneNumber.trim().length != 10 || isCheckNumber}
                onClick={() => {
                  confirmNumber();
                }}
                customClass="delete-popup-button mt-20"
              ></ButtonComponent>
            </div>
            {showDeletePopup && (
              <ModalPopUp
                showModal={true}
                customClass="delete-pop-up"
                onCloseModal={() => {
                  setShowDeletePopup(false);
                  setShowConfirmError(false);
                  setConfirmCheck(false);
                }}
              >
                <div className="delete-account-container">
                  <LanguageAppText
                    tag="div"
                    className="delete-popup-text text-light-black font-medium mb-10"
                    textKey={"delete_account_msg"}
                    value="Are you sure that you want to delete your account?"
                  />
                  <LanguageAppText
                    tag="div"
                    className="delete-popup-text text-light-black font-medium mb-10"
                    textKey={"delete_account_msg_1"}
                    value="This process is irreversible."
                  />
                  <CheckBox
                    label={"I Confirm"}
                    isChecked={confirmCheck}
                    onClick={(e: any) => {
                      setConfirmCheck(e.target.checked);
                      if (e.target.checked) {
                        setShowConfirmError(false);
                      } else {
                        setShowConfirmError(true);
                      }
                    }}
                  />
                  {showConfirmError && (
                    <LanguageAppText
                      tag="div"
                      className="validationError error "
                      textKey={"delete_account_error_msg"}
                      value="Please Check The Confirmation"
                    />
                  )}
                  <div className="delete-account-button-wrapper mt-20">
                    <ButtonComponent
                      name="Yes"
                      textKey={"yes"}
                      onClick={() => {
                        if (confirmCheck) {
                          setShowDeletePopup(false);
                          setShowConfirmError(false);
                          setConfirmCheck(false);
                          setShowOTPPopup(true);
                          startTimer(optTime);
                        } else {
                          setShowConfirmError(true);
                        }
                      }}
                      customClass="delete-popup-button mr-10"
                    ></ButtonComponent>
                    <ButtonComponent
                      name="No"
                      textKey={"no"}
                      outline
                      onClick={() => {
                        setShowDeletePopup(false);
                      }}
                      customClass="delete-popup-button ml-10"
                    ></ButtonComponent>
                  </div>
                </div>
              </ModalPopUp>
            )}
            {showOTPPopup && (
              <ModalPopUp
                showModal={true}
                customClass="delete-pop-up"
                onCloseModal={() => {
                  clearInterval(interval);
                  interval = null;
                  setRemainingTime("01:00");
                  setShowOTPPopup(false);
                  setShowOTPError(false);
                }}
              >
                <div className="delete-account-container">
                  <InputText
                    name="otp"
                    value={otp}
                    type="number"
                    label="Enter OTP"
                    labelKey={"enter_otp"}
                    maxlength={4}
                    placeholder="- - - -"
                    onChange={(val: string) => {
                      setOtp(val);
                    }}
                  ></InputText>
                  {remainingTime != "00:00" ? (
                    <LanguageAppText
                      tag={"p"}
                      className="page-content__otptext-resent"
                      value={`${remainingTime} OTP has been sent!`}
                      textKey={"otp_resent"}
                      metaData={{ remainingTime: remainingTime }}
                    />
                  ) : (
                    // <p className="page-content__otptext-resent">
                    //   Resend OTP after {remainingTime}
                    // </p>
                    <div onClick={handleResendClick}>
                      <LanguageAppText
                        tag={"p"}
                        className="page-content__otptext pt-0"
                        value={"Resend OTP"}
                        textKey={"resend_otp"}
                      />
                    </div>
                  )}
                  {showOTPError && (
                    <LanguageAppText
                      tag="div"
                      className="validationError error "
                      textKey={"invalid_otp"}
                      value="invalid OTP."
                    />
                  )}
                </div>
              </ModalPopUp>
            )}
          </IonContent>
        </>
      </IonPage>
    </div>
  );
};

export default DeleteAccount;

import "./style.scss";

import { IonContent, IonPage } from "@ionic/react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import Loader from "../../components/common/loader";
import RegisterHeader from "../../components/common/register-header";
import RewardsCard from "../../components/rewardcard";
import { AppContext } from "../../context/app.context";
import { getRedeemedRewards } from "../../services/reward.service";
import {
  checkExpiresOn,
  formatDateToSingleChar,
} from "../../utils/common.helper";
import LanguageAppText from "../../components/languageapptext";

const RedeemedRewards: React.FC = () => {
  const history: any = useHistory();
  const { toastMessage }: any = useContext(AppContext);
  const [expiredRewards, setExpiredRewards]: any = useState(null);
  const [redeemedRewards, setRedeemedRewards]: any = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchRedeemedRewards();
  }, []);

  const fetchRedeemedRewards = async () => {
    const resp: any = await getRedeemedRewards();
    if (!resp.eroor) {
      const expiredData: any = [],
        redeemedArray: any = [];
      resp.map((item: any, index: number) => {
        if (checkExpiresOn(item.expiresOn) == "expired") {
          expiredData.push(item);
        } else {
          redeemedArray.push(item);
        }
      });
      setRedeemedRewards(redeemedArray);
      setExpiredRewards(expiredData);
    } else {
      toastMessage(resp.message, "primary");
    }
    setLoading(false);
  };

  const handleRewardClick = (id: number) => {
    history.push(`/rewardinfo/view/${id}`);
  };

  const renderRedeemedRewards = () => {
    return redeemedRewards.map((item: any, index: number) => {
      const redeemedOn = item.redeemedOn ? item.redeemedOn : new Date();
      return (
        <RewardsCard
          key={item.rewardId}
          name={item.name}
          imgPath={item.iconImage}
          onClick={() => {
            handleRewardClick(item.rewardId);
          }}
          expiresOn={checkExpiresOn(item.expiresOn)}
          redeemedOn={formatDateToSingleChar(
            moment(redeemedOn).fromNow(),
            redeemedOn
          )?.replace("ago", "")}
        ></RewardsCard>
      );
    });
  };

  const renderExpiredRewards = () => {
    return expiredRewards.map((item: any, index: number) => {
      return (
        <RewardsCard
          key={item.rewardId}
          name={item.name}
          imgPath={item.iconImage}
          expiresOn={formatDateToSingleChar(
            moment(item.expiresOn).fromNow(),
            item.expiresOn
          )?.replace("ago", "")}
        ></RewardsCard>
      );
    });
  };

  return (
    <IonPage
      className={`page-redeemed-rewards ${
        !expiredRewards || (expiredRewards && expiredRewards.length === 0)
          ? "page-redeemed-rewards-orange"
          : ""
      }`}
    >
      <RegisterHeader
        pageName="Redeemed rewards"
        textKey={"redeemed_rewards"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent className="page-content pb-0 pt-0">
        {loading ? (
          <Loader customClass="loader-page-center"></Loader>
        ) : (
          <>
            {redeemedRewards && (
              <div className="current-rewards stretch-container bg-light-orange">
                {renderRedeemedRewards()}
              </div>
            )}
            {expiredRewards && expiredRewards.length > 0 && (
              <div className="expired-rewards stretch-container bg-white">
                <LanguageAppText
                  tag={"div"}
                  className="expired-rewards__title text-green font-medium mb-10"
                  value={"Expired rewards"}
                  textKey={"expired_rewards"}
                />
                {renderExpiredRewards()}
              </div>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default RedeemedRewards;

import { Capacitor } from "@capacitor/core";
import { Network } from "@ionic-native/network";
import Messages from "../config/messages";
import { getStringByLanguage } from "../utils/common.helper";
import ProgramSessionService from "./programsession.service";
import SyncService from "./sqlite/sync.service";

let toastPreset: any = null;
let toastDismiss: any = null;
let networkState: string = Network.type;
let descEnFile: any = {},
  descFile: any = {};
const showMessage = (
  message: string,
  present: any,
  dismiss: any,
  variant = "success"
) => {
  const duration = message === Messages.NO_INTERNET_MSG ? 0 : 1500;
  message = getStringByLanguage(message, descFile, descEnFile);
  present({
    buttons: [{ text: "OK", handler: () => dismiss() }],
    color: variant,
    message,
    duration: duration,
  });
};

export const networkCheckInit = (
  present: any,
  dismiss: any,
  desEnFile: any,
  desFile: any
) => {
  toastPreset = present;
  toastDismiss = dismiss;
  descEnFile = desEnFile;
  descFile = desFile;
  if (Capacitor.getPlatform() == "web") {
    window.addEventListener("offline", () => {
      console.log("network offine!", navigator.onLine);
      showMessage(Messages.NO_INTERNET_MSG, present, dismiss, "primary");
    });

    window.addEventListener("online", () => {
      console.log("network online!", navigator.onLine);
      showMessage(Messages.INTERNET_AVL_MSG, present, dismiss);
    });
  } else {
    Network.onConnect().subscribe(() => {
      console.log("network connected!", Network.type);
      if (networkState != Network.type) {
        if (Capacitor.getPlatform() !== "web") {
          SyncService.instance.syncToServer();
          ProgramSessionService.instance.getSyncData();
        }
        dismiss();
        setTimeout(() => {
          showMessage(Messages.INTERNET_AVL_MSG, present, dismiss);
        }, 500);
        networkState = Network.type;
      }
    });

    Network.onDisconnect().subscribe(() => {
      networkState = Network.type;
      dismiss();
      setTimeout(() => {
        showMessage(Messages.NO_INTERNET_MSG, present, dismiss, "primary");
      }, 500);
    });

    Network.onChange().subscribe(() => {
      // console.log("network connected!", Network.type);
      // networkState = Network.type;
      // if (Network.type == "none") {
      //   setTimeout(() => {
      //     showMessage(Messages.NO_INTERNET_MSG, present, dismiss, "primary");
      //   }, 500);
      // } else {
      //   setTimeout(() => {
      //     showMessage(Messages.INTERNET_AVL_MSG, present, dismiss);
      //   }, 500);
      // }
    });
    isConnectedToInternet();
  }
};

export const isConnectedToInternet = () => {
  let status = true;
  if (Capacitor.getPlatform() === "web") {
    status = navigator.onLine;
  } else {
    status = Network.type != "none";
  }
  if (!status)
    showMessage(Messages.NO_INTERNET_MSG, toastPreset, toastDismiss, "primary");

  return status;
};

import { IonButton, IonImg, IonItemDivider, IonThumbnail } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import RewardDefault from "../../assets/images/global-default_new.jpeg";
import Coin from "../../assets/images/coin.svg";
import RewardLock from "../../assets/images/reward-lock.svg";
import PolygonIconGrey from "../../assets/images/icons/icon-polygon-grey.svg";
import LanguageText from "../languagetext";
import { formatStringToJSON, getFormattedUrl } from "../../utils/common.helper";
import { AppContext } from "../../context/app.context";
import LanguageAppText from "../languageapptext";

interface Props {
  name?: string;
  nameKey?: string;
  coinsRequired?: string;
  isLocked?: boolean;
  redeemedOn?: string;
  expiresOn?: string;
  redeemedCount?: number;
  isViewCard?: boolean;
  customClass?: string;
  imgPath?: any;
  onClick?(): void;
}

const RewardsCard: React.FC<Props> = ({
  name,
  nameKey,
  coinsRequired,
  isLocked,
  redeemedOn,
  expiresOn,
  isViewCard,
  redeemedCount,
  customClass,
  imgPath,
  onClick,
}) => {
  const { language }: any = useContext(AppContext);
  const imagePath: any = formatStringToJSON(imgPath);
  const [mediaPath, setMediaPath] = useState(
    imagePath instanceof Object
      ? imagePath &&
          getFormattedUrl(
            imagePath[language]
              ? imagePath[language]["uri"]
              : imagePath["en"]["uri"]
          )
      : imagePath
  );

  return (
    <div
      onClick={() => {
        onClick && onClick();
      }}
    >
      <div className={`reward-card__container  ${customClass || ""}`}>
        <div className="reward-card__img">
          <IonThumbnail slot="start" className="ion-thumbnail-reward">
            <img className="reward-img" src={mediaPath} />
          </IonThumbnail>
          {isLocked && (
            <div className="reward-lock">
              <IonImg src={RewardLock} className="reward-lock-img" />
            </div>
          )}
        </div>
        <div className="reward-card__text-container">
          {nameKey ? (
            <LanguageAppText
              tag={"div"}
              className="reward-card__title text-light-black font-medium"
              textKey={nameKey}
              value={name}
            />
          ) : (
            <LanguageText
              tag={"div"}
              className="reward-card__title text-light-black font-medium"
              value={name}
            ></LanguageText>
          )}
          {/* {isViewCard ? (
            <div className="reward-card__title text-light-black font-medium">
              {name}
            </div>
          ) : (
            <LanguageText
              tag={"div"}
              className="reward-card__title text-light-black font-medium"
              value={name}
            ></LanguageText>
          )} */}
          {isViewCard && redeemedCount ? (
            <LanguageAppText
              tag={"div"}
              className="reward-card__description text-gray"
              value={"{rewardRedeemedCount} reward redeemed"}
              textKey={"reward_redeemed_count"}
              metaData={{ rewardRedeemedCount: redeemedCount }}
            />
          ) : !coinsRequired ? (
            <div className="reward-card__description-redeemed font-medium">
              {redeemedOn ? (
                <>
                  {/* Redeemed {redeemedOn},{" "} */}
                  <LanguageAppText
                    tag={"span"}
                    className=""
                    value={"Redeemed {redeemedOn} ago, "}
                    textKey={"redeemed_reward_on"}
                    metaData={{ redeemedOn }}
                  />
                  {/* <span
                    className={`${
                      expiresOn == "tomorrow"
                        ? "description-redeemed__date"
                        : ""
                    }`}
                  >
                    Expires{" "}
                    {expiresOn == "tomorrow" ? expiresOn : "on " + expiresOn}
                  </span> */}

                  <LanguageAppText
                    tag={"span"}
                    className={`${
                      expiresOn == "tomorrow"
                        ? "description-redeemed__date"
                        : ""
                    }`}
                    value={
                      expiresOn == "tomorrow"
                        ? "Expires tomorrow"
                        : "Expires on {expiresOn}"
                    }
                    textKey={
                      expiresOn == "tomorrow"
                        ? "expires_tomorrow"
                        : "expires_on"
                    }
                    metaData={{ expiresOn }}
                  />
                </>
              ) : (
                <LanguageAppText
                  tag={"div"}
                  className=""
                  value={"Expired {rewardExpiredDuration} ago"}
                  textKey={"reward_expired_duration"}
                  metaData={{ rewardExpiredDuration: expiresOn }}
                />
              )}
            </div>
          ) : (
            <div className="reward-card__coins">
              <IonImg src={Coin} className="coin-img" />
              {/* <div className="coins-text text-orange font-medium">
                {coinsRequired} Coins
              </div> */}
              <LanguageAppText
                tag={"div"}
                className="coins-text text-orange font-medium"
                value={"{rewardCoins} Coins"}
                textKey={"reward_coins"}
                metaData={{ rewardCoins: coinsRequired }}
              />
            </div>
          )}
        </div>

        {!isLocked && onClick && (
          <div className="reward-card__arrow">
            <IonImg src={PolygonIconGrey} className="reward-arrow" />
          </div>
        )}
      </div>
    </div>
  );
};
export default RewardsCard;

import {
  createAnimation,
  IonContent,
  IonImg,
  IonLabel,
  IonList,
  IonPage,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import Loader from "../../components/common/loader";
import RegisterHeader from "../../components/common/register-header";
import LanguageAppText from "../../components/languageapptext";
import LanguageText from "../../components/languagetext";
import LeaderboardCard from "../../components/leaderboardcard";
import TopThreeProfile from "../../components/topthreeprofile";
import Messages from "../../config/messages";
import { AppContext } from "../../context/app.context";
import { getLeaderBoard } from "../../services/leaderboard.service";
import { getUserCurrentProgram } from "../../services/program.service";
import { getFormattedUrl } from "../../utils/common.helper";
import "./style.scss";

const LeaderBoard: React.FC = () => {
  const { toastMessage }: any = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser]: any = useState(null);
  const [leaderBoardData, setLeaderBoardData]: any = useState([]);
  const animationRef: any = useRef<HTMLDivElement>(null);
  const [isAnimate, setIsAnimate] = useState(false);
  const paragraphObserver: any = React.useRef(null);
  const bottomObserver: any = React.useRef(null);

  const [showFloatingView, setShowFloatingView] = useState(false);
  const [isUserInTop, setIsUserInTop] = useState(false);
  const [bottomRef, setBottomRef]: any = React.useState(null);
  const [currentProgram, setCurrentProgram]: any = useState(false);
  const history = useHistory();
  useEffect(() => {
    Promise.all([getLeaderBoardData(), fetchUserCurrentProgram()]).finally(
      () => {
        setIsLoading(false);
      }
    );
    // animate();
  }, []);

  React.useEffect(() => {
    if (bottomRef && bottomObserver) {
      bottomObserver.current.observe(bottomRef);
    }
    return () => {
      if (bottomRef && bottomObserver) {
        bottomObserver.current.unobserve(bottomRef);
      }
    };
  }, [bottomObserver, bottomRef]);

  const getLeaderBoardData = async () => {
    const response: any = await getLeaderBoard();
    if (!response.error) {
      const currentUserData = response.currentUserData;
      setCurrentUser(currentUserData);
      const filteredData =
        response.leaderboardData &&
        response.leaderboardData.map((item: any, index: number) => {
          item["isCurrentUser"] = false;
          item["rank"] = index + 1;
          if (
            currentUserData &&
            item.user &&
            item.user.id == currentUserData.id
          ) {
            item["isCurrentUser"] = true;
            console.log({ ...currentUserData, ...item });
            setCurrentUser({ ...currentUserData, ...item });
            setIsUserInTop(true);
          }
          return item;
        });
      setLeaderBoardData(filteredData);
      setObserver(currentUserData);

      // setIsLoading(false);
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
  };

  const fetchUserCurrentProgram = async () => {
    const resp: any = await getUserCurrentProgram();
    if (!resp.error) {
      if (resp.defaultProgram) {
      } else {
        setCurrentProgram(resp);
      }
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
  };
  const setObserver = (currentUserData: any) => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry: any) => {
          if (entry.target.dataset.id == currentUserData.id) {
            if (entry.isIntersecting) {
              setShowFloatingView(false);
            } else {
              setShowFloatingView(true);
            }
          }
        });
      },
      { threshold: 0.15 }
    );
    paragraphObserver.current = observer;

    const bottom = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry: any) => {
          console.log("bottom", entry.isIntersecting);
          if (entry.isIntersecting) {
            setShowFloatingView(false);
          } else {
            setShowFloatingView(true);
          }
        });
      },
      { threshold: 0.15 }
    );
    bottomObserver.current = bottom;
  };
  return (
    <div className="large-header--gradient-leaderboard large-header--gradient-blue-leaderboard">
      <IonPage className="page-leaderboard large-header abstract-img-light-blue">
        {isLoading ? (
          <Loader customClass="loader-page-center" />
        ) : (
          <>
            <RegisterHeader
              pageName="Leaderboard"
              textKey={"leaderboard"}
              backBtn={true}
            ></RegisterHeader>
            <LanguageAppText
              tag={"div"}
              className="page-leaderboard-period font-bold text-white mb-15"
              value={"Last 30 days"}
              textKey={"last_30_days"}
            />
            {leaderBoardData && (
              <div className="leaderboard-top-three-container">
                <TopThreeProfile
                  imgPath={
                    leaderBoardData[0].user.profile?.profilePic &&
                    getFormattedUrl(
                      leaderBoardData[0].user.profile.profilePic.uri
                    )
                  }
                  rank={1}
                />
                {leaderBoardData[1] && (
                  <TopThreeProfile
                    imgPath={
                      leaderBoardData[1].user.profile?.profilePic &&
                      getFormattedUrl(
                        leaderBoardData[1].user.profile.profilePic.uri
                      )
                    }
                    rank={2}
                  />
                )}
                {leaderBoardData[2] && (
                  <TopThreeProfile
                    imgPath={
                      leaderBoardData[2].user.profile?.profilePic &&
                      getFormattedUrl(
                        leaderBoardData[2].user.profile.profilePic.uri
                      )
                    }
                    rank={3}
                  />
                )}
              </div>
            )}
            <IonContent className="page-content pb-0">
              <div ref={animationRef}>
                {!isAnimate && (
                  <>
                    <div className="leaderboard-card-view bg-light-orange stretch-container pt-20">
                      <LanguageAppText
                        tag={"div"}
                        className="leaderboard-instuction text-green font-medium mb-25"
                        value={"Top 10 users who collected the most coins"}
                        textKey={"top_10_users"}
                      />
                      <div className="leaderboard-cards">
                        {leaderBoardData &&
                          leaderBoardData.map((item: any, index: any) => {
                            if (!item.user) return null;
                            return (
                              <LeaderboardCard
                                key={index}
                                observer={paragraphObserver.current}
                                rank={item.rank}
                                userName={item.user.name}
                                coinCount={item.coinCount}
                                id={item.user.id}
                                imgPath={
                                  item.user.profile?.profilePic
                                    ? getFormattedUrl(
                                        item.user.profile.profilePic.uri
                                      )
                                    : null
                                }
                                isSelfPosition={item.isCurrentUser}
                              />
                            );
                          })}
                      </div>
                    </div>

                    {currentUser && !isUserInTop && (
                      <div
                        ref={setBottomRef}
                        className="leaderboard-bottom-box bg-white stretch-container pt-20"
                      >
                        <LanguageAppText
                          tag={"div"}
                          className="leaderboard-footer-title text-green font-medium mb-10"
                          value={"Earn more coins to get into the Top 10!"}
                          textKey={"earn_more_coins"}
                        />
                        {currentProgram && (
                          <div
                            className="leaderboard-footer-desc text-gray mb-25"
                            onClick={() => {
                              history.push({
                                pathname: `/learnings/${currentProgram.program.id}`,
                              });
                            }}
                          >
                            <LanguageAppText
                              tag={"span"}
                              value={"Keep working on "}
                              textKey={"keep_working_on_program"}
                            />
                            <LanguageText
                              tag="span"
                              className="footer-desc-orange text-orange"
                              value={currentProgram.program.nodeFieldData.title}
                            />{" "}
                            <LanguageAppText
                              tag={"span"}
                              value={" to get more coins."}
                              textKey={"to_get_more_coins"}
                            />
                          </div>
                        )}
                        <LeaderboardCard
                          isSelfPosition={true}
                          hideShare={true}
                          hideRank={true}
                          isFloating={false}
                          userName={currentUser.name}
                          coinCount={currentUser.profile.earnedCoin || 0}
                          imgPath={
                            currentUser.profile?.profilePic
                              ? getFormattedUrl(
                                  currentUser.profile.profilePic.uri
                                )
                              : null
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </IonContent>
            {showFloatingView && (
              <div className="floatingView">
                <LeaderboardCard
                  isSelfPosition={true}
                  hideShare={true}
                  hideRank={!isUserInTop}
                  isFloating={true}
                  rank={currentUser.rank || 0}
                  userName={currentUser.name}
                  coinCount={
                    isUserInTop
                      ? currentUser.coinCount
                      : currentUser.profile.earnedCoin
                  }
                  imgPath={
                    currentUser.profile?.profilePic &&
                    getFormattedUrl(currentUser.profile.profilePic.uri)
                  }
                />
              </div>
            )}
          </>
        )}
      </IonPage>
    </div>
  );
};

export default LeaderBoard;

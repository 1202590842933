import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import RegisterHeader from "../../components/common/register-header";
import LanguageAppText from "../../components/languageapptext";
import { TNC_LINK } from "../../config/appconfig";

import "./style.scss";

const TOCDetails: React.FC = () => {
  const history = useHistory();
  return (
    <IonPage className="page-t-and-c">
      <RegisterHeader
        pageName="Terms & conditions"
        textKey={"tnc_amp"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen={true} className="page-content">
        <div>
          <LanguageAppText
            tag={"p"}
            className="page-content__infotext"
            value={
              "These explain important information, including but not limited to:"
            }
            textKey={"tnc_important_information"}
          />
          <ul className="m0">
            <LanguageAppText
              tag={"li"}
              className="page-content__infotext p0"
              value={"How we collect information about you"}
              textKey={"tnc_collect_information"}
            />
            <LanguageAppText
              tag={"li"}
              className="page-content__infotext"
              value={"How we share with others"}
              textKey={"tnc_share_information"}
            />
          </ul>
          <p className="page-content__infotext p0">
            <div
              className="page-content__link"
              onClick={() => {
                history.push("/terms-of-use");
              }}
            >
              <LanguageAppText
                tag={"span"}
                value={"Read the terms & conditions"}
                textKey={"tnc_read"}
              />
            </div>
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TOCDetails;

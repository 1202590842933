import { IonCol, IonImg, IonRow, IonSpinner } from "@ionic/react";
import React, { useState } from "react";
import "./style.scss";
import Certificate from "../../assets/images/certificate.png";
import { sliceIntoChunks } from "../../utils/common.helper";
interface Props {
  data: any;
  loading?: boolean;
  onClick?: (data: any) => void;
}
const CertificateView: React.FC<Props> = ({ data, loading, onClick }) => {
  const certificateData = sliceIntoChunks(data, 3);
  const [currentIndex, setCurrentIndex]: any = useState(null);

  const onItemClick = (item: any, index: number) => {
    if (onClick) {
      setCurrentIndex(item);
      onClick(item);
    } else {
      console.log("no click");
    }
  };
  return (
    <div>
      {certificateData.map((itemArray: any, index: number) => {
        return (
          <div
            key={index}
            className={`certificates__shelf ${
              loading && itemArray.length === 1 ? "no-certificate" : ""
            }`}
          >
            <IonRow className="certificate__wrapper">
              {itemArray.map((item: any, index: number) => {
                return (
                  <IonCol key={index}>
                    {loading && currentIndex && currentIndex === item ? (
                      <IonSpinner
                        color="primary"
                        className="certificate-loader"
                      />
                    ) : (
                      <div
                        onClick={() => {
                          onItemClick(item, index);
                        }}
                      >
                        <IonImg
                          src={Certificate}
                          className="certificate__shelf-image"
                        />
                      </div>
                    )}
                  </IonCol>
                );
              })}
            </IonRow>
          </div>
        );
      })}
    </div>
  );
};
export default CertificateView;

import "./style.scss";

import {
  IonButton,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRadioGroup,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";

import RegisterHeader from "../../components/common/register-header";
import ProfileDetails from "../../components/profiledetails";
import {
  getUserCertificateById,
  getUserCertificates,
  profileSummary,
  removePicture,
  saveProfile,
  userBadgesData,
  userDataCounts,
  userRecentActivities,
} from "../../services/user.service";
import { uploadFile } from "../../services/filetransfer.service";
import { AppContext } from "../../context/app.context";
import { USER_ACTIVITY_ICONS } from "../../config/appconfig";
import PolygonIcon from "../../assets/images/icons/icon-polygon.svg";
import { useHistory } from "react-router";
import moment from "moment";
import {
  formatDateToSingleChar,
  getActivityMetaData,
  getFormattedUrl,
  stringInject,
} from "../../utils/common.helper";
import CertificateView from "../../components/certificateview";
import Popup from "../../components/common/popup";
import Certificate from "../../components/certificate";
import observerService from "../../services/observer.service";
import { Capacitor } from "@capacitor/core";
import { chooseFromGallery, imageCapture } from "../../utils/media.helper";
import BottomDrawer from "../../components/bottomdrawer";
import BackDrop from "../../components/common/backdrop";
import LanguageText from "../../components/languagetext";
import Loader from "../../components/common/loader";
import LanguageAppText from "../../components/languageapptext";
import Messages from "../../config/messages";
import ButtonComponent from "../../components/common/button-component";
import DefaultActivityIcon from "../../assets/images/emoji-events.svg";

let isBackDrop = false,
  isShowRemove = false;
const Profile: React.FC = () => {
  const PICKER_TYPE = "picker";
  const history: any = useHistory();
  const { toastMessage, language, setUserDetails }: any =
    useContext(AppContext);
  const [userDetails, setUserDetailsData]: any = useState(null);
  const [userBadges, setUserBadges]: any = useState(null);
  const [userActivities, setUserActivities]: any = useState(null);
  const [userDataCount, setUserDataCount]: any = useState(null);
  const [userCertificates, setUserCertificates]: any = useState(null);
  const [certificateImage, setCertificateImage]: any = useState("");
  const [certificateImageLoading, setCertificateImageLoading]: any =
    useState(false);
  const [isBackDropOpen, setIsBackDropOpen] = useState(false);
  const [isBackDropVisible, setIsBackDropVisible] = useState(false);
  const [hideProfileDetails, setHideProfileDetails] = useState(false);
  const [profileImg, setProfileImg]: any = useState(null);
  const [loading, setLoading]: any = useState(true);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  useEffect(() => {
    Promise.all([
      fetchProfileDetails(),
      fetchUserBadges(),
      fetchUserActivities(),
      fetchUserDataCount(),
      fetchUserCertificate(),
    ]).finally(() => {
      setLoading(false);
    });

    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
      isBackDrop = false;
      isShowRemove = false;
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(101, () => {
      if (isBackDrop) {
        isBackDrop = false;
        observerService.handleEvents(PICKER_TYPE, { state: "close" });
      } else if (isShowRemove) {
        isShowRemove = false;
        setShowRemoveModal(false);
      } else {
        history.goBack();
      }
    });
  };

  const fetchProfileDetails = async () => {
    const userResponse: any = await profileSummary();
    if (!userResponse.error) {
      if (userResponse.profilePic) {
        setProfileImg(getFormattedUrl(userResponse.profilePic.uri));
      }
      setUserDetailsData(userResponse);
      setUserDetails(userResponse);
    } else {
      toastMessage(userResponse.message, "primary");
    }
  };

  const fetchUserBadges = async () => {
    const badgesResponse: any = await userBadgesData();
    console.log(JSON.stringify(badgesResponse));
    if (!badgesResponse.error) {
      setUserBadges(badgesResponse);
    } else {
      toastMessage(badgesResponse.message, "primary");
    }
  };

  const fetchUserActivities = async () => {
    const activitiesResponse: any = await userRecentActivities();
    if (!activitiesResponse.error) {
      setUserActivities(activitiesResponse);
    } else {
      toastMessage(activitiesResponse.message, "primary");
    }
  };

  const fetchUserDataCount = async () => {
    const response: any = await userDataCounts();
    if (!response.error) {
      setUserDataCount(response);
    }
  };

  const fetchUserCertificate = async () => {
    const response: any = await getUserCertificates();
    if (!response.error) {
      setUserCertificates(response);
    }
  };

  const handleUpdateProfilePic = async (data: any) => {
    const thumbRes: any = await uploadFile(
      data.uri || data.path,
      data.mediaType || data.type,
      data.name || ""
    );
    if (!thumbRes.error) {
      const postData = {
        profilePicFileId: JSON.parse(thumbRes).id,
      };
      const response: any = await saveProfile(postData);
      if (response.error) {
        toastMessage(response.message, "primary");
      } else {
        setUserDetails(response);
      }
    } else {
      toastMessage(thumbRes.message, "primary");
    }
  };

  const handlePageRedirect = (route: string) => {
    history.push(route);
  };

  const getCertificateById = async (id: number) => {
    setCertificateImageLoading(true);
    const certificateImage: any = await getUserCertificateById(id);
    if (!certificateImage.error) {
      setCertificateImage(certificateImage);
    }
    setCertificateImageLoading(false);
  };

  const handleBackDrop = (state: boolean) => {
    setIsBackDropVisible(state);
    isBackDrop = state;
  };

  const handlePickerSelect = async (picker: string) => {
    let imageData: any = null;
    if (isBackDropOpen) {
      setIsBackDropOpen(false);
      isBackDrop = false;
      observerService.handleEvents(PICKER_TYPE, { state: "close" });
    }
    setTimeout(async () => {
      if (picker === "camera") {
        imageData = await imageCapture();
        if (imageData) {
          setProfileImg(Capacitor.convertFileSrc(imageData.path));
          handleUpdateProfilePic(imageData);
        }
      } else if (picker === "gallery") {
        imageData = await chooseFromGallery("image/*");
        if (imageData) {
          setProfileImg(imageData.dataURI);
          handleUpdateProfilePic(imageData);
        }
      } else {
        isShowRemove = true;
        setShowRemoveModal(true);
      }
    }, 250);
  };

  const handlePickerClick = () => {
    setIsBackDropOpen(true);
    isBackDrop = true;
    observerService.handleEvents(PICKER_TYPE, { state: "open" });
  };

  const onRemovePicture = async () => {
    const resp: any = await removePicture({});
    if (!resp.error) {
      // isShowRemove = false;
      // setShowRemoveModal(false);
      setProfileImg(null);
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
  };

  const renderPicker = () => {
    return (
      <IonList className="ion-list">
        <IonRadioGroup className="ion-radio-group">
          {profileImg && (
            <IonItem
              onClick={() => {
                handlePickerSelect("remove");
              }}
            >
              <IonLabel>Remove picture</IonLabel>
            </IonItem>
          )}
          <IonItem
            onClick={() => {
              handlePickerSelect("camera");
            }}
          >
            <IonLabel>Take a picture</IonLabel>
          </IonItem>
          <IonItem
            onClick={() => {
              handlePickerSelect("gallery");
            }}
          >
            <IonLabel>Choose from gallery</IonLabel>
          </IonItem>
        </IonRadioGroup>
      </IonList>
    );
  };

  const renderBadges = () => {
    return userBadges.badges.map((item: any, index: number) => {
      // const icon_path =
      //   USER_BADGE_ICONS[item.badgeName.toLowerCase().replace(/ /g, "_")];
      return (
        <IonList key={index} className="badge-list">
          <IonImg
            src={item.image ? getFormattedUrl(item.image.uri) : ""}
            className="profile__badge-image"
          />
          <IonLabel
            position="stacked"
            className="profile__badge-label text-gray"
          >
            {item.badgeName}
          </IonLabel>
        </IonList>
      );
    });
  };

  const renderActivities = () => {
    return userActivities.map((item: any, index: number) => {
      if (item.activityType === "") return;
      const type = JSON.parse(item.activityType);
      const icon_path =
        USER_ACTIVITY_ICONS[type["en"].replace(" ", "_").toLowerCase()];
      const descData = JSON.parse(item.activityDescription);
      let desc = descData[language] || descData["en"];
      // if (
      //   type["en"] == "BADGE EARNED" &&
      //   item.activityMetaData &&
      //   item.activityMetaData.badgeTitle
      // ) {
      //   const badgeTitleData = JSON.parse(item.activityMetaData.badgeTitle);
      //   const badgeTitle = badgeTitleData[language] || badgeTitleData["en"];
      //   desc = stringInject(desc, { badgeTitle });
      // }
      if (item.activityMetaData) {
        const formattedData = getActivityMetaData(
          item.activityMetaData,
          desc,
          language
        );
        desc = stringInject(desc, formattedData);
      }
      return (
        <div key={item.id} className="page-content__activity ion-margin-bottom">
          <IonImg
            src={icon_path || DefaultActivityIcon}
            className="activity__image"
          />
          <div className="activity__details">
            <LanguageText
              tag={"div"}
              className="activity__name"
              value={item.activityType}
            ></LanguageText>

            {/* <LanguageText
              tag={"div"}
              className="activity__inner-text"
              value={item.activityDescription}
            ></LanguageText> */}
            <div className="activity__inner-text">{desc}</div>
          </div>
          <div className="activity__time">
            {formatDateToSingleChar(
              moment(item.createdAt).fromNow(),
              item.createdAt
            )}
          </div>
        </div>
      );
    });
  };

  const handleOnScroll = async (event: any) => {
    const scrollElement = await event.target.getScrollElement();
    console.log(scrollElement.scrollHeight, scrollElement.clientHeight);
    const offsetY = event.detail.currentY;
    if (scrollElement.scrollHeight > 400 && offsetY < 20) {
      setHideProfileDetails(false);
    } else if (offsetY > 20) {
      setHideProfileDetails(true);
    }
  };

  return (
    <>
      <IonPage className="page-profile">
        {loading ? (
          <Loader customClass="loader-page-center" />
        ) : (
          <>
            <div
              className={`page-profile__header ${
                hideProfileDetails ? "hide-page-details" : ""
              }`}
            >
              <RegisterHeader
                pageName="Profile"
                textKey={"profile"}
                backBtn={true}
              ></RegisterHeader>
              {/* <IonButton className="ion-button__setting-btn icon__setting ion-button__transparent-with-icon"></IonButton> */}
              <ProfileDetails
                onEditPick={handlePickerClick}
                data={userDetails}
                profileImg={profileImg}
              ></ProfileDetails>
            </div>

            <IonContent
              className={`page-content pt-0 ${
                hideProfileDetails ? "pb-20 pt-50" : ""
              }`}
              scrollEvents={true}
              onIonScroll={handleOnScroll}
            >
              <div className="page-content-top-container bg-light-orange stretch-container">
                <div className="page-profile__all">
                  <LanguageAppText
                    tag={"div"}
                    className="page-content__title"
                    value={"Badges"}
                    textKey={"badges"}
                  />
                  {userBadges && userBadges.badges.length > 0 && (
                    <IonButton
                      className="ion-button__all page-content__title ion-button__transparent-with-icon"
                      onClick={() => {
                        handlePageRedirect("/yourbadges");
                      }}
                    >
                      <LanguageAppText
                        tag={"span"}
                        value={"See All"}
                        textKey={"see_all"}
                      />
                      <span className="ion-button__all-icon">
                        <IonImg src={PolygonIcon} />
                      </span>
                    </IonButton>
                  )}
                </div>
                {userBadges && userBadges.badges.length > 0 ? (
                  <LanguageAppText
                    tag={"p"}
                    className="page-profile__instructions text-gray ion-no-margin"
                    value={"Here are your most recently earned badges"}
                    textKey={"recently_earned_badge"}
                  />
                ) : (
                  <LanguageAppText
                    tag={"p"}
                    className="page-profile__instructions text-gray"
                    value={"Earn your first badge by starting a program"}
                    textKey={"earn_first_badge"}
                  />
                )}

                {userBadges && (
                  <div
                    className={`page-profile__badge-list ion-no-padding ${
                      userBadges.badges.length < 3
                        ? "badge-list-less-than-three"
                        : ""
                    }`}
                  >
                    {renderBadges()}
                    {userBadges.badges.length > 0 &&
                      userBadges.remainingBadgesCount > 0 && (
                        <IonList
                          className="badge-list mb-top"
                          onClick={() => {
                            handlePageRedirect("/yourbadges");
                          }}
                        >
                          <IonButton className="profile__badge-more ion-button__transparent-with-icon ion-no-margin">
                            +{userBadges.remainingBadgesCount}
                          </IonButton>
                        </IonList>
                      )}
                  </div>
                )}
                {userActivities && userActivities.length > 0 && (
                  <>
                    <LanguageAppText
                      tag={"div"}
                      className="page-content__title"
                      value={"Activity"}
                      textKey={"activity"}
                    />
                    <LanguageAppText
                      tag={"p"}
                      className="page-profile__instructions text-gray ion-no-margin"
                      value={"Here’s what you’ve been up to recently"}
                      textKey={"recent_activity"}
                    />
                    <div className="page-profile__activities bg-white ion-padding ion-margin-top ion-margin-bottom">
                      {renderActivities()}
                    </div>
                  </>
                )}
              </div>
              <div className="page-content__certificates stretch-container">
                <LanguageAppText
                  tag={"div"}
                  className="page-content__title"
                  value={"Certificates"}
                  textKey={"certificates"}
                />
                {userCertificates && userCertificates.length > 0 ? (
                  <CertificateView
                    data={userCertificates}
                    onClick={(item: any) => {
                      getCertificateById(item);
                    }}
                    loading={certificateImageLoading}
                  />
                ) : (
                  <div className="certificates__shelf no-certificate">
                    <LanguageAppText
                      tag={"p"}
                      className="certificates__shelf-content text-black ion-no-margin"
                      value={"Complete your first program to earn certificates"}
                      textKey={"certificate_complete_program"}
                    />
                  </div>
                )}

                <div className="certificates__quick-links">
                  <LanguageAppText
                    tag={"div"}
                    className="quick-links__title text-green"
                    value={"Quick links"}
                    textKey={"quick_links"}
                  />
                  <div className="quick-links__list">
                    {userDataCount && userDataCount.rewards > 0 && (
                      <div
                        className="quick-link ion-no-padding"
                        onClick={() => {
                          handlePageRedirect("/redeemedreward");
                        }}
                      >
                        <div className="quick__link-image rewards"></div>
                        <LanguageAppText
                          tag={"div"}
                          className="quick__link-label text-light-black"
                          value={"My Rewards"}
                          textKey={"my_rewards"}
                        />
                      </div>
                    )}
                    {userDataCount && userDataCount.stories > 0 && (
                      <div
                        className="quick-link ion-no-padding"
                        onClick={() => {
                          handlePageRedirect("/mystories");
                        }}
                      >
                        <div className="quick__link-image stories"></div>
                        <LanguageAppText
                          tag={"div"}
                          className="quick__link-label text-light-black"
                          value={"My Stories"}
                          textKey={"my_stories"}
                        />
                      </div>
                    )}
                    <div
                      className="quick-link ion-no-padding"
                      onClick={() => {
                        handlePageRedirect("/todo");
                      }}
                    >
                      <div className="quick__link-image to-do"></div>
                      <LanguageAppText
                        tag={"div"}
                        className="quick__link-label text-light-black"
                        value={"My Tasks"}
                        textKey={"my_tasks"}
                      />
                    </div>
                    <div
                      className="quick-link ion-no-padding"
                      onClick={() => {
                        handlePageRedirect("/helpcenter");
                      }}
                    >
                      <div className="quick__link-image help-center"></div>
                      <LanguageAppText
                        tag={"div"}
                        className="quick__link-label text-light-black"
                        value={"Help Centre"}
                        textKey={"help_centre"}
                      />
                    </div>
                    <div
                      className="quick-link ion-no-padding"
                      onClick={() => {
                        handlePageRedirect("/settings");
                      }}
                    >
                      <div className="quick__link-image settings"></div>
                      <LanguageAppText
                        tag={"div"}
                        className="quick__link-label text-light-black"
                        value={"Settings"}
                        textKey={"settings"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {certificateImage && certificateImage != "" && (
                <Popup
                  customClass="certificate-popup"
                  onClose={() => {
                    setCertificateImage("");
                  }}
                >
                  <div>
                    <Certificate
                      onBackClick={() => {
                        setCertificateImage("");
                      }}
                      imgPath={certificateImage}
                    />
                  </div>
                </Popup>
              )}
            </IonContent>
            <BackDrop
              backdropVisible={isBackDropVisible}
              isOutsideClick={true}
              onOutClick={() => {
                isBackDrop = false;
              }}
            ></BackDrop>
          </>
        )}
      </IonPage>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          zIndex: 101,
          width: "100%",
        }}
      >
        <BottomDrawer type={PICKER_TYPE} hideBackDrop={handleBackDrop}>
          {renderPicker()}
        </BottomDrawer>
      </div>
      {showRemoveModal && (
        <Popup
          customClass="delete-comment-popup"
          onClose={() => {
            setShowRemoveModal(false);
            isShowRemove = false;
          }}
        >
          <div className="delete-popup-container">
            <LanguageAppText
              tag="div"
              className="delete-popup-text text-light-black font-medium mb-20"
              textKey={"delete_profile_picture"}
              value="Are you sure that you want to remove profile picture?"
            />

            <div className="delete-popup-button-wrapper">
              <ButtonComponent
                name="Yes"
                textKey={"yes"}
                onClick={() => {
                  onRemovePicture();
                  setShowRemoveModal(false);
                  isShowRemove = false;
                }}
                customClass="delete-popup-button mr-10"
              ></ButtonComponent>
              <ButtonComponent
                name="No"
                textKey={"no"}
                outline
                onClick={() => {
                  setShowRemoveModal(false);
                  isShowRemove = false;
                }}
                customClass="delete-popup-button ml-10"
              ></ButtonComponent>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default Profile;

import "./style.scss";

import { IonButton, IonContent, IonImg, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import RegisterHeader from "../../components/common/register-header";
import ButtonComponent from "../../components/common/button-component";
import StorySuccess from "../../assets/images/icons/submit-story-success-icon.svg";
import StoryPeopleIcon from "../../assets/images/icons/submit-story-people-icon.svg";
import { CaptureVideoOptions } from "@awesome-cordova-plugins/media-capture";
import { chooseFromGallery, videoRecorder } from "../../utils/media.helper";
import Loader from "../../components/common/loader";
import VideoPlayer from "../../components/videoplayer";
import { Capacitor } from "@capacitor/core";
import Back from "../../assets/images/icons/back.svg";
import RecorderVideo from "../recordedvideo";
import { uploadFile } from "../../services/filetransfer.service";
import { submitTempStory } from "../../services/community.service";
import Messages from "../../config/messages";
import { AppContext } from "../../context/app.context";
import { useHistory } from "react-router";
import {
  checkPermission,
  requestPermission,
} from "../../utils/permission.helper";
import { AndroidPermissions } from "@ionic-native/android-permissions";
import LanguageAppText from "../../components/languageapptext";
import { getClassName } from "@ionic/react/dist/types/components/utils";
import { getDeviceInfo, isOSBelowOREqual } from "../../utils/common.helper";
interface Props {
  onClose?: () => void;
}
const SubmitStory: React.FC<Props> = ({ onClose }) => {
  const { toastMessage, language }: any = useContext(AppContext);
  const history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUri, setFileUri] = useState("");
  const [thumbUri, setThumbUri] = useState("");
  const hasPermission = async () => {
    let hasPermission = false;
    if (await isOSBelowOREqual(12)) {
      hasPermission = await checkPermission(
        AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
      );

      if (!hasPermission) {
        hasPermission = await requestPermission(
          AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
        );
      }
    } else {
      hasPermission = true;
    }

    return hasPermission;
  };
  const onRecordVideo = async () => {
    const isAllowed = await hasPermission();
    if (isAllowed) {
      setLoading(true);
      let options: CaptureVideoOptions = { duration: 600 };
      const videoData = await videoRecorder(options);
      console.log("video-----", videoData);
      if (videoData.compressed) {
        setFileUri(videoData.compressed);
      }
      if (videoData.thumbnail) {
        setThumbUri(videoData.thumbnail);
      }
      setLoading(false);
    }
  };

  const onChooseVideo = async () => {
    const isAllowed = await hasPermission();
    if (isAllowed) {
      setLoading(true);
      const videoData = await chooseFromGallery("video/mp4", true);
      console.log("video-----", videoData);
      if (videoData) {
        if (videoData.compressed) {
          setFileUri(videoData.compressed);
        }
        if (videoData.thumbnail) {
          setThumbUri(videoData.thumbnail);
        }
      }
      setLoading(false);
    }
  };

  const redoPost = () => {
    setFileUri("");
    setThumbUri("");
  };

  const onPost = async () => {
    setLoading(true);
    if (fileUri != "" && thumbUri != "") {
      const fileResp: any = await uploadFile(
        "file://" + fileUri,
        "video/mp4",
        ""
      );

      const thumbResp: any = await uploadFile(
        "file://" + thumbUri,
        "image/jpeg",
        ""
      );

      if (!fileResp.error && !thumbResp.error) {
        const storyObj = {
          title: "",
          videoId: JSON.parse(fileResp).id,
          thumnailId: JSON.parse(thumbResp).id,
        };
        const storyRes: any = await submitTempStory(storyObj);
        if (!storyRes.error) {
          setFileUri("");
          setThumbUri("");
          setShowSuccess(true);
          // isVideo = false;
          // toastMessage(Messages.STORY_SUBMIT, "success");
        } else {
          // isVideo = false;
          toastMessage(Messages.SERVER_ERROR, "primary");
        }
      } else {
        // isVideo = false;
        toastMessage(Messages.FILE_UPLOAD_ERROR, "primary");
      }
    }
    setLoading(false);
  };
  return (
    <>
      <IonPage className="page-submit-story">
        {fileUri == "" && (
          <RegisterHeader
            pageName="Submit a story"
            textKey={"submit_a_story"}
            backBtn={true}
          ></RegisterHeader>
        )}
        <IonContent fullscreen={true} className="page-content">
          {loading ? (
            <Loader customClass="loader-page-center" />
          ) : showSuccess ? (
            <div className="story-success__container">
              <div className="story-success__text">
                <IonImg
                  src={StorySuccess}
                  className="story-success__icon mb-15"
                />
                <LanguageAppText
                  tag={"div"}
                  className="story-success__title text-deep-black font-bold mb-10"
                  value={"Thanks!"}
                  textKey={"thanks"}
                />
                <LanguageAppText
                  tag={"div"}
                  className="story-success__submit text-light-black font-bold mb-15"
                  value={"Your story has been submitted."}
                  textKey={"story_submitted"}
                />
                <LanguageAppText
                  tag={"div"}
                  className="story-success__description text-light-black font-medium"
                  value={
                    "We will review your story and notify you when it has been uploaded!"
                  }
                  textKey={"review_story"}
                />
              </div>
              <ButtonComponent
                name="Done"
                textKey={"done"}
                customClass="story-success__btn"
                onClick={() => {
                  if (onClose) {
                    onClose();
                  } else {
                    history.goBack();
                  }
                }}
              ></ButtonComponent>
            </div>
          ) : fileUri == "" && !loading ? (
            <div className="submit-story__container">
              <div className="story-success__container">
                <div className="story-success__text">
                  <IonImg
                    src={StoryPeopleIcon}
                    className="story-people__icon mb-15"
                  />
                  <LanguageAppText
                    tag={"div"}
                    className="story-success__title text-deep-black font-bold mb-10"
                    value={"Tell your story!"}
                    textKey={"tell_your_story"}
                  />
                  <LanguageAppText
                    tag={"div"}
                    className="story-success__submit text-light-black font-bold mb-10"
                    value={
                      "What is one trick you have to run your business easily?"
                    }
                    textKey={"run_business_easily"}
                  />
                  <LanguageAppText
                    tag={"div"}
                    className="story-success__description text-light-black font-medium mb-10"
                    value={
                      "Record a video of upto 10 minutes on your phone! Upload the video and have it seen by our whole community."
                    }
                    textKey={"record_story_video"}
                  />
                  <LanguageAppText
                    tag={"div"}
                    className="story-success__description text-light-black font-medium"
                    value={`Please make sure to adhere to our <span class="success__description-orange text-orange">community guidelines</span>`}
                    textKey={"adhere_community_guidelines"}
                  />
                  {/* <div className="story-success__description text-light-black font-medium">
                    Please make sure to adhere to our{" "}
                    <span className="success__description-orange text-orange">
                      community guidelines
                    </span>
                  </div> */}
                </div>
                <div className="submit-story__btn-container">
                  <ButtonComponent
                    name="Upload Video"
                    textKey={"upload_video"}
                    outline
                    customClass="story-upload__btn mr-5"
                    onClick={onChooseVideo}
                  ></ButtonComponent>
                  <ButtonComponent
                    name="Record Video"
                    textKey={"record_video"}
                    customClass="story-record__btn ml-5"
                    onClick={onRecordVideo}
                  ></ButtonComponent>
                </div>
              </div>
            </div>
          ) : fileUri != "" ? (
            <RecorderVideo
              screenType="submitStory"
              url={Capacitor.convertFileSrc("file://" + fileUri)}
              thumbnail={Capacitor.convertFileSrc("file://" + thumbUri)}
              onRedo={redoPost}
              onPost={onPost}
            />
          ) : null}
        </IonContent>
      </IonPage>
    </>
  );
};

export default SubmitStory;

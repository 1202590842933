import {
  IonIcon,
  IonPage,
  IonRippleEffect,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { Route } from "react-router";
import LanguageAppText from "../../components/languageapptext";
import { AppContext } from "../../context/app.context";
import Experts from "./experts";
import Home from "./home";
import Stories from "./stories";
import "./style.scss";
import Tools from "./tools";
import SettingIcon from "../../assets/images/quick-link-settings.svg";
import observerService from "../../services/observer.service";
const Tabs: React.FC = () => {
  const { homeConfig }: any = useContext(AppContext);

  const [storiesTab, setstoriesTab] = useState(
    window.location.pathname == "/tabs/stories"
  );

  const tabsClick = (e: { detail: { tab: string } }) => {
    if (e.detail.tab === "stories") {
      setstoriesTab(true);
    } else if (e.detail.tab == "language") {
      observerService.handleEvents("change-lang");
    } else {
      setstoriesTab(false);
    }
  };

  return (
    <IonPage>
      <IonTabs>
        <IonRouterOutlet>
          <Route path="/tabs/:tab(experts)">
            <Experts />
          </Route>
          <Route path="/tabs/:tab(tools)">
            <Tools />
          </Route>
          {/* <Route path="/tabs/:tab(home)">
            <Home />
          </Route> */}
          <Route path="/tabs/:tab(stories)">
            <Stories />
          </Route>
          {/* <Route path="/tabs/:tab(rewards)">
            <Rewards />
          </Route> */}
        </IonRouterOutlet>
        <IonTabBar
          slot="bottom"
          className={`bottom-nav ${storiesTab ? "stories" : "experts"}`}
          onIonTabsWillChange={tabsClick}
        >
          <IonTabButton
            className="ion-tab__btn experts"
            tab="experts"
            href="/tabs/experts"
          >
            <IonRippleEffect type="unbounded"></IonRippleEffect>
            <LanguageAppText
              tag={"span"}
              className="ion-btn__label"
              value={"Programs"}
              textKey={"programs"}
            />
          </IonTabButton>
          {homeConfig && homeConfig.showStory ? (
            <IonTabButton
              className="ion-tab__btn stories"
              tab="stories"
              href="/tabs/stories"
            >
              <IonRippleEffect type="unbounded"></IonRippleEffect>
              <LanguageAppText
                tag={"span"}
                className="ion-btn__label"
                value={"Stories"}
                textKey={"stories"}
              />
            </IonTabButton>
          ) : null}
          {homeConfig && homeConfig.showTools ? (
            <IonTabButton
              className="ion-tab__btn tools"
              tab="tools"
              href="/tabs/tools"
            >
              <IonRippleEffect type="unbounded"></IonRippleEffect>
              <LanguageAppText
                tag={"span"}
                className="ion-btn__label"
                value={"Tools"}
                textKey={"tools"}
              />
            </IonTabButton>
          ) : null}

          <IonTabButton className="ion-tab__btn setting" tab="language">
            <IonIcon src={SettingIcon} className="setting-icon" />
            <IonRippleEffect type="unbounded"></IonRippleEffect>
            <LanguageAppText
              tag={"span"}
              className="ion-btn__label"
              value={"Language"}
              textKey={"language"}
            />
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonPage>
  );
};

export default Tabs;

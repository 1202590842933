import { IonButton, IonContent, IonImg, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";

import VideoPlayer from "../../components/videoplayer";

import { getFormattedUrl } from "../../utils/common.helper";
import BackButton from "../../components/common/button-component/back";
import "./style.scss";

const ReviewStory: React.FC = () => {
  const { state }: any = useLocation();
  const storyDetails: any = state.storyDetails;

  return (
    <>
      <IonPage className="page-community-story page-review-story">
        <IonContent fullscreen={true} className="page-content">
          <div className="community-story__container">
            <div className="community-story__video-container stretch-container mb-40">
              <BackButton />
              <VideoPlayer
                url={getFormattedUrl(storyDetails.video.uri)}
                thumbnail={getFormattedUrl(storyDetails.thumbnail.uri)}
                isLiked={storyDetails.isLiked}
                isFullScreen={true}
                hideFullScreen={true}
              />
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default ReviewStory;

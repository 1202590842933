import { ScreenOrientation } from "@ionic-native/screen-orientation";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory } from "react-router";
import Loader from "../common/loader";
import LanguageAppText from "../languageapptext";
import Duration from "./duration";
import "./style.scss";

interface Props {
  isPlaying?: boolean;
  url?: string;
  onVideoEnd?: () => void;
  isFullScreen?: boolean;
  onFullScreenChange?: (isFullScreen: boolean) => void;
  hideControl?: boolean;
  showBottomBack?: boolean;
  thumbnail?: string;
  hideFullScreen?: boolean;
  hideProgress?: boolean;
  likeCount?: number;
  isLiked?: boolean;
  hideLike?: boolean;
  onVideoPlay?: () => void;
  disableOrientation?: boolean;
}
let landscape = false;
const VideoPlayer = (props: Props) => {
  const {
    isPlaying,
    url,
    onVideoEnd,
    onFullScreenChange,
    hideControl,
    showBottomBack,
    thumbnail,
    hideFullScreen,
    hideProgress,
    likeCount,
    isLiked,
    hideLike,
    onVideoPlay,
    disableOrientation,
  } = props;
  let player: any;
  const [isPlayerLoad, setIsPlayerLoad] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [played, setPlayed] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(props.isFullScreen);
  const history: any = useHistory();
  const [muted, setMuted] = useState(true);
  // const [isLandScape, setIsLandScape] = useState(
  //   !props.isFullScreen &&
  //     (ScreenOrientation.type === ScreenOrientation.ORIENTATIONS.LANDSCAPE ||
  //       ScreenOrientation.type ===
  //         ScreenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY ||
  //       ScreenOrientation.type ===
  //         ScreenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY)
  // );
  const [isLandScape, setIsLandScape] = useState(false);
  const [buffering, setBuffering] = useState(false);
  // useEffect(() => {
  //   if (!props.isFullScreen) {
  //     ScreenOrientation.unlock();
  //     ScreenOrientation.onChange().subscribe(handleOrientation);
  //   }

  //   return () => {
  //     ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (disableOrientation) {
  //     ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
  //   } else {
  //     if (!props.isFullScreen) {
  //       ScreenOrientation.unlock();
  //       // ScreenOrientation.onChange().subscribe(handleOrientation);
  //     }
  //   }
  // }, [disableOrientation]);
  // const handleBack = (ev: any) => {
  //   ev.detail.register(101, () => {
  //     if (landscape) {
  //       ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
  //       setTimeout(() => {
  //         ScreenOrientation.unlock();
  //       }, 3000);
  //     }
  //   });
  // };

  // const handleOrientation = () => {
  //   if (!props.isFullScreen) {
  //     if (
  //       ScreenOrientation.type === ScreenOrientation.ORIENTATIONS.LANDSCAPE ||
  //       ScreenOrientation.type ===
  //         ScreenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY ||
  //       ScreenOrientation.type ===
  //         ScreenOrientation.ORIENTATIONS.LANDSCAPE_SECONDARY
  //     ) {
  //       document.addEventListener("ionBackButton", handleBack);
  //       landscape = true;
  //       setIsLandScape(true);
  //       setIsFullScreen(true);
  //       if (onFullScreenChange) {
  //         onFullScreenChange(true);
  //       }
  //     } else {
  //       document.removeEventListener("ionBackButton", handleBack);
  //       landscape = false;
  //       setIsLandScape(false);
  //       setIsFullScreen(false);
  //       if (onFullScreenChange) {
  //         onFullScreenChange(false);
  //       }
  //     }
  //   }
  // };
  useEffect(() => {
    setIsFullScreen(props.isFullScreen);
  }, [props.isFullScreen]);

  const onFullScreen = () => {
    if (landscape) {
      // ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
      // setTimeout(() => {
      //   ScreenOrientation.unlock();
      // }, 3000);
    } else {
      setIsFullScreen(!isFullScreen);
      if (onFullScreenChange) {
        onFullScreenChange(!isFullScreen);
      }
    }
  };

  const ref = (playerRef: any) => {
    player = playerRef;
  };

  const togglePlayPause = () => {
    if (!isPlayerLoad) {
      setIsPlayerLoad(true);
    }
    setPlaying(!playing);
    onVideoPlay && onVideoPlay();
  };

  const toggleMute = () => {
    setMuted(!muted);
  };

  const handleProgress = (event: any) => {
    if (!seeking) {
      setPlayed(event.played);
      setLoaded(event.loaded);
    }
  };

  const handleDuration = (duration: any) => {
    setDuration(duration);
  };

  const handleEnded = () => {
    setTimeout(() => {
      setPlaying(false);
      setPlayed(0);
      onVideoEnd && onVideoEnd();
    }, 1000);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = (e: any) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e: any) => {
    setSeeking(false);
    player.seekTo(parseFloat(e.target.value));
    setPlaying(true);
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        height: isFullScreen
          ? isLandScape
            ? "auto"
            : window.innerHeight - 15
          : 232,
      }}
    >
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
          height: isFullScreen
            ? isLandScape
              ? window.innerHeight - 15
              : "100%"
            : 232,
        }}
        className="fullscreen"
      >
        <div
          style={{
            height: isFullScreen
              ? isLandScape
                ? window.innerHeight - 15
                : "100%"
              : 232,
          }}
          className="player-wrapper"
          onClick={togglePlayPause}
        >
          <ReactPlayer
            ref={ref}
            config={{
              vimeo: {
                playerOptions: {
                  fullscreen: false,
                  cc: false,
                  pip: false,
                },
              },
            }}
            playing={playing}
            className="react-player"
            url={url}
            muted={muted}
            volume={1}
            light={thumbnail || false}
            width={"100%"}
            height={"100%"}
            controls={hideControl}
            onError={(error: any, data: any) => {
              console.log("error", error);
              console.log("data", data);
            }}
            onBuffer={() => {
              setBuffering(true);
            }}
            onBufferEnd={() => {
              setBuffering(false);
            }}
            onProgress={handleProgress}
            onDuration={handleDuration}
            onEnded={handleEnded}
            playIcon={<div className="icon__play">play</div>}
          />
          {!thumbnail && !playing && (
            <div className="icon__play hide-thumnail-play-icon">play</div>
          )}
          {isPlayerLoad && thumbnail && !playing && (
            <div className="icon__play hide-thumnail-play-icon">play</div>
          )}
          {playing && buffering && <Loader customClass=" buffer_loader" />}
        </div>
        <div className="ion-video__controllers">
          {showBottomBack && (
            <div
              className="ion-video__controllers_align-left"
              onClick={() => {
                history.goBack();
              }}
            >
              {/* <div style={{ color: "white" }}>Back</div> */}
              <LanguageAppText
                tag={"div"}
                className="back"
                value={"Back"}
                textKey={"back"}
              />
            </div>
          )}
          {!hideControl && (
            <>
              <div className="ion-video__controllers_align-left">
                {isFullScreen ? (
                  <div className="ion-video__time">
                    <Duration seconds={duration * played} />/
                    <Duration seconds={duration} />
                  </div>
                ) : !hideFullScreen ? (
                  <div className="ion-video__icon-max ">
                    <div
                      onClick={onFullScreen}
                      className="ion-video__icon icon__max"
                    ></div>
                  </div>
                ) : null}
              </div>
              {isFullScreen && playing && (
                <div
                  className="ion-video__icon icon__pause"
                  onClick={togglePlayPause}
                ></div>
              )}
              {/* {isFullScreen && !playing && (
            <div className="ion-video__icon icon__play-fullscreen" onClick={togglePlayPause}>
            </div>
          )} */}
              <div className="ion-video__controllers_align-right">
                {isFullScreen ? (
                  <>
                    <div className="ion-video__icon-mute" onClick={toggleMute}>
                      <div
                        className={`ion-video__icon ${
                          muted ? "icon__mute" : "icon__unmute"
                        }`}
                      ></div>
                    </div>
                    {!hideFullScreen ? (
                      <div
                        className="ion-video__icon-min"
                        onClick={onFullScreen}
                      >
                        <div className="ion-video__icon icon__min"></div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <div className="ion-video__icon-mute" onClick={toggleMute}>
                    <div
                      className={`ion-video__icon ${
                        muted ? "icon__mute" : "icon__unmute"
                      }`}
                    ></div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {!hideProgress && (
        <div>
          <div className="ion-video__progress-bar">
            <input
              type="range"
              min={0}
              max={0.999999}
              step="any"
              style={{ width: window.screen.availWidth }}
              value={played}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
              onTouchStart={handleSeekMouseDown}
              onTouchEnd={handleSeekMouseUp}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default VideoPlayer;

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonImg,
  IonThumbnail,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ThumbnailVideo from "../../assets/images/global-default_new.jpeg";
import PlayStory from "../../assets/images/icons/play-story.svg";
import StoryNew from "../../assets/images/story-new.svg";

import LanguageAppText from "../languageapptext";
import "./style.scss";

interface Props {
  title: string;
  imgPath: string;
  likeCount?: number;
  cardName?: string;
  storyName?: boolean;
  isNew?: boolean;
  isLiked?: boolean;
  onClick?: any;
  status?: any;
  isActive?: any;
}

const StoryCard: React.FC<Props> = ({
  title,
  imgPath,
  likeCount,
  cardName,
  isNew,
  isLiked,
  onClick,
  status,
  isActive,
}) => {
  const [mediaPath, setMediaPath] = useState(imgPath);
  useEffect(() => {
    if (imgPath) {
      setMediaPath(imgPath);
    }
  }, [imgPath]);

  return (
    <IonCard className="page-stories__ion-card ion-no-margin" onClick={onClick}>
      <div className="page-stories__thumbnail-container">
        <IonThumbnail slot="start" className="page-stories__thumbnail">
          <img className="" src={mediaPath || ThumbnailVideo} />
        </IonThumbnail>
        {/* <IonImg
          src={mediaPath || ThumbnailVideo}
          className="page-stories__thumbnail"
        /> */}
        <IonButton className="page-stories__play ion-button__transparent-with-icon">
          <IonImg src={PlayStory} />
        </IonButton>
        {isNew && <IonImg src={StoryNew} className="story-new-icon" />}
      </div>
      <IonCardHeader className="page-stories__header ion-padding">
        {cardName && (
          <IonCardTitle className="page-stories__name text-blue font-medium mb-8">
            {cardName}
          </IonCardTitle>
        )}
        <IonCardTitle className="page-stories__title text-green font-medium">
          {title}
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent className="page-stories__content ion-padding">
        {/* {likeCount && likeCount > 0 ? (
          <Likes isCommentLiked={isLiked || false} likes={likeCount}></Likes>
        ) : null} */}
        {status &&
          (status === "pending" ||
          status === "approved" ||
          status === "draft" ? (
            <LanguageAppText
              tag={"div"}
              className="page-stories__awaiting text-black font-medium"
              value={"Awaiting review"}
              textKey={"awaiting_review"}
            />
          ) : status === "rejected" ? (
            <div className="page-stories__awaiting text-black font-medium">
              Rejected
            </div>
          ) : null)}

        {isActive != null && !isActive && status === "published" ? (
          <div className="page-stories__awaiting text-black font-medium">
            Inactive
          </div>
        ) : null}
      </IonCardContent>
    </IonCard>
  );
};

export default StoryCard;

import { IonImg } from "@ionic/react";
import React, { useEffect, useState } from "react";
import ButtonComponent from "../common/button-component";
import "./style.scss";
import CertificateDefault from "../../assets/images/global-default_new.jpeg";
import { socialSharing } from "../../utils/common.helper";

interface Props {
  imgPath?: string;
  onBackClick?: () => void;
}

const Certificate: React.FC<Props> = ({ imgPath, onBackClick }) => {
  const [mediaPath, setMediaPath] = useState(imgPath);
  useEffect(() => {
    if (imgPath) {
      setMediaPath(imgPath);
    }
  }, [imgPath]);

  const onShareClick = async () => {
    socialSharing(
      "I’m growing my business with Ovante. See my certificate here!",
      undefined,
      imgPath,
      "https://ovante.org/redirect/3h4jks"
    );
  };
  return (
    <div className="page-certificate__container">
      <IonImg
        src={mediaPath || CertificateDefault}
        className="page-certificate__img"
      />
      <div className="certificate__button-container">
        <ButtonComponent
          name="Go back"
          outline={true}
          customClass="certificate__button-go-back"
          onClick={() => {
            onBackClick ? onBackClick() : console.log("");
          }}
          textKey={"go_back"}
        ></ButtonComponent>
      </div>
    </div>
  );
};
export default Certificate;

import { IonContent, IonImg, IonPage, useIonRouter } from "@ionic/react";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import RegisterHeader from "../../components/common/register-header";
import LanguageAppText from "../../components/languageapptext";
import { TNC_LINK } from "../../config/appconfig";
import { AppContext } from "../../context/app.context";
import { agreeToTOC } from "../../services/user.service";
import "./style.scss";

const TermsOfUse: React.FC = () => {
  const { toastMessage, language }: any = useContext(AppContext);
  const history: any = useHistory();

  const handleAcceptTOC = async () => {
    const resp: any = await agreeToTOC();
    if (!resp.error) {
      history.replace({
        pathname: "/onboarding",
      });
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  return (
    <IonPage className="page-t-and-c">
      <RegisterHeader
        pageName={
          language == "hi"
            ? "ओवांते इंडिया उपयोग की शर्तें"
            : "OVANTE INDIA TERMS OF USE"
        }
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen={true} className="page-content">
        <div className="pageContent">
          {language == "hi" ? (
            <>
              <p>
                ओवांते इंडिया प्लेटफॉर्म पर आपका स्वागत है, एक डिजिटल प्लेटफॉर्म
                जो सूक्ष्म उद्यमियों के प्रबंधन, वित्त और डिजिटल क्षमताओं को
                मजबूत करने के लिए डिजाइन किया गया है (“ओवांते इंडिया” या
                “प्लेटफॉर्म”)। ओवांते इंडिया एक्सियन इंटरनेशनल का एक प्रोग्राम
                है (अपने सहयोगियों के साथ, “एक्सियन,” “हम,” “हमारा,” “हम,”), एक
                501 (सी)(3) गैर लाभकारी निगम है । कृपया निम्नलिखित उपयोग की
                शर्तें (“शर्तें”) ध्यानपूवर्क पढ़ें क्योंकि वे प्लेटफॉर्म के
                आपके उपयोग को नियंत्रित करते हैं, जिसमें ऑनलाइन सीखने के अनुभव,
                प्रोग्राम एप्लीकेशन और हमारे प्लेटफॉर्म पर सभी सामग्री, जानकारी
                और सेवाएं शामिल हैं, लेकिन इन्हीं तक सीमित नहीं हैं। यह
                प्लेटफॉर्म और सारी सामग्री, जानकारी और सेवाएं जो एक इस
                प्लेटफॉर्म पर होस्ट की गई हैं और प्रोग्राम एप्लीकेशन को एक साथ
                “सर्विसेज” कहा जाता है।
              </p>
              <h2>शर्तों के लिए समझौता</h2>
              <p>
                ओवांते इंडिया पर विजिट करके और हमारी सेवाओं का उपयोग करके, आप,
                इस प्लेटफ़ॉर्म तक पहुँचने वाले व्यक्ति, सहमत हैं कि आप कानूनी
                रूप से इन शर्तों से बंधे हुए हैं। अगर आपको इन शर्तों के किसी भी
                प्रावधान से आपत्ति है तो इस प्लेटफॉर्म का उपयोग न करें और हमारी
                सेवाओं का उपयोग करना बंद कर दें। हमारी कुछ सेवाओं का उपयोग करने
                के दौरान आपको कुछ विशिष्ट ओवांते इंडिया या एक्सियन डिजिटल की कुछ
                विशेषताओं के मद्देनजर इस प्लेटफॉर्म से बाहर निर्देशित किया जाएगा
                (उदाहरण., एक लिंक पर क्लिक करना)। ऐसे कोई भी बाहरी साइट अपनी
                साइट के शर्तों और नियमों और निजता नीतियां (अगर कोई हो) द्वारा
                शासित होगा।
              </p>
              <h2>संपत्ति का अधिकार</h2>
              <p>
                जब तक अन्यथा न कहा गया हो, एक्सियन और/या इसके लाइसेंसकर्ता इस
                प्लेटफॉर्म पर और सेवाओं में सभी सामग्री के लिए बौद्धिक संपदा
                अधिकारों के मालिक हैं, जिसमें इस प्लेटफॉर्म पर बिना किसी सीमा
                के, चित्र, पाठ और ट्रेडमार्क, सेवा चिह्न, लोगो, टूलकिट, मॉड्यूल
                और सीखने की सामग्री शामिल है (सामूहिक रूप से, &quot; ओवांते
                सामग्री&quot;)। सभी ओवांते सामग्री संयुक्त राज्य अमेरिका और
                विदेशी कानूनों के तहत कॉपीराइट और अन्य बौद्धिक संपदा ​अधिकारों
                द्वारा संरक्षित है और एक्सियन का ओवांते सामग्री में सभी बौद्धिक
                संपदा अधिकारों पर आरक्षण है। इन शर्तों में कुछ भी आपको एक्सियन
                या ओवांते नाम या एक्सियन के किसी ट्रेडमार्क, लोगो, डोमेन नाम या
                अन्य विशिष्ट ब्रांड सुविधाओं का उपयोग करने का अधिकार नहीं देता
                है।
              </p>
              <p>
                आप इन शर्तों के अधीन अपने निजी या आंतरिक व्यावसायिक उपयोग के लिए
                इस प्लेटफॉर्म से पृष्ठों को देख, डाउनलोड और/या प्रिंट कर सकते
                हैं। आपको ओवांते सामग्री को फिर प्रकाशित, नकल, कॉपी, संशोधित,
                स्थानांतरण, बिक्री, किराए या लाइसेंस नहीं देना चाहिए, या अन्यथा
                एक्सियन की पूर्व लिखित अनुमति के बिना किसी भी व्यावसायिक
                उद्देश्य सहित किसी अन्य उद्देश्य के लिए या किसी भी विशेषता को
                हटाने के लिए ओवांते सामग्री का उपयोग नहीं करना चाहिए।
              </p>
              <p>
                आप ओवांते सामग्री को सिर्फ &#39;शेयर&#39; सुविधा का उपयोग करके
                या हमारे प्लेटफॉर्म के लिए एक लिंक बनाकर साझा या फिर से वितरित
                कर सकते हैं, जब तक कि लिंक: (a) किसी भी तरह से भ्रामक नहीं है;
                (b) लिंकिंग पार्टी और उसके उत्पादों या सेवाओं के एक्सियन द्वारा
                प्रायोजन, समर्थन, या अनुमोदन का झूठा अर्थ नहीं है; और (c)
                एक्सियन को प्रतिकूल रूप से प्रतिबिंबित नहीं करता है। हम किसी भी
                समय और अपने विवेक के अधिकार में यह अनुरोध करने का अधिकार
                सुरक्षित रखते हैं कि आप हमारे प्लेटफ़ॉर्म से संबंधित किसी विशेष
                लिंक को हटा दें। आप इस तरह के अनुरोध पर हमारे प्लेटफॉर्म के सभी
                लिंक को तुरंत हटाने के लिए सहमत हैं।
              </p>
              <p>
                अगर आप हमारे प्लेटफॉर्म पर मिलने वाली जानकारी को प्रकाशित करना
                चाहते हैं, तो कृपया अपना अनुरोध{" "}
                <a href="mailto:support@ovante.in">support@ovante.in</a> के
                माध्यम से हमें भेंजे।{" "}
              </p>
              <h2>तीसरे पक्ष के प्लेटफॉर्म के लिए लिंक</h2>
              <p>
                हमारी सेवाओं में तीसरे पक्ष की वेबसाइट, एप्लीकेशन, प्लेटफॉर्म के
                लिंक और/या अनुशंसा शामिल हो सकते हैं। या उन सेवाओं के लिए जो
                एक्सियन के स्वामित्व के अधीन या नियंत्रित नहीं है। हम अन्य
                साइटों पर मौजूद सामग्री, निजता नीतियों, अभ्यासों, विचारों, या
                जानकारी की सटीकता के लिए कोई जिम्मेदारी नहीं लेते। हम आपके
                व्यवसाय के लिए ऐसे लिंक और/या अनुशंसाओं के उपयुक्त होने की
                गारंटी नहीं देते हैं और आप इन लिंक का अनुसरण करते हैं और अपने
                जोखिम पर ऐसी तीसरे-पक्ष वेबसाइटों, एप्लीकेशन, प्लेटफॉर्म या
                सेवाओं का उपयोग करते हैं। ओवांते इंडिया के माध्यम से तीसरे—पक्ष
                की वेबसाइटों, एप्लीकेशन, प्लेटफॉर्म, या सेवाओं के लिए कोई भी
                लिंक और/या अनुशंसा आपकी सुविधा के लिए प्रदान की जाती है और किसी
                तीसरे-पक्ष की लिंक और या अनुशंसा के किसी भी समावेश को किसी भी
                तरह से हमारे द्वारा प्रचारित नहीं किया जाता ।
              </p>

              <h2>ईमेल संचार</h2>
              <p>
                इस प्लेटफॉर्म पर अपना ईमेल पता जमा कर, आप इस बात की सहमति दे रहे
                हैं कि आपको ओवांते इंडिया की मेलिंग सूची में डाला जा सकता है और
                आप ओवांते इंडिया से ईमेल संचार प्राप्त कर सकते हैं, जिसमें
                न्यूलेटर और प्लेटफॉर्म के अपडेट शामिल हैं । आप लागू मेलिंग सूची
                से अपना ईमेल पता किसी भी समय ओवांते इंडिया से प्राप्त होने वाले
                मेल में मौजूद अनसब्सक्राइब लिंक पर क्लिक कर हटा सकते हैं।
                हालांकि, ओवांते इंडिया तुरंत आपके ईमेल पते को अपनी मेलिंग सूची
                से हटा देगा, आपको फिर भी सूची से नाम हटाये जाने से पहले शुरू किए
                गए ईमेल प्राप्त होंगे।
              </p>
              <h2>व्हाट्सएप संचार</h2>
              <p>
                इस प्लेटफॉर्म पर अपना व्हाट्सएप नंबर डालकर आप अपने ओवांते इंडिया
                अकाउंट और प्लेटफॉर्म के उपयोग के बारे में व्हाट्सएप मैसेजिंग
                प्लेटफॉर्म के माध्यम से ओवांते इंडिया से आवश्यक समय पर संचार
                प्राप्त करने के लिए सहमति दे रहे हैं, जिसमें बिना किसी सीमा के,
                उपयोगकर्ता समर्थन अनुरोधों को शुरू करना या उनका जवाब देना शामिल
                है। आप इस बात से सहमत हैं कि एक्सियन हमारी सेवा की गुणवत्ता को
                बनाए रखने और सुधारने और ग्राहक सहायता प्रदान करने के उद्देश्य से
                आपके और हमारे बीच किए गए किसी भी संचार की निगरानी और/या रिकॉर्ड
                कर सकता है।
              </p>
              <h2>फोन/एसएमएस संचार</h2>
              <p>
                आपको पता है कि एक्सियन समय—समय पर आपके ओवांते इंडिया अकाउंट और
                प्लेटफॉर्म के उपयोग के बारे में आपको कॉल करेगा या एसएमएस मैसेज
                भेजेगा। आप स्वीकार करते हैं और सहमत हैं कि ओवांते इंडिया को अपना
                टेलीफोन या मोबाइल नंबर देकर आपने एक्सियन की ओर से अपने ओवांते
                इंडिया खाते के बारे में और प्लेटफॉर्म के उपयोग के बारे में
                टेलीफोन कॉल और एसएमएस मैसेज प्राप्त करने के लिए सहमत हैं। आप इस
                बात से सहमत हैं कि एक्सियन हमारी सेवा की गुणवत्ता को बनाए रखने
                और सुधारने और ग्राहक सहायता प्रदान करने के उद्देश्य से आपके और
                हमारे बीच किए गए किसी भी संचार की निगरानी और/या रिकॉर्ड कर सकता
                है।
              </p>
              <h2>उपयोगकर्ता सामग्री</h2>
              <p>
                सेवाओं के कुछ क्षेत्र उपयोगकर्ताओं को सामग्री और टिप्पणी पोस्ट
                करने, जानकारी, सामग्री, प्रश्न और अन्य सामग्री का आदान—प्रदान
                करने का मौका देता है (ऐसी कोई भी सामग्री जो उपयोगकर्ता जमा करता
                है, पोस्ट करता है, प्रदर्शित करता है, या अन्यथा सेवा पर उपलब्ध
                कराता है, &quot; उपयोगकर्ता सामग्री &quot;)। एक्सियन ओवांते
                इंडिया पर प्रदर्शित होने से पहले उपयोगकर्ता सामग्री को स्क्रीन,
                संपादित, प्रकाशित या समीक्षा नहीं करता है, सीमित परिस्थितियों को
                छोड़कर हम किसी भी मीडिया जरूरतों के अनुरूप उपयोगकर्ता सामग्री के
                कुछ रूपों को संशोधित या अनुकूल बना सकते हैं।उपयोगकर्ता सामग्री
                एक्सियन के विचारों को प्रतिबिंबित नहीं करता और एक्सियन ओवांते
                इंडिया पर पोस्ट किए गए या दिखने वाले किसी भी उपयोगकर्ता सामग्री
                की पूर्णता, सच्चाई, सटीकता या विश्वसनीयता का प्रचार, समर्थन,
                प्रदर्शन या गारंटी नहीं देता है। लागू कानूनों द्वारा अनुमत सीमा
                तक, एक्सियन उपयोगकर्ता सामग्री के लिए या किसी भी डूब चुकी लागत,
                देयता, क्षति, या व्यय के लिए जिम्मेदार या उत्तरदायी नहीं होगा,
                जो ओवांते इंडिया पर उपयोगकर्ता सामग्री के किसी भी उपयोग,
                पोस्टिंग या उपस्थिति के परिणामस्वरूप उत्पन्न हो सकता है या
                भुगतना पड़ता है। किसी भी उपयोगकर्ता सामग्री पर कोई भी उपयोग या
                निर्भरता आपके अपने जोखिम पर है।
              </p>
              <p>
                आप सहमत है ऐसी उपयोगकर्ता सामग्री न पोस्ट करने के लिए जो : (i)
                आपको, किसी और व्यक्ति, या किसी जानवर को नुकसान, हानि, शारीरिक या
                मानसिक चोट, भावनात्मक संकट, मृत्यु, विकलांगता, विकृति, या
                शारीरिक या मानसिक बीमारी का जोखिम पैदा कर सकता है;(ii) किसी और
                व्यक्ति या संपत्ति को किसी और प्रकार का नुकसान या ​क्षति का
                जोखिम पैदा कर सकता है; (iii) बच्चों का अनुचित सामग्री से
                आमना—सामना करवा के, व्यक्तिगत रूप से पहचाने जाने योग्य विवरण या
                अन्यथा जानकारी पूछकर उन्हें नुकसान पहुंचाना या उनका शोषण करना
                चाहता है; (iv) अपराध या अपकृत्य का गठन या योगदान कर सकता है; (v)
                ऐसी कोई भी जानकारी या सामग्री शामिल है जिसे हम गैरकानूनी,
                हानिकारक, अपमानजनक, नस्लीय या जातीय रूप से आक्रामक, मानहानिकारक,
                उल्लंघनकारी, व्यक्तिगत गोपनीयता या प्रचार अधिकारों के लिए
                आक्रामक, अन्य लोगों को परेशान करने, अपमानजनक (सार्वजनिक रूप से
                या अन्यथा) मानते हैं, धमकी, अपवित्र, या अन्यथा आपत्तिजनक है;
                (vi) कोई भी जानकारी या सामग्री शामिल है जो अवैध है; (vii) ऐसी
                कोई भी जानकारी या सामग्री शामिल है जिसे किसी कानून के तहत या
                संविदात्मक या प्रत्ययी संबंधों के तहत उपलब्ध कराने का आपके पास
                अधिकार नहीं है; (viii) बिना किसी सीमा के किसी भी पेटेंट,
                कॉपीराइट, ट्रेडमार्क, या अन्य बौद्धिक संपदा और गोपनीयता के
                अधिकारों सहित किसी भी प्रकार के तीसरे पक्ष के अधिकारों का
                उल्लंघन करता है; (ix) कोई सॉपफ्टवेयर वायरस या अन्य मैलवेयर है;
                (x) कोई वाणिज्यिक या राजनीतिक आग्रह शामिल है; (xi) कोई विज्ञापन,
                पिरामिड योजना, या अन्य स्पैम शामिल है; (xii) और/या गलत, भ्रमजनक
                या भ्रामक है।
              </p>
              <p>
                उपयोगकर्ता सामग्री को निगरानी करने, रिकॉर्ड करने, संपादित करने,
                वितरित करने से मना करने, प्लेटफॉर्म से हटाने, या किसी भी कारण से
                कोई भी उपयोगकर्ता खाता निलंबित या समाप्त करने के सभी अधिकार
                एक्सियन के पास सुरक्षित हैं। यह बिना किसी दायित्व के इन शर्तों
                के उल्लंघन सहित मामलों में लागू होगा।
              </p>
              <p>
                आपको अधिकार है कि कोई भी उपयोगकर्ता सामग्री जो आप पोस्ट करते हैं
                या प्लेटफॉर्म पर डालते है उसका अधिकार बनाए रखें।प्लेटफॉर्म पर
                उपयोगकर्ता सामग्री जमा करके आप स्वत: एक्सियन को एक वैश्विक,
                स्थायी, अपरिवर्तनीय, गैर-अनन्य, पूरी तरह से हस्तांतरणीय,
                रॉयल्टी-मुक्त लाइसेंस (उप-लाइसेंस के अधिकार के साथ) का उपयोग
                करने, कॉपी करने, अनुकूलित करने, प्रकाशित करने, अनुवाद करने,
                वितरित करने, सार्वजनिक रूप से प्रदर्शित करने, संपादित करने,
                व्युत्पन्न कार्यों को बनाने और फिर से पेश करने के लिए किसी भी और
                सभी रूपों, प्रारूपों, प्लेटफार्मों, या मीडिया में कोई भी
                उपयोगकर्ता सामग्री का अधिकार दे रहे हैं (अब ज्ञात या बाद में
                विकसित)। आप प्रदर्शित करते हैं कि आपके पास सारे जरूरी अधिकार हैं
                उपयोगकर्ता सामग्री को ओवांतेइंडिया प्लेटफॉर्म के माध्यम से
                वितरित करने का, इसलिए क्योंकि आप उस उपयोगकर्ता सामग्री के लेखक
                हैं और आपके पास इसका वितरण करने का अधिकार है, या क्योंकि आपके
                पास उपयोगकर्ता सामग्री के दूसरे मालिक से सटीक वितरण के अधिकार,
                लाइसेंस, सहमति, और/या उपयोग करने की अनुमति लिखित में है।
              </p>
              <h2>ओवांते इंडिया पुरस्कार</h2>
              <p>
                एक्सियन आपको अंक प्राप्त करने और पुरस्कार और लाभों के लिए इन
                अंकों को भुनाने का अवसर प्रदान कर सकता है, जैसा कि प्लेटफॉर्म पर
                समय-समय पर अपडेट किया जाता है। यह अंक आपको बिना किसी शुल्क के
                प्रचार के आधार पर दिये जाते हैं, इनका कोई नकद मूल्य नहीं होता और
                ये नकद के लिए भुनाने योग्य नहीं हैं। एक्सियन, अपने विवेकाधिकार
                में, किसी भी तरह से आपके अधिग्रहण, उपयोग, पुरस्कार, ऑफ़र और
                अंकों को भुनाने को प्रतिबंधित कर सकता है।&quot; हम आपके अंक
                प्राप्त करने के तरीके को बदलने, कार्यक्रम के लाभों को बदलने और
                किसी भी समय, बिना सूचना के या बिना पुरस्कार और अंक प्रणाली को
                बंद करने का अधिकार सुरक्षित रखते हैं। इसका मतलब यह है कि जब तक
                इन अंकों को वास्तव में भुनाया नहीं जाता है, तब तक अंकों का संचय
                आपको ऐसे अंकों, पुरस्कारों या कार्यक्रम के लाभों के संबंध में
                किसी भी तरह का अधिकार नहीं देता है।
              </p>
              <p>
                इन अंकों तक पहुंच प्राप्त करने के लिए आपका ओवांतेइंडिया का
                पंजीकृत उपयोगकर्ता होना और उपयोग की शर्तों का अनुपालन करना जरूरी
                है। एक बार आपने एक पुरस्कार या लाभ के लिए भुनाने लायक आवश्यक अंक
                जमा कर लिये, जैसा प्लेटफॉर्म में वर्णित है, तो आपको प्लेटफॉर्म
                के जरिये निर्देशित किया जाएगा जो आपको इन अंकों को किसी पुरस्कार
                या लाभों के लिए भुनाने के लिए अवसर देता है। आपको लाभों को भुनाने
                के लिए निर्देशों का अनुसरण करना चाहिए इससे पहले कि अंक, लाभ और
                पुरस्कार की तिथि समाप्त हो जाए।
              </p>
              <p>
                यह अंक, लाभ और पुरस्कार हस्तांतरित नहीं किये जा सकते। यह अंक और
                पुरस्कार प्रोग्राम 13 साल की उम्र के नीचे के किसी भी बच्चे पर न
                तो लक्षित है न ही उनके द्वारा उपयोग किये जाने के उद्दिष्ट है। 13
                साल की उम्र से नीचे के बच्चे अंक और पुरस्कार प्रोग्राम में भाग
                नहीं ले सकते हैं। अगर आपकी उम्र 13 से 18 वर्ष के बीच है तो आप
                सिर्फ ऐसे माता-पिता या कानूनी अभिभावक के प्रत्यक्ष पर्यवेक्षण के
                तहत अंक और पुरस्कार कार्यक्रम में भाग ले सकते हैं जो इन शर्तों
                से बाध्य होने के लिए भी सहमत हैं।
              </p>
              <p>
                इस पुरस्कारों और लाभों के वितरण पर लागू कानून के तहत उत्पन्न
                होने वाले किसी भी कर, उगाही या अन्य शुल्क के लिए आप जिम्मेदार
                होंगे। आप स्वीकार करते हैं कि आप किसी भी पुरस्कार या लाभ के किसी
                भी अधिकार क्षेत्र में किसी भी कर, उगाही, या अन्य शुल्क के अधीन
                होने के कारण किसी भी अतिरिक्त भुगतान की मांग करने के हकदार नहीं
                होंगे।
              </p>
              <p>
                पुरस्कार या लाभ के रूप में दिए जाने वाले समूहों, कंपनियों,
                उत्पादों या सेवाओं के नाम का हमारे साथ कोई जुड़ाव नहीं है या हम
                उसका कोई प्रचार नहीं करते।
              </p>
              <h2>अस्वीकरण</h2>
              <p>
                आप सहमत है कि इस प्लेटफॉर्म का उपयोग या इस पर लिंक होना या कोई
                सेवाएं लेना आपके स्वयं के जोखिम पर है। लागू कानून द्वारा अनुमत
                अधिकतम सीमा तक एक्सियन, उसके उत्तराधिकारी और उसके सहयोगी उन सभी
                प्रतिरूपों या वारंटियों, चाहें वो अभिव्यक्त हों या लक्षित हों,
                ​को अस्वीकर करते हैं। इसमें किसी विशेष उपयोग के लिए उपयुक्तता
                और/या उचित देखभाल और कौशल का उपयोग शामिल है, लेकिन यह इन्हीं तक
                सीमित नहीं है, जो हमारे प्लेटफॉर्म और सेवाओं से संबंधित हो सकता
                है।।
              </p>
              <p>
                हम ऐसा कोई निरूपण नहीं करते कि ओवांते सामग्री या सेवाएं सही हैं
                या यह अमेरिका के बाहर उपयोग या डाउनलोड किये जा सकते हैं। सेवाओं
                तक पहुंच अमेरिका के बाहर कुछ देशों में कानूनी रूप से वैध नहीं भी
                हो सकते हैं। अगर आप अमेरिका के बाहर से सेवाओं तक पहुंच प्राप्त
                करते हैं, तो आप अपने जोखिम पर ऐसा करते हैं और उस अधिकार क्षेत्र
                के कानूनों के अनुपालन के लिए जिम्मेदार हैं जहां से आप इस
                प्लेटफॉर्म तक पहुंच प्राप्त करते हैं।
              </p>
              <h2>दायित्व की सीमा</h2>
              <p>
                किसी भी घटना में एक्सियन, न तो इसके किसी भी अधिकारी, निदेशक,
                कर्मचारी, स्वयंसेवकों, एजेंटों, लाइसेंसकर्ताओं और आपूर्तिकर्ताओं
                को किसी भी प्रत्यक्ष या अप्रत्यक्ष, परिणामी, या विशेष नुकसान,
                दावों या दायित्व जो किसी भी तरह से प्लेटफॉर्म या सेवाओं के उपयोग
                से जुड़े हैं या कोई भी संबद्ध जानकारी, उत्पाद और सेवाओं के लिए
                उत्तरदायी नहीं हैं।
              </p>
              <h2>निजी जानकारी जो हम आपसे एकत्रित कर सकते हैं</h2>
              <p>
                इन शर्तों के उद्देश्यों के लिए, &quot;निजी जानकारी&quot; का मतलब
                किसी व्यक्ति से संबंधित कोई भी जानकारी है, जो प्रत्यक्ष या
                अप्रत्यक्ष रूप से या अन्य जानकारी के संयोजन में है जो किसी
                संस्था के लिए उपलब्ध है या हो सकती है और ऐसे व्यक्ति की पहचान
                करने में सक्षम है। &quot;संवेदनशील निजी जानकारी&quot; में निजी
                जानकारी जैसे पासवर्ड, वित्तीय जानकारी जैसे बैंक खाता या क्रेडिट
                कार्ड या डेबिट कार्ड या अन्य भुगतान साधन का विवरण शामिल हैं।
              </p>
              <p className="pb-0">
                एक्सियन आपसे सीधे तौर पर एकत्रित कर स​कता है—
              </p>
              <ol className="type-alpha">
                <li>
                  कोई भी जानकारी जो आप प्रदान करते हैं जब आप हमारे प्लेटफॉर्म पर
                  साइन इन करते हैं या इस पर क्रियाकलाप करते हैं, सेवाओं का उपयोग
                  करते हैं, आवधिक अपडेट के लिए साइन अप करते हैं, या हमारे साथ
                  किसी अन्य तरीके से जुड़ते है, हम आपसे कुछ निजी जानकारी या
                  संवेदनशील निजी जानकारी एकत्रित करते हैं, जिनमें आपके उपयोग की
                  जानकारी,(जैसे प्लेटफॉर्म या कार्यक्रमों में बिताया गया समय,
                  पूरे किए गए कार्यक्रमों की संख्या) प्रोफाइल की जानकारी,
                  जनसांख्यिकीय जानकारी, शिक्षा का स्तर, जानकारी जो आप अपने
                  व्यापार के बारे में प्रदान करते हैं,(जैसे बिक्री रेंज, व्यवसाय
                  का प्रकार) नाम और फोन नंबर शामिल है।
                </li>
                <li>
                  जानकारी जो आप फॉर्म या &#39;कहानियों&#39; के अनुभाग में
                  संग्रहित कर सकते हैं।
                </li>
                <li>
                  Statistics pertaining to users’ actions and behaviour while
                  using the Platform.
                </li>
                <li>
                  प्लेटफॉर्म का उपयोग करते समय उपयोगकर्ताओं के कार्यों और
                  व्यवहार से संबंधित आंकड़े।
                </li>
                <li>
                  कैमरा, ऑडियो रिकॉर्डिंग (उदाहरण के लिए, जब आप एक वीडियो कहानी
                  रिकॉर्ड करते हैं या एक ऑडियो टिप्पणी छोड़ते हैं), और इंटरनेट
                  कनेक्टिविटी जैसे उपकरण सुविधाओं से उत्पन्न उपयोग और जानकारी।
                </li>
                <li>
                  कोई भी जानकारी जो आप ग्राहक सहायता सेवाओं को प्राप्त करने के
                  लिए प्रदान करते हैं, जिनमें नाम, फोन नंबर, स्थान, उपकरण की
                  विशेषताएं (आईपी, मोबाइल उपकरण या डेस्कटॉप, और ऑपरेशन सि​स्टम)
                  साथ ही साथ ओवांते इंडिया को भेजे गए संदेश की अंतर्वस्तु।
                </li>
                <li>
                  अगर आप प्लेटफॉर्म पर लॉगिन करने के लिए सोशल मीडिया प्रोफाइलों
                  (जैसे फेसबुक) का उपयोग करते हैं और अनुमति देते हैं, तो हम आपके
                  ईमेल पते और फोन नंबर की जानकारी एकत्रित करते हैं।
                </li>
              </ol>
              <h2>कुकीज</h2>
              <p>
                हम कुकीज के उपयोग को नियोजित कर सकते हैं। इस प्लेटफॉर्म का उपयोग
                करके, आप एक्सियन की गोपनीयता नीति के अनुसार कुकीज के उपयोग के
                लिए सहमति देते हैं।
              </p>
              <h2>एकत्रित करने और साझाकरण का उद्देश्य</h2>
              <p>
                हम इस जानकारी का उपयोग कई उद्देश्यों और गतिविधियों के लिए करते
                हैं जिनमें आपको सेवाएं देना, आपको अवाधिक संचार और जानकारी भेजना,
                तकनीकी समस्याओं को हल करना और प्लेटफॉर्म और/या सेवाओं की कुल
                गुणवत्ता को बेहतर करना शामिल है।
              </p>
              <p>
                हम इस जानकारी को तीसरे पक्ष को प्रकट कर सकते हैं, जिसमें हमारे
                सहयोगी, पार्टनर, सलाहकार, विक्रेता और सेवा प्रदाता शामिल हैं, यह
                गोपनीयता और डेटा सुरक्षा के लागू दायित्वों के अधीन है, अगर (i)
                हम सेवाएं प्रदान करने के लिए उनके साथ काम कर रहे हैं, (ii) हमारे
                पास आपकी जानकारी का खुलासा करने या साझा करने का एक कर्तव्य है,
                (iii) किसी भी कानूनी दायित्वों का पालन करने के लिए, और (iv) इन
                शर्तों और अन्य समझौतों को लागू करने और उनका पालन करना शामिल है।
              </p>
              <h2>निजता नीतियां</h2>
              <p>
                जब आप हमारे प्लेटफॉर्म पर जाते हैं या सेवाओं का उपयोग करते हैं,
                तो हम आपकी जानकारी को कैसे एकत्रित, उपयोग, संग्रहित, खुलासा और
                संरक्षित करते हैं, इस बारे में अतिरिक्त जानकारी के लिए, कृपया
                एक्सियन की गोपनीयता नीति देखें, जिसे संदर्भ द्वारा इन शर्तों में
                शामिल किया गया है।
              </p>
              <h2>शिकायत अधिकारी</h2>
              <p>
                प्लेटफॉर्म का उपयोग करके आप एक्सियन को जानकारी एकत्रित करने की
                सहमति देते हैं। अगर आप सहमति नहीं देते हैं, अपनी सहमति वापस लेना
                चाहते हैं, या आपके पास एक शिकायत है,तो कृपया शिकायत अधिकारी से
                यहां संपर्क करें
              </p>
              <p className="pb-0">अर्चना केजरीवाल</p>
              <p className="pb-0">
                नंबर 9/3, पहली मंजिल, कैशर—ए—हिंद, रिचमंड रोड, बैंगलोर, कर्नाटक
                560025
              </p>
              <p>
                <a href="mailto:support@ovante.in">support@ovante.in</a>
              </p>
              <h2>कानून का चयन</h2>
              <p>
                आप सहमत हैं कि न्यूयॉर्क राज्य के कानून, कानूनों के टकराव के
                सिद्धांतों की परवाह किए बिना, इन शर्तों और आपके और ​एक्सियन के
                बीच उत्पन्न होने वाले किसी भी प्रकार के किसी भी विवाद को
                नियंत्रित करेंगे।
              </p>
              <h2>हमसे संपर्क करना</h2>
              <p>
                अगर शर्तों के बारे में आपके कोई सवाल हैं तो हमसे{" "}
                <a href="mialto:legal@accion.org">legal@accion.org</a> पर संपर्क
                करें।
              </p>
              <h2>अपडेट</h2>
              <p>
                हमारे शर्तों को समय—समय पर अपडेट किया जा सकता है। हर बार जब आप
                इस प्लेटफॉर्म तक पहुंचते हैं और/या हमारी सेवाओं का उपयोग करते
                हैं, तो शर्तों का वर्तमान संस्करण लागू होगा।
              </p>
            </>
          ) : (
            <>
              <p>
                Welcome to the Ovante India platform, a digital platform
                designed to strengthen the management, financial and digital
                capabilities of microentrepreneurs (“Ovante India” or the
                “Platform”). Ovante India is a program of Accion International
                (together with its affiliates, “Accion,” “we,” “our,” “us,”), a
                501(c)(3) not for profit corporation. Please read the following
                Terms of Use and Conditions (“Terms”) carefully because they
                govern your use of the Platform, including, but not limited to,
                the online learning experiences, program applications, and all
                content, information, and services on our Platform. This
                Platform and all content, information, and services hosted on
                our Platform and program applications are collectively called
                the “Services.”
              </p>
              <h2>AGREEMENT TO TERMS</h2>
              <p>
                By visiting Ovante India and using our Services, you, the person
                accessing this Platform, agree that you are legally bound by
                these Terms. If you object to any provisions of these Terms, do
                not use this Platform and cease all use of our Services. You may
                also be directed to specific Ovante India or Accion digital
                properties outside of this Platform when using certain of our
                Services (e.g., clicking on a link). Any such external site will
                be governed by the terms and conditions and privacy policy (if
                any) of such site.
              </p>
              <h2>PROPERTY RIGHTS</h2>
              <p>
                Unless otherwise stated, Accion and/or its licensors own the
                intellectual property rights for all material on this Platform
                and in the Services, including without limitation, the images,
                text and the trademarks, service marks, logos, toolkits,
                modules, and learning content on this Platform (collectively,
                the “Ovante Content”). All Ovante Content is protected by
                copyright and other intellectual property rights under both
                United States and foreign laws and Accion reserves all
                intellectual property rights in the Ovante Content. Nothing in
                these Terms gives you a right to use the Accion or Ovante name
                or any of Accion’s trademarks, logos, domain names or other
                distinctive brand features.
              </p>
              <p>
                You may view, download and/or print pages from this Platform for
                your own personal or internal business use, subject to these
                Terms. You must not republish, duplicate, copy, modify,
                transfer, sell, rent, or license the Ovante Content, or
                otherwise make use of the Ovante Content for any other purpose,
                including any commercial purpose, or remove any attributions,
                without Accion’s prior written permission.
              </p>
              <p>
                You may share or redistribute Ovante Content only as permitted
                using the ‘share’ feature or by creating a link to our Platform,
                so long as the link: (a) is not in any way misleading; (b) does
                not falsely imply sponsorship, endorsement, or approval by
                Accion of the linking party and its products or services; and
                (c) the link does not reflect unfavorably to Accion. We reserve
                the right at any time and in our sole discretion to request that
                you remove any particular link to our Platform. You agree to
                immediately remove all links to our Platform upon such request.
              </p>
              <p>
                If you would like to publish information that you find on our
                Platform, please send your request through{" "}
                <a href="mailto:support@ovante.in">support@ovante.in</a>.
              </p>
              <h2>LINKS TO THIRD-PARTY PLATFORMS</h2>
              <p>
                Our Services may contain links and/or recommendations to
                third-party websites, applications, platforms. or services that
                are not owned or controlled by Accion. We take no responsibility
                for the content, privacy policies, practices, views, or accuracy
                of the information contained on other sites. We do not warrant
                the suitability of such links and/or recommendations for your
                business and you follow these links and use
              </p>

              <p>
                such third-party websites, applications, platforms, or services
                at your own risk. Any links and/or recommendation to third-party
                websites, applications, platforms, or services through Ovante
                India are provided for your convenience and any inclusion of a
                third-party link and or recommendation shall in no way be
                construed as an endorsement by us.
              </p>
              <h2>EMAIL COMMUNICATION</h2>
              <p>
                By submitting your email address to this Platform, you consent
                to being placed on Ovante India’s mailing lists and thereby
                receiving e-mail correspondence from Ovante India, including
                newsletters and Platform updates. You may remove your e-mail
                address from the applicable mailing list at any time by clicking
                the “unsubscribe” link in any e-mail from Ovante India. Although
                Ovante India will promptly remove your e- mail address from its
                mailing list, you may still receive any e-mails that are
                initiated before your e-mail address was removed from the list.
              </p>
              <h2>WHATSAPP COMMUNICATION</h2>
              <p>
                By submitting your WhatsApp number to this Platform, you consent
                to receiving periodic communication from Ovante India through
                the WhatsApp messaging platform regarding your Ovante India
                account and use of the Platform, including without limitation,
                initiating or responding to user support requests. You agree
                that Accion may monitor and/or record any communications made
                between you and us for the purpose of maintaining and improving
                the quality of our service and providing customer support.
              </p>
              <h2>PHONE/SMS COMMUNICATION</h2>
              <p>
                You are aware that Accion may from time-to-time call you or SMS
                message you about your Ovante India account and use of the
                Platform. You acknowledge and agree that by providing your
                telephone or mobile number to Ovante India, you consent to
                receive telephone calls and SMS messages from or on behalf of
                Accion regarding your Ovante India account and use of the
                Platform. You agree that Accion may monitor and/or record any
                communications made between you and us for the purpose of
                maintaining and improving the quality of our service.
              </p>
              <h2>USER CONTENT</h2>
              <p>
                Some areas of the Service offer the opportunity for users to
                post content and comments, to exchange information, material,
                questions, and other content (any such materials a user submits,
                posts, displays, or otherwise makes available on the Service,
                the “User Content”). Accion does not screen, edit, publish, or
                review User Content prior to its appearance on Ovante India,
                except in limited circumstances we may modify or adapt certain
                forms of User Content in order conform to any media
                requirements. User Content does not reflect the views or
                opinions of Accion and Accion does not endorse, support,
                represent or guarantee the completeness, truthfulness, accuracy,
                or reliability of any User Content posted or appearing on Ovante
                India. To the extent permitted by applicable laws, Accion shall
                not be responsible or liable for User Content or for any lost
                cost, liability, damages, or expenses causes or suffered as a
                result of any use, posting, or appearance of the User Content on
                Ovante India. Any use or reliance on any User Content is at your
                own risk.
              </p>
              <p>
                You agree not to post User Content that: (i) may create a risk
                of harm, loss, physical or mental injury, emotional distress,
                death, disability, disfigurement, or physical or mental illness
                to you, to any other person, or to any animal; (ii) may create a
                risk of any other loss or damage to any person or property;
                (iii) seeks to harm or exploit children by exposing them to
                inappropriate content, asking for personally identifiable
                details or otherwise; (iv) may constitute or contribute to a
                crime or tort; (v) contains any information or content that we
                deem to be unlawful, harmful, abusive, racially or ethnically
                offensive, defamatory, infringing, invasive of personal privacy
                or publicity rights, harassing, humiliating to other people
                (publicly or otherwise), libelous, threatening, profane, or
                otherwise objectionable; (vi) contains any information or
                content that is illegal; (vii) contains any information or
                content that you do not have a right to make available under any
                law or under contractual or fiduciary relationships; (viii)
                violates third- party rights of any kind, including without
                limitation any patents, copyrights, trademarks, or other
                intellectual property rights, and the rights of privacy; (ix)
                contains any software virus or other malware; (x) includes any
                commercial or political solicitation; (xi) includes any
                advertisement, pyramid scheme, or other spam; (xii) and/or is
                false, deceptive, or misleading.
              </p>
              <p>
                Accion reserves all rights to monitor, record, edit, refuse to
                distribute, or remove any User Content on the Platform or to
                suspend or terminate any user account for any reason, including
                violation of these Terms, without any liability.
              </p>
              <p>
                You retain your right to any User Content you post or submit to
                the Platform. By submitting User Content to the Platform, you
                automatically grant Accion a worldwide, perpetual, irrevocable,
                non-exclusive, fully- transferable, royalty-free license (with a
                right to sub-license) to use, copy, adapt, publish, translate,
                distribute, publicly display, edit, create derivative works
                from, and reproduce any User Content in any and all forms,
                format, platforms, or media (now known or later developed). You
                represent that you have all necessary rights to distribute User
                Content via the Ovante India Platform, either because you are
                the author of the User Content and have the right to distribute
                the same, or because you have the appropriate distribution
                rights, licenses, consents, and/or permission to use, in
                writing, from the other owner of the User Content.
              </p>
              <h2>OVANTE INDIA REWARDS</h2>
              <p>
                Accion may offer you the opportunity to obtain points and redeem
                these points for rewards and benefits, as updated from time to
                time on the Platform. These points are provided to you without
                charge on a promotional basis, carry no cash value, and are not
                redeemable for cash. Accion may, in its sole discretion,
                restrict your acquisition, use, awards, offers, and redemption
                of points in any manner. We reserve the right to change the way
                you may acquire points, to change the program benefits, and to
                discontinue the rewards and points system at any time, with or
                without notice. This means that the accumulation of points does
                not entitle you to any vested rights with respect to such
                points, awards, or program benefits, unless or until these
                points are actually redeemed.
              </p>
              <p>
                To access these points, you must be a registered user of Ovante
                India and comply with these Terms of Use. Once you have
                accumulated the necessary points to redeem a reward or benefit,
                as described in the Platform, instructions will be provided
                through the Platform giving you the opportunity to redeem these
                points for a reward or benefit. You must follow the instructions
                to redeem the benefit before the points, benefits, and rewards
                expire.
              </p>
              <p>
                These points, benefits, and rewards are nontransferable. The
                points and reward program are not targeted towards, nor is it
                intended for use by, anyone under the age of 13. Children under
                13 may not participate in the points and reward program. If you
                are between the ages of 13 and 18 you may only participate in
                the points and rewards program under direct supervision of a
                parent or legal guardian who also agrees to be bound by these
                Terms.
              </p>
              <p>
                You will be responsible for any tax, levy, or other charge that
                may arise under any applicable law from the distribution of
                these rewards or benefits. You acknowledge that you will not be
                entitled to demand any additional payment by reason of any
                reward or benefit being subject to any tax, levy, or other
                charge in any jurisdiction.
              </p>
              <p>
                The name of groups, companies, products, or services offered as
                a reward or benefit does not imply any association with or any
                endorsement by us.
              </p>
              <h2>DISCLAIMERS</h2>
              <p>
                You agree that the use of or linking to this Platform or any
                Services is at your sole risk. To the maximum extent permitted
                by applicable law, Accion, its successors, and its affiliates
                disclaim all representations and warranties, whether express or
                implied, including but not limited to fitness for a particular
                purpose and/or the use of reasonable care and skill, relating to
                our Platform and Services.
              </p>
              <p>
                We make no representations that the Ovante Content or the
                Services are appropriate or that they may be used or downloaded
                outside of the United States. Access to the Services may not be
                legal in certain countries outside of the United States. If you
                access the Services from outside the United States, you do so at
                your own risk and are responsible for compliance with the laws
                of the jurisdiction from which you access this Platform.
              </p>
              <h2>LIMITATION OF LIABILITY</h2>
              <p>
                In no event shall Accion, nor any of its officers, directors,
                employees, volunteers, agents, licensors, and suppliers be held
                liable for any direct or indirect, consequential, or special
                damages, claims or liability arising out of or in any way
                connected with your use of this Platform or Services or any
                associated information, products, and services.
              </p>
              <h2>PERSONAL INFORMATION WE MAY COLLECT FROM YOU</h2>
              <p>
                For the purposes of these Terms, “Personal information” means
                any information that relates to an individual, which, either
                directly or indirectly or in combination with other information
                that is or may be available to an entity and is capable of
                identifying such individual. “Sensitive personal information”
                includes personal information such as passwords, financial
                information such as bank account or credit card or debit card or
                other payment instrument details.
              </p>
              <p className="pb-0">Accion may directly collect from you:</p>
              <ol className="type-alpha">
                <li>
                  Any information you provide us when you sign into or interact
                  with our Platform, use the Services, sign up for periodic
                  updates, or engage with us in any other way, we may collect
                  certain personal information or sensitive personal information
                  from you, including your usage information (such as the time
                  spent in the Platform or programs, number of programs
                  completed), profile information, demographic information,
                  education level, information you provide about your business
                  (such as sales range, type of business), , name, and phone
                  number.
                </li>
                <li>
                  Information that you might enter in forms and the “Stories”
                  section.
                </li>
                <li>
                  Statistics pertaining to users’ actions and behaviour while
                  using the Platform.
                </li>
                <li>
                  Usage and information generated from device features such as
                  camera, audio recording (for example, when you record a video
                  story or leave an audio comment), and internet connectivity.
                </li>
                <li>
                  Any information you may provide to obtain customer support
                  services, including your name, phone number, location, device
                  properties (IP, mobile device or desktop, and operating
                  system), as well as the content of your messages directed to
                  Ovante India.
                </li>
                <li>
                  If you use your social media profile(s) (e.g., Facebook) to
                  login to the Platform and grant permission, we may also
                  collect your email address and phone number.
                </li>
              </ol>
              <h2>COOKIES</h2>
              <p>
                We may employ the use of cookies. By using this Platform, you
                consent to the use of cookies in accordance with{" "}
                <a
                  href="https://www.accion.org/Privacy-and-Security-Policy"
                  target="_blank"
                >
                  Accion’s Privacy Policy
                </a>
                .
              </p>
              <h2>PURPOSE OF COLLECTION AND SHARING</h2>
              <p>
                We use this information for a number of purposes and activities
                including to provide you with the Services, to send you periodic
                communication and information, to resolve any technical issues
                and improve the overall quality of the Platform and/or Services.
              </p>
              <p>
                We may disclose this information to third parties, including our
                affiliates, partners, consultants, vendors, and service
                providers subject to applicable confidentiality and data
                protection obligations, if (i) we are working with them to
                provide Services, (ii) we have a duty to disclose or share your
                information, (iii) to comply with any legal obligations, and
                (iv) to enforce and comply with these Terms and other
                agreements.
              </p>
              <h2>PRIVACY POLICY</h2>
              <p>
                Please refer to{" "}
                <a
                  href="https://www.accion.org/Privacy-and-Security-Policy"
                  target="_blank"
                >
                  Accion’s Privacy Policy
                </a>
                , which is incorporated into these Terms by reference, for
                additional information on how we collect, use, store, disclose
                and protect your information when you visit our Platform or use
                the Services.
              </p>
              <h2>GRIEVANCE OFFICER</h2>
              <p>
                By using the Platform you consent to Accion’s collection of this
                information. If you do not consent, wish to withdraw your
                consent, or have a complaint, please contact our Grievance
                Officer at:
              </p>
              <p className="pb-0">Archana Kejriwal</p>
              <p className="pb-0">
                No. 9/3, 1st Floor, Kaiser-e-Hind Richmond Road Bangalore KA
                560025
              </p>
              <p>
                <a href="mailto:support@ovante.in">support@ovante.in</a>
              </p>
              <h2>CHOICE OF LAW</h2>
              <p>
                You agree that the laws of the state of New York, without regard
                to principles of conflict of laws, will govern these Terms and
                any dispute of any sort that might arise between you and Accion.
              </p>
              <h2>CONTACTING US</h2>
              <p>
                If you have any questions about these Terms, you can contact us
                at <a href="mialto:legal@accion.org">legal@accion.org</a>.
              </p>
              <h2>UPDATES</h2>
              <p>
                Our Terms may be updated from time to time. Each time you access
                this Platform and/or use our Services, the current version of
                the Terms will apply.
              </p>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TermsOfUse;

import { IonContent, IonImg, IonPage, useIonRouter } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import Popup from "../../components/common/popup";
import RegisterHeader from "../../components/common/register-header";
import { ACCESS_TOKEN, USER_SETTINGS } from "../../config/appconfig";
import { AppContext } from "../../context/app.context";
import { hearaboutus, partnersList } from "../../services/auth.service";
import storageSevice from "../../services/storage.sevice";
import { changeSettings, saveProfile } from "../../services/user.service";
import Success from "../../assets/images/icons/success-icon.svg";
import PolygonIcon from "../../assets/images/icons/icon-polygon.svg";
import PhoneIcon from "../../assets/images/icons/icon-phone.svg";
import { getFormattedUrl } from "../../utils/common.helper";
import NOAIcon from "../../assets/images/NOA_icon.svg";
import "./style.scss";
import LanguageAppText from "../../components/languageapptext";
const HearAboutUs: React.FC = () => {
  const { language, toastMessage, setSettings }: any = useContext(AppContext);
  const history: any = useHistory();
  const { state }: any = useLocation();
  const fromPage: string = state.fromPage;
  const [partners, setPartners] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    fetchPartnerList();
  }, []);

  const fetchPartnerList = async () => {
    const resp: any = await partnersList();
    if (!resp.error) {
      const partners = resp;
      partners.push({
        id: 1,
        name: "None of the above",
      });
      setPartners(partners);
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  const handlePartnerSelection = async (id: number) => {
    const numbers = JSON.parse(state.phoneNumbers);
    const userData: any = {
      partnerId: id,
    };
    numbers.phoneNumber1 && (userData["phoneNumber1"] = numbers.phoneNumber1);
    numbers.phoneNumber2 && (userData["phoneNumber2"] = numbers.phoneNumber2);
    numbers.phoneNumber3 && (userData["phoneNumber3"] = numbers.phoneNumber3);
    const resp: any = await saveProfile(userData);
    if (!resp.error) {
      const settingData = {
        notifications: resp.settings.notifications,
        languageCode: language,
      };
      await changeSettings(settingData);
      await storageSevice.set(USER_SETTINGS, JSON.stringify(settingData));
      setSettings(settingData);
      setShowSuccess(true);
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  const handleSuccessClick = async () => {
    setShowSuccess(true);
    history.replace({
      pathname: "/accountsecurity",
      state: {
        fromPage: fromPage,
      },
    });
  };

  const renderPartners = () => {
    return partners.map((item: any, index: number) => {
      return (
        <div
          key={item.id}
          className="page-content__whitebox"
          onClick={() => {
            handlePartnerSelection(item.id);
          }}
        >
          {item.id === 1 ? (
            <IonImg className="whitebox__socialicon" src={NOAIcon} />
          ) : (
            item.icon && (
              <IonImg
                className="whitebox__socialicon"
                src={getFormattedUrl(item.icon.uri)}
              />
            )
          )}

          <p className="whitebox__content">{item.name}</p>
          <IonImg src={PolygonIcon} className="whitebox__right-arrow" />
        </div>
      );
    });
  };

  return (
    <IonPage className="page-hearaboutus">
      <RegisterHeader
        pageName="Create your account"
        textKey={"create_your_acc"}
        backBtn={false}
      ></RegisterHeader>
      <IonContent fullscreen className="page-content">
        <LanguageAppText
          tag={"h1"}
          className="page-content__title p0"
          value={"How did you hear about Ovante?"}
          textKey={"hear_about_ovante"}
        />
        <div className="page-content page-content__marginTop">
          {renderPartners()}
        </div>
        {showSuccess && (
          <Popup>
            <IonImg src={Success} className="ion-popup__success-icon" />
            <LanguageAppText
              tag={"h1"}
              className="ion-popup__success-text m0 p0"
              value={"Success!"}
              textKey={"success"}
            />
            <LanguageAppText
              tag={"p"}
              className="ion-popup__text m0 p0"
              value={"You have been registered on Ovante."}
              textKey={"registered_success"}
            />
            <ButtonComponent
              name="Get started"
              textKey={"button:get_started"}
              onClick={handleSuccessClick}
            ></ButtonComponent>
          </Popup>
        )}
      </IonContent>
    </IonPage>
  );
};

export default HearAboutUs;

import { IonInput, IonItem, IonLabel } from "@ionic/react";
import React, { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../context/app.context";
import {
  getActivityMetaData,
  stringInject,
} from "../../../utils/common.helper";
import "./style.scss";
import { Plugins, KeyboardInfo } from "@capacitor/core";
const { Keyboard } = Plugins;
interface Props {
  value: any;
  name: string;
  label?: string;
  placeholder?: string;
  type?: any;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (val: string) => void;
  onClick?: any;
  maxlength?: any;
  prefix?: string;
  inputMode?: any;
  customClass?: string;
  multiple?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  labelKey?: any;
  placeholderKey?: any;
  metaData?: any;
  focusable?: boolean;
}

const InputText: React.FC<Props> = ({
  // focusInput,
  value,
  name,
  label,
  placeholder,
  type,
  disabled,
  readonly,
  onChange,
  onClick,
  maxlength,
  prefix,
  inputMode,
  customClass,
  multiple,
  onBlur,
  onFocus,
  labelKey,
  placeholderKey,
  metaData,
  focusable,
}) => {
  const inputRef: any = useRef<HTMLInputElement>(null);
  const { language, descEnFile, descFile }: any = useContext(AppContext);
  let labelText: any = label;
  let placeholderText: any = placeholder;
  if (label && labelKey) {
    labelText =
      descFile[labelKey.toLowerCase()] ||
      descEnFile[labelKey.toLowerCase()] ||
      label;
    if (metaData) {
      const formattedData = getActivityMetaData(metaData, labelText, language);
      labelText = stringInject(labelText, formattedData);
    }
  }

  useEffect(() => {
    disableInputScroll();
  }, []);
  useEffect(() => {
    if (focusable) {
      setFocus();
    }
  }, [focusable]);

  const disableInputScroll = async () => {
    if (type === "number") {
      const temp = await inputRef.current.getInputElement();
      temp.addEventListener("wheel", (e: any) => {
        e.preventDefault();
      });
    }
  };

  const setFocus = async () => {
    const temp = await inputRef.current.getInputElement();
    temp.focus();
  };

  if (placeholderKey) {
    placeholderText =
      descFile[placeholderKey.toLowerCase()] ||
      descEnFile[placeholderKey.toLowerCase()] ||
      placeholder;
    if (metaData) {
      const formattedData = getActivityMetaData(
        metaData,
        placeholderText,
        language
      );
      placeholderText = stringInject(placeholderText, formattedData);
    }
  }

  const validateNumberOnly = (e: any) => {
    if (e.which < 0x20) {
      return; // Do nothing
    }
    if (e.target.value.length == maxlength) {
      e.preventDefault();
    } else if (e.target.value.length > maxlength) {
      // Maximum exceeded
      e.target.value = e.target.value.substring(0, maxlength);
    }
  };

  return (
    <IonItem className="ion-item item-label-stacked">
      {label && <IonLabel className="ion-item__label">{labelText}</IonLabel>}
      {prefix && <span className="ion-item__prefix">{prefix}</span>}
      <IonInput
        ref={inputRef}
        className={`${prefix ? "ion-item__input-prefix" : "ion-item__input"} ${
          customClass || ""
        } noscroll`}
        autocorrect={"off"}
        autocomplete={"off"}
        // className="ion-item__input"
        value={value}
        name={name}
        inputmode={inputMode || "text"}
        type={type || "text"}
        placeholder={placeholderText || ""}
        disabled={disabled}
        readonly={readonly}
        onIonChange={(e) => {
          onChange && onChange(e.detail.value || "");
        }}
        onClick={() => {
          onClick && onClick();
        }}
        maxlength={maxlength}
        onKeyPress={validateNumberOnly}
        multiple={multiple}
        onFocus={() => {
          onFocus && onFocus();
        }}
        // onKeyUp={(e: any) => {
        // if (e?.key === "Enter" || e?.key == "Unidentified") {
        // Keyboard.hide();
        // }
        // }}
        onBlur={() => {
          onBlur && onBlur();
        }}
      ></IonInput>
    </IonItem>
  );
};

export default InputText;

import {
  IonApp,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  useIonToast,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import "./theme/styles/main.scss";

import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./context/app.context";
import { OnboardingProvider } from "./context/onboarding.contex";
import AccountSecurity from "./pages/accountsecurity";
import Activity from "./pages/activity";
import ActivityComplete from "./pages/activitycomplete";
import ActivityRewards from "./pages/activityrewards";
import BusinessType from "./pages/businesstype";
import ChangeNumbers from "./pages/changenumbers";
import ChangePreferredNumber from "./pages/changeprefferednumber";
import ConfirmOTP from "./pages/confirmotp";
import CreateAccount from "./pages/createaccount";
import DiagnosticQuiz from "./pages/diagnostic";
import EditProfile from "./pages/editprofile";
import HearAboutUs from "./pages/hearaboutus";
import HelpCenter from "./pages/helpcenter";
import Learnings from "./pages/learnings";
import LoginWithPhone from "./pages/loginwithphone";
import MyRewards from "./pages/myrewards";
import MyStories from "./pages/mystories";
import Notifications from "./pages/notification";
import OtherNumbers from "./pages/othernumbers";
import PrefferedNumber from "./pages/prefferednumber";
import Profile from "./pages/profile";
import ProfileSettings from "./pages/profilesettings";
import ProgramIntro from "./pages/programintro";
import RedeemedRewards from "./pages/redeemedreward";
import Register from "./pages/register";
import ReviewProgram from "./pages/reviewprogram";
import RewardDetails from "./pages/rewarddetails";
import RewardRedeemInfo from "./pages/rewardredeeminfo";
import StartQuiz from "./pages/startquiz";
import Tabs from "./pages/tabs";
import Rewards from "./pages/tabs/rewards";
import TermsOfUse from "./pages/terms-of-use";
import TermsOfUseHindi from "./pages/terms-of-use-hindi";
import TermsAndCondition from "./pages/termsandconditions";
import TOCDetails from "./pages/tocdetails";
import ToDo from "./pages/todo";
import Topic from "./pages/topic";
import YourBadges from "./pages/yourbadges";

import { Plugins } from "@capacitor/core";
import Popup from "./components/common/popup";
import Reward from "./components/reward";
import CommunityStory from "./pages/communitystory";
import Congratulation from "./pages/congratulation";
import DeleteAccount from "./pages/deleteaccount";
import DiagnosticQuestionary from "./pages/diagnosticquestionary";
import DistrictList from "./pages/districtlist";
import Introduction from "./pages/introduction";
import LeaderBoard from "./pages/leaderboard";
import NetworkError from "./pages/networkerror";
import RecorderVideo from "./pages/recordedvideo";
import ReviewStory from "./pages/reviewstory";
import Splash from "./pages/splash";
import StateList from "./pages/statelist";
import SubmitStory from "./pages/submitstory";
import Experts from "./pages/tabs/experts";
import {
  isConnectedToInternet,
  networkCheckInit,
} from "./services/network.service";
import ObserverService from "./services/observer.service";
import { getLanguages } from "./services/app.service";
import { changeSettings } from "./services/user.service";
import storageSevice from "./services/storage.sevice";
import { USER_SETTINGS } from "./config/appconfig";
import BottomDrawer from "./components/bottomdrawer";
import BackDrop from "./components/common/backdrop";
import { getLangDescriptor } from "./services/lang-descriptor.service";

const { SplashScreen } = Plugins;
// const { App } = Plugins;
let isShowRewrd = false;
let observer: any = null;
let languages: any = [];
const App: React.FC = (props: any) => {
  const {
    descEnFile,
    descFile,
    toastMessage,
    lanChange,
    setSettings,
    language,
    languagesList,
    getDescriptorFile,
  }: any = useContext(AppContext);
  const [present, dismiss] = useIonToast();
  const [showReward, setShowReward] = useState(false);
  const [rewardData, setRewardData] = useState(null);
  // const [languages, setLanguages] = useState([]);
  const [isBackDropOpen, setIsBackDropOpen] = useState(false);
  const [languageSetting, setLanguageSetting] = useState(language);
  const [isBackDropVisible, setIsBackDropVisible] = useState(false);

  useEffect(() => {
    languages = languagesList;
  }, [languagesList]);

  useEffect(() => {
    networkCheckInit(present, dismiss, descEnFile, descFile);
  }, [descEnFile, descFile]);

  useEffect(() => {
    SplashScreen.hide();
    // fetchLangauages();
    //initialize NetworkService
    networkCheckInit(present, dismiss, descEnFile, descFile);

    // check network on start
    isConnectedToInternet();

    observer = ObserverService.observable.subscribe((value: any) => {
      if (value) {
        if (value.type == "change-lang") {
          handleLanguageChangeClick();
        } else if (value.type == "reward") {
          if (value.data.show) {
            setRewardData(value.data.data);
            setShowReward(true);
            isShowRewrd = true;
            document.addEventListener("ionBackButton", handleBack);
          } else {
            setShowReward(false);
            setRewardData(null);
            isShowRewrd = false;
            document.removeEventListener("ionBackButton", handleBack);
          }
        }
      }
    });
    return () => {
      if (observer) {
        observer.unsubscribe();
        observer = null;
      }
      document.removeEventListener("ionBackButton", handleBack);
      isShowRewrd = false;
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(102, () => {
      if (isShowRewrd) {
        setShowReward(false);
        setRewardData(null);
        isShowRewrd = false;
        document.removeEventListener("ionBackButton", handleBack);
      }
    });
  };

  const fetchLatestLangDescriptor = async (language: any) => {
    await getLangDescriptor(language);
    await getDescriptorFile(language);
    // window.location.reload();
  };

  const postSettings = async (langauge?: any) => {
    const data = {
      notifications: null,
      languageCode: langauge == null ? languageSetting : langauge,
    };
    const resp: any = await changeSettings(data);
    if (!resp.error) {
      lanChange(langauge == null ? languageSetting : langauge);
      setSettings(data);
      await storageSevice.set(USER_SETTINGS, JSON.stringify(data));
    } else {
      toastMessage(resp.message, "primary");
    }
  };

  const handleLanguageChangeClick = () => {
    if (languages.length > 2) {
      ObserverService.handleEvents("language", { state: "open" });
      setIsBackDropOpen(true);
    } else if (languages.length == 2) {
      let temp: any = [...languages];
      const index = temp.findIndex((val: any) => val.code == languageSetting);
      temp.splice(index, 1);
      setLanguageSetting(temp[0].code);
      postSettings(temp[0].code);
      toastMessage("Language changed to " + temp[0].title, "success");
    }
  };

  const handleBackDrop = (state: boolean) => {
    setIsBackDropVisible(state);
  };

  const handleLanguageSelect = (e: any) => {
    if (isBackDropOpen) {
      ObserverService.handleEvents("language", { state: "close" });
      setIsBackDropOpen(false);
    }
    if (languageSetting != e.detail.value) {
      fetchLatestLangDescriptor(e.detail.value);
      setLanguageSetting(e.detail.value);
      postSettings(e.detail.value);
    }
  };

  const renderLanguageItems = () => {
    return languages.map((item: any, index: any) => {
      return (
        <IonItem key={item.name}>
          <IonLabel>{item.title}</IonLabel>
          <IonRadio slot="end" value={item.code} />
        </IonItem>
      );
    });
  };

  const renderLanguages = () => {
    return (
      <IonList className="ion-list">
        <IonRadioGroup
          className="ion-radio-group"
          value={languageSetting}
          onIonChange={handleLanguageSelect}
        >
          {renderLanguageItems()}
        </IonRadioGroup>
      </IonList>
    );
  };

  return (
    <AppContext.Consumer>
      {(consumer: any) => {
        return (
          <>
            <IonApp>
              <IonReactRouter>
                <Route path="/networkerror">
                  <NetworkError />
                </Route>
                <Route path="/introduction">
                  <Introduction />
                </Route>
                <Route path="/register">
                  <Register />
                </Route>
                <Route path="/loginwithphone">
                  <LoginWithPhone />
                </Route>
                <Route path="/confirmotp">
                  <ConfirmOTP />
                </Route>
                <Route path="/createaccount">
                  <CreateAccount />
                </Route>
                <Route path="/othernumbers">
                  <OtherNumbers />
                </Route>
                <Route path="/prefferednumber">
                  <PrefferedNumber />
                </Route>
                <Route path="/accountsecurity">
                  <AccountSecurity />
                </Route>
                <Route path="/hearaboutus">
                  <HearAboutUs />
                </Route>
                <Route path="/learnings/:id">
                  <Learnings />
                </Route>
                <Route path="/changenumbers">
                  <ChangeNumbers />
                </Route>
                <Route path="/userprofile">
                  <Profile />
                </Route>
                <Route path="/leaderboard">
                  <LeaderBoard />
                </Route>
                <Route exact path="/topic/:id">
                  <Topic />
                </Route>
                <Route exact path="/yourbadges">
                  <YourBadges />
                </Route>
                <Route exact path="/review/:type/:id">
                  <ReviewProgram />
                </Route>
                <Route exact path="/activityrewards">
                  <ActivityRewards />
                </Route>
                <Route exact path="/tocdetails">
                  <TOCDetails />
                </Route>
                <Route exact path="/mystories">
                  <MyStories />
                </Route>
                <Route exact path="/helpcenter">
                  <HelpCenter />
                </Route>
                <Route exact path="/myrewards">
                  <MyRewards />
                </Route>
                <Route exact path="/activitycomplete">
                  <ActivityComplete />
                </Route>
                <Route exact path="/termsandconditions">
                  <TermsAndCondition />
                </Route>
                <Route exact path="/terms-of-use">
                  <TermsOfUse />
                </Route>
                <Route exact path="/terms-of-use-hindi">
                  <TermsOfUseHindi />
                </Route>
                <Route exact path="/activity/:id">
                  <Activity />
                </Route>
                <Route exact path="/settings">
                  <ProfileSettings />
                </Route>
                <Route exact path="/changeprefferednumber">
                  <ChangePreferredNumber />
                </Route>
                <Route exact path="/programintro">
                  <ProgramIntro />
                </Route>
                <Route exact path="/notification">
                  <Notifications />
                </Route>
                <Route path="/tabs">
                  <Tabs />
                </Route>
                <Route exact path="/todo">
                  <ToDo />
                </Route>
                <Route exact path="/communitystory/:id">
                  <CommunityStory />
                </Route>
                <Route exact path="/recordedvideo">
                  <RecorderVideo />
                </Route>
                <Route exact path="/submitstory">
                  <SubmitStory />
                </Route>
                <OnboardingProvider>
                  <Route exact path="/onboarding/businesstype">
                    <BusinessType />
                  </Route>
                  <Route exact path="/onboarding/state">
                    <StateList />
                  </Route>
                  <Route exact path="/onboarding/district">
                    <DistrictList />
                  </Route>
                  <Route exact path="/onboarding/diagnostic">
                    <DiagnosticQuiz />
                  </Route>
                  <Route exact path="/onboarding">
                    <StartQuiz />
                  </Route>
                  <Route exact path="/onboarding/diagnosticquestionary">
                    <DiagnosticQuestionary />
                  </Route>
                  <Route exact path="/editprofile">
                    <EditProfile />
                  </Route>
                </OnboardingProvider>
                <Route exact path="/todo">
                  <ToDo />
                </Route>
                <Route exact path="/rewards">
                  <Rewards />
                </Route>
                <Route exact path="/experts">
                  <Experts />
                </Route>
                <Route exact path="/congratulation">
                  <Congratulation />
                </Route>
                <Route exact path="/rewarddetails/:type/:id">
                  <RewardDetails />
                </Route>
                <Route exact path="/redeemedreward">
                  <RedeemedRewards />
                </Route>
                <Route exact path="/rewardinfo/:type/:id">
                  <RewardRedeemInfo />
                </Route>
                <Route exact path="/reviewStory">
                  <ReviewStory />
                </Route>
                <Route exact path="/splash">
                  <Splash />
                </Route>
                <Route exact path="/deleteaccount">
                  <DeleteAccount />
                </Route>
                <Route exact path="/">
                  <Redirect to={"/splash"} />
                </Route>
                {showReward && (
                  <Popup
                    customClass="tools-popup"
                    onClose={() => {
                      ObserverService.handleEvents("reward", { show: false });
                    }}
                  >
                    <Reward data={rewardData} />
                  </Popup>
                )}
                {/* <BackDrop
                  backdropVisible={isBackDropVisible}
                  isOutsideClick={true}
                ></BackDrop> */}
                {languages.length > 2 && (
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      zIndex: 101,
                      width: "100%",
                    }}
                  >
                    <BottomDrawer
                      type={"language"}
                      hideBackDrop={handleBackDrop}
                      backBtnText={"Change language"}
                    >
                      {renderLanguages()}
                    </BottomDrawer>
                  </div>
                )}
              </IonReactRouter>
            </IonApp>
          </>
        );
      }}
    </AppContext.Consumer>
  );
};
export default App;

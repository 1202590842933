import { IonContent, IonImg, IonPage, IonRange } from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import CheckBox from "../../components/common/checkbox";
import Loader from "../../components/common/loader";
import RadioGroup from "../../components/common/radiogroup";
import StatusClose from "../../assets/images/icons/icon-close.svg";
import "./style.scss";
import {
  getRecommendation,
  getRecommendationQuestions,
  postRecommendation,
  updateUser,
  userProfile,
} from "../../services/user.service";
import { AppContext } from "../../context/app.context";
import Messages from "../../config/messages";
import LanguageAppText from "../../components/languageapptext";
let answerData: any = [];
const DiagnosticQuestionary = () => {
  const history: any = useHistory();
  const { toastMessage, setUserDetails }: any = useContext(AppContext);
  const [questionData, setQuestionData]: any = useState(null);
  const [questionList, setQuestionList]: any = useState([]);
  const [loading, setLoading]: any = useState(true);
  const [currentIndex, setCurrentIndex]: any = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isAlmostDone, setisAlmostDone] = useState(false);
  const [isSubmitting, setIsSubmitting]: any = useState(false);
  useEffect(() => {
    getData();
    return () => {
      answerData = [];
    };
  }, []);

  const getData = async () => {
    const resp: any = await getRecommendationQuestions();
    const ansResp: any = await getRecommendation();
    if (resp && !resp.error && ansResp && !ansResp.error) {
      let ansData: any = null;
      try {
        ansData = JSON.parse(ansResp.response);
        if (ansData && ansData.length > 0) {
          ansData = ansData.filter((item: any) => {
            return resp.some(function (arrVal: any) {
              return item.qusId === arrVal.id;
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
      if (ansData && resp.length === ansData.length) {
        const userResp: any = await userProfile();
        if (!userResp.error) {
          setUserDetails(userResp);
        }
        history.replace({
          pathname: `/experts`,
        });
        return;
      }
      let currentIndex = -1;
      resp.map((resItem: any, index: number) => {
        if (ansData && ansData.length > 0) {
          let checkIndex = ansData.findIndex((item: any) => {
            return item.qusId === resItem.id;
          });
          if (checkIndex != -1) {
            const checkItem = ansData[checkIndex];
            answerData.push(checkItem);
            // ansData.map((ansItem: any, ansIndex: number) => {
            //   let tempAnsData = [];
            //   let dataIndex = answerData.findIndex((item: any) => {
            //     return item.qusId === resItem.id;
            //   });
            //   if (resItem.type === "checkbox") {
            //     if (dataIndex != -1) {
            //       tempAnsData = answerData[dataIndex].ans;
            //       tempAnsData.concat(ansItem.ans);
            //       answerData[dataIndex].ans = tempAnsData;
            //     } else {
            //       answerData.push({
            //         qusId: resItem.id,
            //         ans: ansItem.ans,
            //       });
            //     }
            //   } else {
            //     answerData.push({
            //       qusId: resItem.id,
            //       ans: ansItem.ans,
            //     });
            //   }
            // });
          } else {
            if (currentIndex === -1) {
              currentIndex = index;
            }
          }
        } else {
          if (currentIndex === -1) {
            currentIndex = index;
          }
        }
      });
      // console.log("get", answerData);

      setQuestionList(resp);
      setQuestionData(resp[currentIndex]);
      setCurrentIndex(currentIndex);
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }

    setLoading(false);
  };
  const onProceedClick = async () => {
    // console.log(answerData);
    setIsSubmitting(true);
    const resp: any = await postRecommendation({ recommendation: answerData });
    setIsSubmitting(false);
    if (!resp.error) {
      if (questionList.length === currentIndex + 1) {
        await updateUser({
          diagnosticQuiz: true,
        });
        const userResp: any = await userProfile();
        if (!userResp.error) {
          setUserDetails(userResp);
        }
        answerData = [];
        history.replace({
          pathname: `/experts`,
        });
        return;
      }
      setCurrentIndex(currentIndex + 1);
      setQuestionData(questionList[currentIndex + 1]);
      setBtnDisabled(true);
      // answerData = [];
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
    // if (questionList.length === currentIndex + 1) {
    //   answerData = [];
    //   history.replace({
    //     pathname: `/experts`,
    //   });
    //   return;
    // }
    // setCurrentIndex(currentIndex + 1);
    // setQuestionData(questionList[currentIndex + 1]);
    // setBtnDisabled(true);
  };

  const onCheckBoxOptionClick = (checked: boolean, item: any) => {
    let tempAnsData = [];
    let dataIndex = answerData.findIndex((item: any) => {
      return item.qusId === questionData.id;
    });

    if (dataIndex != -1) {
      tempAnsData = answerData[dataIndex].ans;
    }
    if (checked) {
      if (tempAnsData.length > 0) {
        tempAnsData.push(item);
        answerData[dataIndex].ans = tempAnsData;
      } else {
        let tempData = {
          qusId: questionData.id,
          ans: [item],
        };
        answerData.push(tempData);
      }
      setBtnDisabled(false);
    } else {
      const removeIndex = tempAnsData.findIndex((ansItem: any) => {
        return ansItem.id === item.id;
      });
      if (removeIndex != -1) {
        tempAnsData.splice(removeIndex, 1);
        if (tempAnsData.length === 0) {
          answerData.splice(dataIndex, 1);
        } else {
          answerData[dataIndex].ans = tempAnsData;
        }
      }
      setBtnDisabled(tempAnsData.length === 0);
    }
    // console.log(answerData);
    const tempData = questionData;
    const temp = tempData.options.map((optionItem: any, index: number) => {
      if (optionItem.id === item.id) {
        optionItem.checked = checked;
      }
      return optionItem;
    });
    tempData.options = temp;
    setQuestionData(tempData);
  };

  const onRadioOptionClick = (value: any) => {
    let dataIndex = answerData.findIndex((item: any) => {
      return item.qusId === questionData.id;
    });

    const itemIndex = questionData.options.findIndex((item: any) => {
      return item.id === value;
    });
    if (itemIndex != -1) {
      if (dataIndex != -1) {
        answerData[dataIndex].ans = [];
        answerData[dataIndex].ans = [questionData.options[itemIndex]];
      } else {
        let tempData = {
          qusId: questionData.id,
          ans: [questionData.options[itemIndex]],
        };
        answerData.push(tempData);
      }
    }
    // console.log(answerData);

    setBtnDisabled(false);
  };
  return (
    <>
      <IonPage className="page-diagnostic-question">
        <IonContent fullscreen={true} className="page-content">
          {loading ? (
            <Loader customClass="loader-page-center"></Loader>
          ) : questionData ? (
            <>
              <div className="diagnostic-question__header stretch-container">
                <IonRange
                  min={0}
                  max={questionList.length + 1}
                  className="page-content__range"
                  value={currentIndex + 1}
                ></IonRange>
                <h1 className="header-title">{questionData.question}</h1>
              </div>
              <div className="diagnostic-questions__container">
                {questionData.type == "checkbox" ? (
                  questionData.options.map((item: any, index: number) => {
                    return (
                      <div className="mb-10" key={index}>
                        <CheckBox
                          key={item.value}
                          label={item.value}
                          isChecked={item.checked}
                          onClick={(e: any) => {
                            onCheckBoxOptionClick(e.target.checked, item);
                          }}
                        />
                      </div>
                    );
                  })
                ) : (
                  <RadioGroup
                    key={questionData.id}
                    optionsList={questionData.options}
                    onValueChange={(value: any) => {
                      onRadioOptionClick(value);
                    }}
                  />
                )}
                {isAlmostDone && (
                  <div className="questions-status bg-white mb-15">
                    <LanguageAppText
                      tag={"div"}
                      className="questions-status-text text-green font-medium"
                      value={"You’re almost done! Keep going"}
                      textKey={"keep_going"}
                    />
                    <IonImg
                      src={StatusClose}
                      className="questions-status-img"
                    />
                  </div>
                )}
                <div className="diagnostic-questions__btn stretch-container bg-white">
                  {isSubmitting ? (
                    <Loader />
                  ) : (
                    <ButtonComponent
                      name="Proceed"
                      textKey={"proceed"}
                      isEnabled={btnDisabled}
                      onClick={onProceedClick}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <div></div>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};
export default DiagnosticQuestionary;

import { AndroidPermissions } from "@ionic-native/android-permissions";

export const checkPermission = async (permission: string) => {
  const resp = await AndroidPermissions.checkPermission(permission);
  return resp.hasPermission;
};

export const requestPermission = async (permission: string) => {
  const resp = await AndroidPermissions.requestPermission(permission);
  return resp.hasPermission;
};

import { IonCard, IonImg, IonItem, IonList, IonThumbnail } from "@ionic/react";
import React, { useContext } from "react";
import "./style.scss";
import Coins from "../../assets/images/icons/coins.svg";
import PlayOrange from "../../assets/images/icons/play-orange.svg";
import LanguageText from "../languagetext";
import LanguageImage from "../languageimage";
import { isConnectedToInternet } from "../../services/network.service";
import { Capacitor } from "@capacitor/core";
import { AppContext } from "../../context/app.context";
import { getActivityMetaData, stringInject } from "../../utils/common.helper";

interface Props {
  label: any;
  thumbnail: string;
  midIcon?: string;
  rightIcon?: string;
  localThumb?: string;
  midContent?: any;
}
const ExtraActivityCard: React.FC<Props> = ({
  label,
  thumbnail,
  midIcon,
  rightIcon,
  midContent,
  localThumb,
}) => {
  // const LargeHeader = () => {
  const { language, descEnFile, descFile }: any = useContext(AppContext);
  let desc: any = "";
  if (midContent) {
    desc =
      descFile["earn_activity_coin"] ||
      descEnFile["earn_activity_coin"] ||
      `Earn ${midContent} coins`;
    const formattedData = getActivityMetaData(
      { activityCoins: midContent },
      desc,
      language
    );
    desc = stringInject(desc, formattedData);
  }
  return (
    <IonCard className="ion-no-margin capsul-card capsul-card__extra-activities mb-20">
      <IonList className="ion-no-padding">
        <IonItem>
          <IonThumbnail
            slot="start"
            className="ion-no-padding ion-no-margin ion-margin-end"
          >
            {!isConnectedToInternet() && localThumb ? (
              <IonImg src={Capacitor.convertFileSrc(localThumb)} />
            ) : (
              <LanguageImage value={thumbnail} />
            )}
            {/* <IonImg
              src={`https://i.pinimg.com/originals/ee/29/4f/ee294fa3c5bcb26bfed60c79662f3b16.jpg`}
            /> */}
          </IonThumbnail>
          <div className="capsul-card__mid">
            <LanguageText
              tag={"div"}
              className="capsul-card__label"
              value={label}
            ></LanguageText>

            {midContent && (
              <p className="icon-text">
                <IonImg src={midIcon ? midIcon : Coins} />
                {desc}
              </p>
            )}
          </div>
          <div className="capsul-card__last">
            <img src={rightIcon ? rightIcon : PlayOrange} />
          </div>
        </IonItem>
      </IonList>
    </IonCard>
  );
};
export default ExtraActivityCard;

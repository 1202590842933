import "./style.scss";

import {
  IonButton,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ObserverService from "../../services/observer.service";
import {
  getFormattedUrl,
  handleOnValueChange,
} from "../../utils/common.helper";
import IntroImg from "../../assets/images/intro-img.svg";
import IntroODivice from "../../assets/images/intro-o-device.svg";
import LogoOvante from "../../assets/images/logo-ovante-intro.svg";
import ButtonComponent from "../../components/common/button-component";
import LangDownArrow from "../../assets/images/icons/icon-lang-down-arrow.svg";
import FormGroup from "../../components/common/formgroup";
import InputText from "../../components/common/inputtext";
import BottomDrawer from "../../components/bottomdrawer";
import BackDrop from "../../components/common/backdrop";
import VideoPlayer from "../../components/videoplayer";
import { getAppSlides, getLanguages } from "../../services/app.service";
import Loader from "../../components/common/loader";
import { AppContext } from "../../context/app.context";
import { useHistory } from "react-router";
import { getLangDescriptor } from "../../services/lang-descriptor.service";
import { Capacitor } from "@capacitor/core";
import LanguageAppText from "../../components/languageapptext";
import { number } from "yup";

interface Props {
  //formik: any;
  // language?: string;
}

const Introduction: React.FC<Props> = (
  {
    //formik,
    // language,
  }
) => {
  //const { values, errors, touched, setFieldValue } = formik;
  const { lanChange, getDescriptorFile }: any = useContext(AppContext);
  const [showNextButton, setshowNextButton] = useState(true);
  const [showGoButton, setshowGoButton] = useState(true);
  const [hidebottomContent, sethidebottomContent] = useState(false);
  const [isBackDropOpen, setIsBackDropOpen] = useState(false);
  const [isBackDropVisible, setIsBackDropVisible] = useState(false);
  const [language, setLanguage] = useState({
    title: "हिन्दी",
    name: "hindi",
    code: "hi",
  });
  const [slidesList, setSlidesList] = useState([]);
  const [introVideo, setIntroVideo]: any = useState(null);
  const [languageList, setLanguageList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState("");
  const [showVideo, setshowVideo] = useState(false);
  const [titleOpacity, setTitleOpacity] = useState(false);
  const LANGUAGE_TYPE = "lang";
  const mySlides: any = useRef(null);
  const mySlides01: any = useRef(null);
  const history = useHistory();

  useEffect(() => {
    Promise.all([fetchLangauages(), fetchAppSlides()]).finally(() => {
      setLoading(false);
    });
    fetchLangauages();
  }, []);

  useEffect(() => {
    fetchLatestLangDescriptor();
  }, [language]);

  const fetchLatestLangDescriptor = async () => {
    setLoading(true);
    await getLangDescriptor(language.code);
    await getDescriptorFile(language.code);
    setLoading(false);
  };
  const fetchLangauages = async () => {
    let resp: any = await getLanguages();
    if (!resp.error) {
      resp = resp.filter((item: any) => {
        return item.isActive;
      });
      setLanguageList(resp);
      setLanguage(resp[1]);
    }
  };

  const fetchAppSlides = async () => {
    const resp: any = await getAppSlides();
    if (!resp.error) {
      let slideArray: any = [];
      let video = null;
      resp.map((item: any) => {
        let parsed = JSON.parse(item.value);
        if (item.type == "slider") {
          item.value = parsed;
          slideArray.push(item);
        } else if (item.type == "video") {
          // video = parsed.video[language.code]
          //   ? parsed.video[language.code].uri
          //   : parsed.video["en"].uri;
          video = parsed.video;
        }
      });
      setSlidesList(slideArray);
      setIntroVideo(video);
    }
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    spaceBetween: 0,
  };

  const slideOptions = {
    initialSlide: 0,
    speed: 0,
    spaceBetween: 0,
  };

  const onBtnClicked = async (direction: string) => {
    const swiper = await mySlides.current.getSwiper();
    //const swiper01 = await mySlides01.current.getSwiper();
    if (direction === "next") {
      swiper.slideNext();
      // swiper01.slideNext();
    }
  };

  const removeGoBotton = () => {
    setshowNextButton(true);
  };
  const removeNextButton = () => {
    setshowNextButton(false);
  };
  const addFadeIn = () => {
    setTitleOpacity(true);
  };
  const removeFadeIn = () => {
    setTitleOpacity(false);
  };
  const skipVideo = () => {
    setshowVideo(false);
    setshowGoButton(false);
    sethidebottomContent(true);
    history.replace({
      pathname: `/register`,
    });
  };
  const backVideo = async () => {
    setshowVideo(false);
    setshowNextButton(true);
  };
  const handleBackDrop = (state: boolean) => {
    setIsBackDropVisible(state);
  };
  const handleLanguageClick = () => {
    setIsBackDropOpen(true);
    ObserverService.handleEvents(LANGUAGE_TYPE, { state: "open" });
  };
  const handleLanguageSelect = (e: any) => {
    const val = e.detail.value;
    if (isBackDropOpen) {
      setIsBackDropOpen(false);
      ObserverService.handleEvents(LANGUAGE_TYPE, { state: "close" });
    }
    // initialValues["language"] = val.toLowerCase();
    setLanguage(val);
    lanChange(val.code);
  };
  const selectLanguage = () => {
    return (
      <IonList className="ion-list">
        <IonRadioGroup
          className="ion-radio-group"
          value={language}
          onIonChange={handleLanguageSelect}
        >
          {languageList.map((lang: any, index: number) => {
            return (
              <IonItem key={index}>
                <IonLabel>{lang.title}</IonLabel>
                <IonRadio slot="end" value={lang} />
              </IonItem>
            );
          })}
        </IonRadioGroup>
      </IonList>
    );
  };

  return (
    <>
      <IonPage className="page-introduction">
        {loading ? (
          <Loader customClass="loader-page-center" />
        ) : (
          <IonContent fullscreen={true}>
            {!showVideo && (
              <div>
                {/* <IonImg src={IntroODivice} className="intro-o-device" />  */}
                <IonSlides
                  options={slideOpts}
                  pager={true}
                  ref={mySlides}
                  className={`${!hidebottomContent ? "" : "hideNextButton"}`}
                  onIonSlidePrevStart={removeGoBotton}
                  onIonSlideReachEnd={removeNextButton}
                  onIonSlideWillChange={addFadeIn}
                  onIonSlideDidChange={removeFadeIn}
                >
                  {slidesList.map((item: any, index: number) => {
                    return (
                      <IonSlide key={index}>
                        <div className="intro-top-images">
                          <IonImg
                            src={IntroODivice}
                            className="intro-o-device"
                          />
                          <IonImg
                            src={
                              item.value.image[language.code]
                                ? getFormattedUrl(
                                    item.value.image[language.code].uri
                                  )
                                : getFormattedUrl(item.value.image["en"].uri)
                            }
                            className="intro-top-img"
                          />
                        </div>
                        <div className="page-intro-bottom-conatiner">
                          {/* <IonImg src={LogoOvante} className="logo-ovante mb-15" /> */}
                          <div
                            className={`page-intro-title text-green font-bold mt-40 mb-10 ${
                              titleOpacity ? "fade-in-effect" : ""
                            }`}
                          >
                            {item.value.title[language.code] ||
                              item.value.title["en"]}
                          </div>
                          <div
                            className={`page-intro-desc text-blue font-medium mb-15 ${
                              titleOpacity ? "fade-in-effect" : ""
                            }`}
                          >
                            {item.value.description[language.code] ||
                              item.value.description["en"]}
                          </div>
                        </div>
                      </IonSlide>
                    );
                  })}
                </IonSlides>
                {hidebottomContent && (
                  <>
                    <div className="page-intro-title text-green font-bold mb-10">
                      Join our growing community
                    </div>
                    <div className="page-intro-desc text-blue font-medium mb-15">
                      Share stories and get the latest trends from over 5,000
                      people like you!
                    </div>
                  </>
                )}
                <ButtonComponent
                  name="Next"
                  textKey="next"
                  customClass={`intro-next-btn ${
                    showNextButton ? "" : "hideNextButton"
                  }`}
                  onClick={() => onBtnClicked("next")}
                />
                {!showNextButton && showGoButton && (
                  <ButtonComponent
                    name="Let’s go!"
                    textKey="lets_go"
                    customClass="intro-go-btn"
                    onClick={() => {
                      setshowVideo(true);
                    }}
                  />
                )}
                <form>
                  <FormGroup>
                    <div className="intro-lang-select">
                      <InputText
                        name=""
                        label=""
                        value={language.title}
                        customClass="font-bold"
                        onClick={() => {
                          handleLanguageClick && handleLanguageClick();
                        }}
                        // onChange={() => {
                        //   handleOnValueChange("lang", language, setFieldValue);
                        // }}
                        readonly
                      ></InputText>
                      <IonImg
                        src={LangDownArrow}
                        className="lang__down-arrow"
                      />
                    </div>
                  </FormGroup>
                </form>
              </div>
            )}
            {/* <IonContent fullscreen={true} className="page-content">
              {!showVideo && (
                <div className="page-intro-bottom-conatiner">
                  <IonImg src={LogoOvante} className="logo-ovante mb-15" />
                  <IonSlides
                    pager={true}
                    options={slideOptions}
                    ref={mySlides}
                    className={`${!hidebottomContent ? "" : "hideNextButton"}`}
                  >
                    {slidesList.map((item: any, index: number) => {
                      return (
                        <IonSlide
                          className={
                            index == 0 ? "bottom-conatiner-first-slide" : ""
                          }
                          key={index}
                        >
                          <div className="page-intro-title text-green font-bold mb-10">
                            {item.value.title[language.code] ||
                              item.value.title["en"]}
                          </div>
                          <div className="page-intro-desc text-blue font-medium mb-15">
                            {item.value.description[language.code] ||
                              item.value.description["en"]}
                          </div>
                        </IonSlide>
                      );
                    })}
                  </IonSlides>
                  {hidebottomContent && (
                    <>
                      <div className="page-intro-title text-green font-bold mb-10">
                        Join our growing community
                      </div>
                      <div className="page-intro-desc text-blue font-medium mb-15">
                        Share stories and get the latest trends from over 5,000
                        people like you!
                      </div>
                    </>
                  )}
                  <ButtonComponent
                    name="Next"
                    textKey="next"
                    customClass={`intro-next-btn ${
                      showNextButton ? "" : "hideNextButton"
                    }`}
                    onClick={() => onBtnClicked("next")}
                  />
                  {!showNextButton && showGoButton && (
                    <ButtonComponent
                      name="Let’s go!"
                      textKey="lets_go"
                      customClass="intro-go-btn"
                      onClick={() => {
                        setshowVideo(true);
                      }}
                    />
                  )}
                </div>
              )}
            </IonContent> */}
            {showVideo && (
              <div className="page-video-intro">
                <VideoPlayer
                  url={
                    introVideo
                      ? getFormattedUrl(
                          introVideo[language.code]?.uri || introVideo["en"].uri
                        )
                      : ""
                  }
                  isFullScreen={true}
                  onVideoEnd={() => {
                    history.replace({
                      pathname: `/register`,
                    });
                  }}
                />
                <div className="page-video-intro-button">
                  <ButtonComponent
                    name="Back"
                    textKey="back"
                    customClass="intro-video-btn"
                    onClick={backVideo}
                  />
                  <LanguageAppText
                    tag={"div"}
                    className="intro-video-text text-white font-medium"
                    value={"Intro to Ovante"}
                    textKey={"intro_to_ovante"}
                  />
                  <ButtonComponent
                    name="Skip"
                    textKey="skip"
                    customClass="intro-video-btn"
                    onClick={skipVideo}
                  />
                </div>
              </div>
            )}
          </IonContent>
        )}
        <BackDrop
          backdropVisible={isBackDropVisible}
          isOutsideClick={true}
        ></BackDrop>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            zIndex: 99999,
            width: "100%",
          }}
        >
          <BottomDrawer
            type={LANGUAGE_TYPE}
            hideBackDrop={handleBackDrop}
            backBtnText="Change language"
            backBtnTextKey={"change_language"}
          >
            {selectLanguage()}
          </BottomDrawer>
        </div>
      </IonPage>
    </>
  );
};

export default Introduction;

import {
  IonButton,
  IonCard,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonThumbnail,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ButtonComponent from "../../components/common/button-component";
import RegisterHeader from "../../components/common/register-header";
import ExtraActivityCard from "../../components/extraactivitycard";
import { useHistory, useLocation } from "react-router";
import Coins from "../../assets/images/icons/coins.svg";
import PlayOrange from "../../assets/images/icons/play-orange.svg";
import Congratultions from "../../assets/images/congratultions.svg";
import "./style.scss";
import { AppContext } from "../../context/app.context";
import LanguageAppText from "../../components/languageapptext";
import Popup from "../../components/common/popup";
import ErrorRetrySvg from "../../assets/images/error_retry.svg";
import { isConnectedToInternet } from "../../services/network.service";

let isModal = false;
const ActivityComplete: React.FC = () => {
  const { language }: any = useContext(AppContext);
  const history = useHistory();
  const { state }: any = useLocation();
  const programId: any = state.programId;
  const activityTitle: any = state.activityTitle;
  const pendingActivities: any = state.pendingActivities;
  const programCompleted: any = state.programCompleted || false;
  const [showModal, setShowModal] = useState(false);
  let activities: any = [];
  let title = "";
  try {
    activities = JSON.parse(pendingActivities);
    let parsedTitle = JSON.parse(activityTitle);
    title = parsedTitle[language] || parsedTitle["en"];
  } catch (e) {}

  useEffect(() => {
    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
      isModal = false;
    };
  }, []);

  const onGoToTopic = () => {
    if (isConnectedToInternet()) {
      history.replace({
        pathname: `/learnings/${programId}`,
        state: {
          fromPage: "activityCompleted",
        },
      });
    } else {
      isModal = true;
      setShowModal(true);
    }
  };

  const handleBack = (ev: any) => {
    ev.detail.register(101, () => {
      if (isModal) {
        isModal = false;
        setShowModal(false);
      } else {
        history.goBack();
      }
    });
  };

  const onGoToProgram = () => {
    if (isConnectedToInternet()) {
      history.replace({
        pathname: `/tabs/experts`,
      });
    } else {
      isModal = true;
      setShowModal(true);
    }
  };

  const onActivityClick = (activity: any) => {
    history.push({
      pathname: `/activity/${activity.id}`,
    });
  };

  const onContinueClick = () => {
    isModal = false;
    setShowModal(false);
  };

  return (
    <IonPage className="page-activity-complete large-header abstract-img">
      <RegisterHeader
        pageName="Activity complete!"
        textKey={"activity_completed"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent className="page-content pb-0">
        <div className="reward-content">
          <IonImg src={Congratultions} className="reward__img"></IonImg>
          <LanguageAppText
            tag={"div"}
            className="reward__title"
            value={"Congratulations!"}
            textKey={"congratulations"}
          />
          <LanguageAppText
            tag={"p"}
            className="reward__info"
            value={`You have completed the activity "${title}"`}
            textKey={"activity_completed_success"}
            metaData={{ activityName: title }}
          />
          {/* <p className="">
            {`You have completed the activity "${title}"`}
          </p> */}
        </div>
        <div className="stretch-container bg-light-orange bottom-box bottom-box__noscroll pt-0">
          {activities && activities.length > 0 && !programCompleted && (
            <>
              <LanguageAppText
                tag={"p"}
                className="bottom-box__title"
                value={"Choose an activity or complete program."}
                textKey={"choose_activity_program"}
              />
              <div
                onClick={() => {
                  onActivityClick(activities[0]);
                }}
              >
                <ExtraActivityCard
                  label={activities[0].nodeFieldData.title}
                  thumbnail={activities[0].nodeFieldData.teaserImage}
                ></ExtraActivityCard>
              </div>
            </>
          )}
          {programCompleted ? (
            <ButtonComponent
              onClick={onGoToProgram}
              name="Go back to program list"
            ></ButtonComponent>
          ) : (
            <ButtonComponent
              onClick={onGoToTopic}
              name="Go back to topic list"
              textKey={"back_to_topic"}
            ></ButtonComponent>
          )}
        </div>
        {showModal && (
          <Popup
            customClass="tools-popup"
            onClose={() => {
              isModal = false;
              setShowModal(false);
            }}
          >
            <div>
              <IonImg
                src={ErrorRetrySvg}
                className="ion-popup__success-icon mb-20"
              />

              {/* <div className="tools-popup__detail-text text-light-black font-medium mb-20">
                This require active internet connection. Please try again.
              </div> */}

              <LanguageAppText
                tag={"div"}
                className="tools-popup__detail-text text-light-black font-medium mb-20"
                value={
                  "This require active internet connection. Please try again."
                }
                textKey={"no_internet"}
              />
              <ButtonComponent
                name="Ok"
                textKey={"ok"}
                onClick={onContinueClick}
                customClass="page-congratulations__btn"
              ></ButtonComponent>
            </div>
          </Popup>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ActivityComplete;

import { IonContent, IonImg, IonLabel, IonList, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import LockBadge from "../../assets/images/badges/badge-lock.svg";
import RegisterHeader from "../../components/common/register-header";
import LanguageAppText from "../../components/languageapptext";
import { AppContext } from "../../context/app.context";
import { userBadgesDetails } from "../../services/user.service";
import {
  formatDate,
  getFormattedUrl,
  sliceIntoChunks,
} from "../../utils/common.helper";
import "./style.scss";

const YourBadges: React.FC = () => {
  const { toastMessage, userDetails }: any = useContext(AppContext);
  const [currentLevelDetails, setCurrentlevelDetails]: any = useState(null);
  const [completedLevelDetails, setCompletedlevelDetails]: any = useState(null);
  useEffect(() => {
    fetchUserBages();
  }, []);
  const fetchUserBages = async () => {
    const response: any = await userBadgesDetails();
    console.log(JSON.stringify(response));
    if (!response.error) {
      setCurrentlevelDetails(response.currentLevelBadges);
      if (
        response.completedLevelBadges &&
        response.completedLevelBadges.length > 0
      ) {
        const badgesData: any = {};
        response.completedLevelBadges.map((item: any, index: number) => {
          if (!badgesData.hasOwnProperty(item.level)) {
            badgesData[item.level] = [];
          }
          badgesData[item.level].push(item);
        });
        setCompletedlevelDetails(badgesData);
      }
    } else {
      toastMessage(response.message, "primary");
    }
  };

  const renderCurrentLevelView = () => {
    const currLevelBadges = currentLevelDetails.badges.map(
      (badgeItem: any, index: any) => {
        const item = badgeItem.badge;
        return (
          <IonList key={item.id} className="badge-list ion-no-padding">
            <IonImg
              src={item.image ? getFormattedUrl(item.image.uri) : ""}
              className="profile__badge-image"
            />
            <IonLabel
              position="stacked"
              className="profile__badge-label text-gray"
            >
              {item.badgeName}
            </IonLabel>
          </IonList>
        );
      }
    );

    const lockedData = [];
    for (let i = 0; i < currentLevelDetails.remainingBadgesCount; i++) {
      lockedData.push(
        <IonList key={"locked-" + i} className="badge-list ion-no-padding">
          <IonImg src={LockBadge} className="profile__badge-image" />
        </IonList>
      );
    }

    return [currLevelBadges, lockedData];
  };

  const renderBadgeItems = (levelsData: any) => {
    return levelsData.map((badgesData: any, index: number) => {
      return (
        <div
          key={"badge-container-" + index}
          className={`page-badge__badge-list ion-no-padding ${
            badgesData.length != 3 ? "empty-col" : ""
          }`}
        >
          {badgesData.map((item: any, index: number) => {
            const badgeName = item.badge.badgeName;
            return (
              <IonList key={item.id} className="badge-list ion-no-padding">
                <IonImg
                  src={
                    item.badge.image
                      ? getFormattedUrl(item.badge.image.uri)
                      : ""
                  }
                  className="profile__badge-image"
                />
                <IonLabel
                  position="stacked"
                  className="profile__badge-label text-gray"
                >
                  {badgeName}
                </IonLabel>
              </IonList>
            );
          })}
        </div>
      );
    });
  };

  const renderCompletedLevels = () => {
    const levels = Object.keys(completedLevelDetails);

    return levels.map((data: any, dataIndex: number) => {
      completedLevelDetails[data].sort((a: any, b: any) => {
        return Number(new Date(b.createdAt)) - Number(new Date(a.createdAt));
      });

      const levelDetails = sliceIntoChunks(completedLevelDetails[data], 3);
      return (
        <div key={"level-" + data + "-" + dataIndex}>
          <div className="page-content__title">Level {data}</div>
          <LanguageAppText
            tag={"p"}
            className="page-badges__instructions text-gray ion-no-margin"
            value={`Completed on ${" "} ${formatDate(
              levelDetails[0][0].createdAt,
              "DD MMM yyyy"
            )}`}
            textKey={"level_completion_date"}
            metaData={{
              levelCompletionDate: formatDate(
                levelDetails[0][0].createdAt,
                "DD MMM yyyy"
              ),
            }}
          />
          <div>{renderBadgeItems(levelDetails)}</div>
        </div>
      );
    });
  };

  return (
    <div className="large-header--gradient large-header--gradient-blue">
      <IonPage className="page-your-badges large-header abstract-img-light-blue">
        <RegisterHeader
          pageName="Your badges"
          textKey={"your_badges"}
          backBtn={true}
        ></RegisterHeader>
        <IonContent className="page-content pb-0">
          {currentLevelDetails && (
            <div className="page-badges__content bg-white ion-padding">
              <LanguageAppText
                tag={"div"}
                className="page-content__title"
                value={`You’re almost on Level ${
                  userDetails.level ? userDetails.level + 1 : 1
                }`}
                textKey={"level_next"}
                metaData={{
                  levelNext: userDetails.level ? userDetails.level + 1 : 1,
                }}
              />
              <LanguageAppText
                tag={"p"}
                className="page-badges__instructions text-gray ion-no-margin"
                value={`Great work! Just ${
                  currentLevelDetails.remainingBadgesCount
                } ${" "} badges left`}
                textKey={"badge_remaining_count"}
                metaData={{
                  badgeRemainingCount: currentLevelDetails.remainingBadgesCount,
                }}
              />
              <div className="page-badge__badge-list ion-no-padding">
                {renderCurrentLevelView()}
              </div>
            </div>
          )}
          {completedLevelDetails && (
            <div className="stretch-container bg-light-orange bottom-box">
              {renderCompletedLevels()}
            </div>
          )}
        </IonContent>
      </IonPage>
    </div>
  );
};

export default YourBadges;

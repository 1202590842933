import "./style.scss";

import { IonContent, IonItem, IonList, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";

import ButtonComponent from "../../components/common/button-component";
import BackButton from "../../components/common/button-component/back";
import CheckBox from "../../components/common/checkbox";
import { AppContext } from "../../context/app.context";
import { OnboardingContext } from "../../context/onboarding.contex";
import { getStateList } from "../../services/app.service";
import Backblue from "../../assets/images/icons/back-blue.svg";
import Messages from "../../config/messages";
import LanguageAppText from "../../components/languageapptext";

const StateList: React.FC = () => {
  const { toastMessage }: any = useContext(AppContext);
  const { onboardingData, setOnoboardingData }: any =
    useContext(OnboardingContext);
  const history: any = useHistory();
  const [stateList, setStateList]: any = useState([]);
  const [disableSave, setDisableSave] = useState(
    !onboardingData || !onboardingData.state || onboardingData.state == ""
  );
  const [selectedState, setSelectedState]: any = useState(
    (onboardingData && onboardingData.state) || ""
  );

  useEffect(() => {
    fetchStateList();
  }, []);

  const fetchStateList = async () => {
    const resp: any = await getStateList();
    if (!resp.error) {
      setStateList(resp);
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
  };

  const handleStateSelection = (val: string) => {
    if (selectedState === val) {
      setSelectedState("");
      setDisableSave(true);
    } else {
      setSelectedState(val);
      setDisableSave(false);
    }
  };

  const handleSave = () => {
    if (onboardingData.state !== selectedState) {
      onboardingData["state"] = selectedState;
      onboardingData["district"] = "";
      setOnoboardingData(onboardingData);
    }

    history.goBack();
  };

  const renderStateList = () => {
    const view = stateList.map((item: any, index: number) => {
      return (
        <div className="page-content__whitebox" key={index}>
            <CheckBox
              key={item.state}
              label={item.state}
              isChecked={selectedState === item.state}
              onClick={() => {
                handleStateSelection(item.state);
              }}
            />
        </div>
      );
    });

    return view;
  };

  return (
    <IonPage className="page-state">
      <IonContent fullscreen className="page-content">
        <div className="page-content__bg stretch-container"></div>
        <div className="page-content__container stretch-container">
          <div className="page-content__businessheader">
            <BackButton icon={Backblue}></BackButton>
            <LanguageAppText
              tag={"h1"}
              className="page-content__title p0"
              value={"List of States"}
              textKey={"type_of_states"}
            />
          </div>
          <div className="page-content__container-inner">
            <IonList>{renderStateList()}</IonList>

            <div className="stretch-container">
              <ButtonComponent
                name="Save"
                isEnabled={disableSave}
                onClick={handleSave}
                textKey={"save"}
              />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default StateList;

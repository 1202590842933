import React, { useContext } from "react";
import { AppContext } from "../../context/app.context";
import parse from "html-react-parser";
import { IonImg } from "@ionic/react";
import { getFormattedUrl } from "../../utils/common.helper";

type Props = {
  className?: any;
  value: any;
};

const LanguageImage: React.FC<Props> = ({ className, value }) => {
  const { language }: any = useContext(AppContext);
  // const tagValue = JSON.parse(value);
  let tagValue = value;
  if (typeof value === "string") {
    tagValue = JSON.parse(value);
  }
  return (
    <IonImg
      className={className}
      src={
        tagValue[language]
          ? getFormattedUrl(tagValue[language].uri)
          : getFormattedUrl(tagValue.en.uri)
      }
    />
  );
};

export default LanguageImage;

import { IonContent, IonPage } from "@ionic/react";
import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import FormGroup from "../../components/common/formgroup";
import InputText from "../../components/common/inputtext";
import RegisterHeader from "../../components/common/register-header";
import ValidationMessage from "../../components/common/validation-message";
import LanguageAppText from "../../components/languageapptext";
import Messages from "../../config/messages";
import { CheckPhoneSchema } from "../../schemas/auth.schema";
import {
  formatPhoneForForm,
  formatPhoneNumber,
  formatPhoneToReadable,
  handleOnValueChange,
} from "../../utils/common.helper";
import "./style.scss";

const CreateAccount: React.FC = () => {
  const history: any = useHistory();
  const { state }: any = useLocation();
  const initialValues = {
    phoneNumber: formatPhoneForForm(state.phoneNumber),
  };
  const [disabledInput, setDisabledInput] = useState(true);
  const [focusable, setFocusable] = useState(false);

  const handleCorrectNumber = (values: any, actions: any) => {
    history.replace({
      pathname: "/othernumbers",
      state: {
        phoneNumber: formatPhoneToReadable(values.phoneNumber),
      },
    });
  };

  const handleChangeNumber = async () => {
    setDisabledInput(false);
    setFocusable(true);
  };

  return (
    <IonPage className="page-create-account">
      <RegisterHeader
        pageName="Create your account"
        textKey={"create_your_acc"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen className="page-content">
        <div>
          <h1>
            <LanguageAppText
              tag={"span"}
              className="page-content__title"
              value={`Welcome to Ovante! ${" "}`}
              textKey={"welcome_to_ovante"}
            />
            <LanguageAppText
              tag={"span"}
              className="page-content__title"
              value={"Let’s get started on your account!"}
              textKey={"lets_get_started_acc"}
            />
          </h1>
          {/* <LanguageAppText
            tag={"h1"}
            className="page-content__title"
            value={"Welcome to Ovante! Let’s get started on your account!"}
            textKey={"welcome_to_ovante_lets_get_started_acc"}
          /> 
          <LanguageAppText
            tag={"h1"}
            className="page-content__title"
            value={"Let’s get started on your account!"}
            textKey={"lets_get_started_acc"}
          /> */}

          <Formik
            initialValues={initialValues}
            validationSchema={CheckPhoneSchema}
            onSubmit={handleCorrectNumber}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FormGroup>
                  <InputText
                    focusable={focusable}
                    label="Please check your phone number"
                    labelKey={"check_your_phone"}
                    name="phoneNumber"
                    value={formatPhoneNumber(values.phoneNumber)}
                    onChange={(val: string) => {
                      handleOnValueChange("phoneNumber", val, setFieldValue);
                    }}
                    maxlength={11}
                    prefix={"+91"}
                    inputMode={"numeric"}
                    disabled={disabledInput}
                    customClass={disabledInput ? "error-input" : ""}
                  ></InputText>
                  <ValidationMessage name="phoneNumber"></ValidationMessage>
                </FormGroup>
                <FormGroup>
                  <div onClick={handleChangeNumber}>
                    <LanguageAppText
                      tag={"p"}
                      className="page-content__otptext"
                      value={"Change phone number"}
                      textKey={"change_phone"}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="page-content__button">
                    <ButtonComponent
                      //outline={true}
                      name={Messages.BTN_NUMBER_CORRECT}
                      textKey={"phone_is_correct"}
                    />
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CreateAccount;

import HttpService, { HttpMethod } from "./http.service";

export const getLeaderBoard = async () => {
  const response = await HttpService.execute({
    method: HttpMethod.GET,
    url: `/leaderboard`,
  });
  return response;
};

export const getLeaderBoardShareImage = async (coins: number) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/leaderboard/get-share-leaderboard`,
    data: {
      coins,
    },
  });
  return response;
};

import HttpService, { HttpMethod } from './http.service'

export const getAllRewards = async () => {
  const response = await HttpService.execute({
    url: `/rewards`
  })
  return response
}

export const getRewardDetails = async (id: number) => {
    const response = await HttpService.execute({
       url: `/rewards/view/${id}`
    })
     return response
}

export const getRedeemedRewards = async () => {
    const response = await HttpService.execute({
        url: '/rewards/my-rewards'
    })
    return response
}

export const redeemReward = async(id: number)=>{
    const response = await HttpService.execute({
        url: `/rewards/redeem/${id}`
    })
    return response
}

export const getRedeemRewardInfo = async (id: number) => {
    const response = await HttpService.execute({
       url: `/rewards/view/${id}`
    })
     return response
}
import { IonButton, IonImg, IonItemDivider, IonThumbnail } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import ToolUserDefault from "../../assets/images/global-default_new.jpeg";
import ToolLike from "../../assets/images/tool-like.svg";
import ToolUnlike from "../../assets/images/tool-unlike.svg";
import LanguageText from "../languagetext";
import { formatStringToJSON, getFormattedUrl } from "../../utils/common.helper";
import LanguageAppText from "../languageapptext";

interface Props {
  cardName?: string;
  customClass?: string;
  imgPath?: string;
  isLikeCard?: boolean;
  otherText?: any;
  toolLikeText?: string;
  isCountVisible?: boolean;
  isFavourite?: boolean;
  onClick?: any;
  onFavClick?: any;
}

const ToolCard: React.FC<Props> = ({
  cardName,
  customClass,
  imgPath,
  isLikeCard,
  otherText,
  toolLikeText,
  isFavourite,
  isCountVisible = false,
  onClick,
  onFavClick,
}) => {
  const [favourite, setFavourite] = useState(isFavourite);
  const [mediaPath, setMediaPath]: any = useState(
    imgPath && formatStringToJSON(imgPath)
  );

  useEffect(() => {
    if (imgPath) {
      setMediaPath(formatStringToJSON(imgPath));
    }
  }, [imgPath]);

  const handleCardClick = () => {
    onClick && onClick();
  };

  const handleFavClick = () => {
    setFavourite(!favourite);
    onFavClick && onFavClick();
  };

  return (
    <div className="tool-card__main-container">
      <div
        className={`tool-card__container mt-30 bg-white ${customClass || ""}`}
        onClick={handleCardClick}
      >
        <div className="tool-card__details">
          {imgPath && (
            <div className="tool-card__img">
              <IonThumbnail
                slot="start"
                className="tool-card-thumbnail-container"
              >
                <img
                  className="tool-card-thumbnail-img"
                  src={
                    typeof mediaPath == "object"
                      ? mediaPath &&
                        mediaPath.uri &&
                        getFormattedUrl(mediaPath.uri)
                      : mediaPath
                  }
                />
              </IonThumbnail>
            </div>
            // <IonImg
            //   src={getFormattedUrl(mediaPath.uri)}
            //   className="tool-card__img"
            // />
          )}
          <LanguageText
            tag="div"
            value={cardName}
            className={"tool-card__name text-green font-medium mb-3 mt-5"}
          />
          <div className="other__user-container">
            {isCountVisible && (
              <div className="user-top-three-img">
                <IonImg src={ToolUserDefault} className="user__img" />
                <IonImg src={ToolUserDefault} className="user__img" />
                <IonImg src={ToolUserDefault} className="user__img" />
              </div>
            )}

            {otherText}
          </div>
        </div>
      </div>
      {isLikeCard && (
        <div className="tool-card__like">
          <IonImg
            src={favourite ? ToolLike : ToolUnlike}
            className="tool-like__img mb-5 mt-5"
            onClick={handleFavClick}
          />

          {toolLikeText && toolLikeText != "" && (
            <LanguageAppText
              tag={"div"}
              className={"tool-like-text text-pearl-gray"}
              value={"Used {toolLastUsedDuration} ago"}
              textKey={"tool_lastused_duration"}
              metaData={{ toolLastUsedDuration: toolLikeText }}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default ToolCard;

import httpService, { HttpMethod } from "./http.service";

export const createCreditProfile = async (req: any) => {
  const resp = httpService.execute({
    url: "/user-credit-profile",
    method: HttpMethod.POST,
    data: req,
  });
  return resp;
};

export const updateCreditProfile = async (req: any) => {
  const resp = httpService.execute({
    url: "/user-credit-profile",
    method: HttpMethod.PUT,
    data: req,
  });
  return resp;
};

export const getCreditProfile = async () => {
  const resp = httpService.execute({
    url: "/user-credit-profile",
    method: HttpMethod.GET,
  });
  return resp;
};

import HttpService, { HttpMethod } from "./http.service";

export const getToolsByStatus = async (status: boolean) => {
  const response = await HttpService.execute({
    url: `/tools/${status}`,
  });
  return response;
};

export const getUnlockTools = async () => {
  const response = await HttpService.execute({
    url: `/tools/unlocked`,
  });
  return response;
};

export const getLockTools = async () => {
  const response = await HttpService.execute({
    url: `/tools/locked`,
  });
  return response;
};

export const toggleToolFavourite = async (id: number) => {
  const response = await HttpService.execute({
    url: `/tools/favourite/${id}`,
    method: HttpMethod.PUT,
  });
  return response;
};

export const toolUsedOn = async (id: number) => {
  const response = await HttpService.execute({
    url: `/tools/usedon`,
    method: HttpMethod.POST,
    data: { id },
  });
  return response;
};

export const getToolsUsedOn = async (ids: Array<number>) => {
  const response = await HttpService.execute({
    url: `/tools/usedon/fetch`,
    method: HttpMethod.POST,
    data: { ids },
  });
  return response;
};

export const getHighlightedTools = async () => {
  const response = await HttpService.execute({
    url: `/tools/highlighted`,
  });
  return response;
};

import { Capacitor } from "@capacitor/core";
import languageService from "./language.service";
import HttpService, { HttpMethod } from "./http.service";
import { File } from "@ionic-native/file";
import { getFileDirectory } from "../utils/common.helper";
import { downloadFile } from "./filetransfer.service";

export const getLangDescriptor = async (language: string) => {
  let lang_version = 0;

  if (Capacitor.getPlatform() !== "web") {
    const resp: any = await languageService.instance.getData("code", language);
    if (!resp.error && resp.length > 0) {
      lang_version = resp.item(0).version;
    }
  }

  const descResp: any = await getDescriptor(language);
  console.log("desc", lang_version, descResp);
  if (!descResp.error) {
    if (
      Capacitor.getPlatform() !== "web" &&
      descResp.version &&
      descResp.filePath &&
      descResp.version != lang_version
    ) {
      // download new file
      let desc_file = `descriptor_${language}`;
      let parsedFilePath = JSON.parse(descResp.filePath);
      let desc_url = `/file/download/${parsedFilePath.uri}`;
      try {
        const isExist = await File.checkFile(
          getFileDirectory(),
          `${desc_file}.json`
        );
        if (isExist) {
          const removeRes: any = await File.removeFile(
            getFileDirectory(),
            `${desc_file}.json`
          );
          if (removeRes && removeRes.success) {
            await downloadDescFile(desc_url, desc_file);
          }
        }
      } catch (error) {
        await downloadDescFile(desc_url, desc_file);
      }

      languageService.instance.insertorupdate({
        name: descResp.title,
        code: descResp.code,
        version: descResp.version,
      });
    }
  }
};

export const getDescriptor = async (languageCode: string) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/language/getDescriptor`,
    data: {
      languageCode,
    },
  });
  return response;
};

const downloadDescFile = async (desc_url: string, desc_file: string) => {
  const resDownload: any = await downloadFile(desc_url, desc_file, "json");
  console.log("down", resDownload);
  if (!resDownload.error) {
    Capacitor.convertFileSrc(`${getFileDirectory()}${desc_file}.json`);
  }
};

import {
  IonContent,
  IonPage,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import RegisterHeader from "../../components/common/register-header";
import HomeStoryCard from "../../components/homestorycard";
import { getAllNotificationsList } from "../../services/notification.service";
import "./style.scss";
import { AppContext } from "../../context/app.context";
import {
  formatDateToSingleChar,
  getActivityMetaData,
  getFormattedUrl,
  stringInject,
} from "../../utils/common.helper";
import moment from "moment";
import LanguageAppText from "../../components/languageapptext";

const Notifications: React.FC = () => {
  let ionInfiniteScrollRef: React.RefObject<HTMLIonInfiniteScrollElement> =
    React.createRef();
  const { language }: any = useContext(AppContext);
  const [readNotification, setReadNotification] = useState<any>([]);
  const [unreadNotification, setunReadNotification] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [readDisbaled, setReadDisabled] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [lastId, setLastId] = useState(0);
  const [readCompleted, setReadCompleted] = useState(false);
  const [readStatus, setReadStatus] = useState(false);

  useEffect(() => {
    ionInfiniteScrollRef = React.createRef<HTMLIonInfiniteScrollElement>();
  }, []);

  const getNotificationList = async (
    event?: any,
    status?: boolean,
    isRead?: boolean
  ) => {
    if (!isFirstLoad && readDisbaled) return;
    if (!status) {
      status = readStatus;
    }
    if (!isRead) {
      isRead = readCompleted;
    }
    let query = `read=${status}&lastId=${lastId}`;
    const resp = await getAllNotificationsList(query);
    if (!resp.error) {
      if (event) {
        event.target.complete();
      }
      if (!isRead) {
        setReadNotification(readNotification.concat(resp));
      } else {
        let result = resp.filter(
          (item1: any) =>
            !readNotification.some((item2: any) => item1.id === item2.id)
        );
        setunReadNotification(unreadNotification.concat(result));
      }
      setLastId(resp[resp.length - 1].id);
      if (resp.length <= 9) {
        if (isRead) {
          setReadDisabled(true);
        } else {
          setReadCompleted(true);
          setReadStatus(true);
          if (resp.length > 0) {
            setTimeout(() => {
              getNotificationList(undefined, true, true);
            }, 500);
          }

          // setLastId(0);
        }
      }
    } else {
      if (resp.status === 404) {
        if (isRead) {
          setReadDisabled(true);
        } else {
          setReadCompleted(true);
          setReadStatus(true);
          // setLastId(0);
        }
      }
    }
    if (ionInfiniteScrollRef.current) {
      ionInfiniteScrollRef.current.complete();
    }
    setIsFirstLoad(false);
  };
  useEffect(() => {
    if (lastId === 0) {
      getNotificationList();
    }
  }, [readStatus, lastId]);

  const formatNotificationTitle = (title: string, metaData: any) => {
    let parsedTitle: any = title;
    try {
      parsedTitle = JSON.parse(title)[language] || JSON.parse(title)["en"];
      if (metaData) {
        const parsedMetaData = JSON.parse(metaData);
        const formattedData = getActivityMetaData(
          parsedMetaData.metaData,
          parsedTitle
        );
        parsedTitle = stringInject(parsedTitle, formattedData);
      }
    } catch (error) {
      if (metaData) {
        const parsedMetaData = JSON.parse(metaData);
        parsedTitle = stringInject(parsedTitle, parsedMetaData.metaData);
      }
    }
    return parsedTitle;
  };

  return (
    <IonPage className="page-notification">
      <RegisterHeader
        pageName="Notifications"
        textKey={"notifications"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen={true} className="page-content">
        {readNotification.length > 0 && (
          <div className="page-content__unread-notification stretch-container mb-20">
            <LanguageAppText
              tag={"div"}
              className="page-content__title"
              value={"Unread"}
              textKey={"unread"}
            />
            <IonList>
              {readNotification.map((notification: any) => {
                return (
                  <HomeStoryCard
                    key={notification.id}
                    titleText={formatNotificationTitle(
                      notification.title,
                      notification.metaData
                    )}
                    descriptionText={formatNotificationTitle(
                      notification.subText,
                      notification.metaData
                    )}
                    metaData={notification.metaData}
                    isClickable
                  />
                );
              })}
            </IonList>
          </div>
        )}

        <div className="page-content__old-notification">
          {readCompleted && unreadNotification.length > 0 && (
            <>
              <LanguageAppText
                tag={"div"}
                className="old-notification__title text-black font-bold mb-15"
                value={"Older notifications"}
                textKey={"older_notifications"}
              />
              <IonList>
                {unreadNotification.map((notification: any) => {
                  return (
                    <HomeStoryCard
                      key={notification.id}
                      titleText={formatNotificationTitle(
                        notification.title,
                        notification.metaData
                      )}
                      // titleText={notification.title}
                      // descriptionText={notification.subText}
                      metaData={notification.metaData}
                      descriptionText={formatNotificationTitle(
                        notification.subText,
                        notification.metaData
                      )}
                      imgPath={
                        notification.imageUrl
                          ? getFormattedUrl(notification.imageUrl)
                          : ""
                      }
                      notificationCard
                      oldNotification
                      notificationDays={formatDateToSingleChar(
                        moment(notification.updatedAt).fromNow(),
                        notification.updatedAt
                      )}
                      isClickable
                    />
                  );
                })}
              </IonList>
            </>
          )}
        </div>
        {ionInfiniteScrollRef && (
          <IonInfiniteScroll
            ref={ionInfiniteScrollRef}
            threshold="100px"
            onIonInfinite={(event) => {
              getNotificationList(event);
            }}
            disabled={readDisbaled}
          >
            <IonInfiniteScrollContent
              loadingSpinner="circles"
              loadingText="Loading more data..."
            />
          </IonInfiniteScroll>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Notifications;

import React, { useContext, useState } from "react";
import { IonContent, IonPage } from "@ionic/react";

import RegisterHeader from "../../components/common/register-header";
import ButtonComponent from "../../components/common/button-component";
import { chooseFromGallery, videoRecorder } from "../../utils/media.helper";
import {
  checkPermission,
  requestPermission,
} from "../../utils/permission.helper";
import { AndroidPermissions } from "@ionic-native/android-permissions";
import { CaptureVideoOptions } from "@awesome-cordova-plugins/media-capture";
import Loader from "../common/loader";
import RecorderVideo from "../../pages/recordedvideo";
import { Capacitor } from "@capacitor/core";
import { uploadFile } from "../../services/filetransfer.service";
import { AppContext } from "../../context/app.context";
import Messages from "../../config/messages";
import { getFormattedUrl, isOSBelowOREqual } from "../../utils/common.helper";
interface Props {
  onClose?: () => void;
  onSuccess?: (successObj: any) => void;
}
const UploadVideo = (props: Props) => {
  const { onClose, onSuccess } = props;
  const { toastMessage }: any = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [fileUri, setFileUri] = useState("");
  const [thumbUri, setThumbUri] = useState("");

  const hasPermission = async () => {
    let hasPermission = false;

    if (await isOSBelowOREqual(12)) {
      hasPermission = await checkPermission(
        AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
      );

      if (!hasPermission) {
        hasPermission = await requestPermission(
          AndroidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE
        );
      }
    } else {
      hasPermission = true;
    }

    return hasPermission;
  };

  const onRecordVideo = async () => {
    const isAllowed = await hasPermission();
    if (isAllowed) {
      setLoading(true);
      let options: CaptureVideoOptions = { duration: 600 };
      const videoData = await videoRecorder(options);
      console.log("video-----", videoData);
      if (videoData.compressed) {
        setFileUri(videoData.compressed);
      }
      if (videoData.thumbnail) {
        setThumbUri(videoData.thumbnail);
      }
      setLoading(false);
    }
  };

  const onChooseVideo = async () => {
    const isAllowed = await hasPermission();
    if (isAllowed) {
      setLoading(true);
      const videoData = await chooseFromGallery("video/*", true);
      console.log("video-----", videoData);
      if (videoData) {
        if (videoData.compressed) {
          setFileUri(videoData.compressed);
        }
        if (videoData.thumbnail) {
          setThumbUri(videoData.thumbnail);
        }
      }
      setLoading(false);
    }
  };

  const redoPost = () => {
    setFileUri("");
    setThumbUri("");
  };

  const onPost = async () => {
    setLoading(true);
    if (fileUri != "" && thumbUri != "") {
      const fileResp: any = await uploadFile(
        "file://" + fileUri,
        "video/mp4",
        ""
      );

      const thumbResp: any = await uploadFile(
        "file://" + thumbUri,
        "image/jpeg",
        ""
      );

      if (!fileResp.error && !thumbResp.error) {
        // toastMessage("Video uploaded successfully", "success");
        setFileUri("");
        setThumbUri("");
        if (onSuccess) {
          onSuccess({
            videoId: JSON.parse(fileResp).id,
            videoFile: getFormattedUrl(JSON.parse(fileResp).uri),
            thumbFile: getFormattedUrl(JSON.parse(thumbResp).uri),
            thumnailId: JSON.parse(thumbResp).id,
          });
        }
      } else {
        // isVideo = false;
        toastMessage(Messages.FILE_UPLOAD_ERROR, "primary");
      }
    }
    setLoading(false);
  };

  return (
    <IonPage className="page-learning">
      {fileUri === "" && (
        <RegisterHeader
          pageName={"Upload Media"}
          backBtn={true}
          onBack={() => {
            if (onClose) {
              setFileUri("");
              setThumbUri("");
              onClose();
            }
          }}
        ></RegisterHeader>
      )}
      <IonContent className="page-content pb-0">
        {loading ? (
          <Loader customClass="loader-page-center" />
        ) : fileUri != "" ? (
          <RecorderVideo
            url={Capacitor.convertFileSrc("file://" + fileUri)}
            thumbnail={Capacitor.convertFileSrc("file://" + thumbUri)}
            onRedo={redoPost}
            onPost={onPost}
          />
        ) : (
          <div className="submit-story__btn-container">
            <ButtonComponent
              name="Choose From Gallery"
              textKey={"choose_from_gallery"}
              outline
              customClass="story-upload__btn mr-5"
              onClick={onChooseVideo}
            ></ButtonComponent>
            <ButtonComponent
              name="Record Video"
              customClass="story-record__btn ml-5"
              onClick={onRecordVideo}
              textKey={"record_video"}
            ></ButtonComponent>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
export default UploadVideo;

import {
  IonCheckbox,
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonPage,
  IonTextarea,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import InputText from "../../components/common/inputtext";
import RegisterHeader from "../../components/common/register-header";
import PolygonIcon from "../../assets/images/icons/icon-polygon-green.svg";
import AddTask from "../../assets/images/icons/add-task.svg";
import AddTaskOrange from "../../assets/images/icons/add-task-orange.svg";
import AddTick from "../../assets/images/icons/icon-tick.svg";
import "./style.scss";
import ButtonComponent from "../../components/common/button-component";
import {
  convertLocalToUTC,
  convertUTCToLocal,
} from "../../utils/common.helper";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../components/common/loader";
import { openDatePicker } from "../../utils/datepicker.helper";
import {
  addTask,
  changeTaskStatus,
  getToDoList,
  updateTask,
} from "../../services/todo.service";
import { AppContext } from "../../context/app.context";
import Messages from "../../config/messages";
import TodoAnimationData from "../../assets/todo.json";
import { useHistory } from "react-router";
import LanguageAppText from "../../components/languageapptext";
import moment from "moment";
import localnotificationService from "../../services/localnotification.service";
let interval: any;
let completedLength = 0;
const ToDo: React.FC = () => {
  const [todoText, setTodoText] = useState("");
  const [startDate, setStartDate]: any = useState(null);
  const [editObject, setEditObject]: any = useState(null);
  const [completeObject, setCompleteObject]: any = useState(null);
  const {
    toastMessage,
    playLottieAnimation,
    stopLottieAnimation,
    language,
    descEnFile,
    descFile,
  }: any = useContext(AppContext);
  const history = useHistory();
  const [ovanteData, setOvanteData]: any = useState([]);
  const [personalData, setPersonalData]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [completedLength, setCompletedLength] = useState(0);
  // let inputRef: any = React.useRef(null);
  const inputRef: any = useRef<HTMLInputElement>(null);

  useEffect(() => {
    getList();
    return () => {
      interval = null;
      completedLength = 0;
    };
  }, []);

  const getList = async () => {
    const resp: any = await getToDoList();
    if (!resp.error) {
      const personalTask: any = [];
      const ovanteTask: any = [];
      resp.map((item: any) => {
        if (item.type === "personal") {
          personalTask.push(item);
        } else {
          if (!item.isCompleted && !item.isReminder) {
            ovanteTask.push(item);
          }
        }
      });
      setOvanteData(ovanteTask);
      getSortedTodo(personalTask);
      const completeArray = resp.filter((item: any) => {
        return item.isCompleted == true;
      });
      completedLength = completeArray.length;
      console.log(completeArray);
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
    setLoading(false);
  };
  const onDueDateClick = async () => {
    let datepickerOptions: any = {
      format: "yyyy-MM-dd'T'HH:mm:ss.SSS",
      min: new Date().toISOString(),
      // date: "2021-05-18'T'10:09:22.987618",
    };

    // if (startDate) {
    //   let date = convertUTCToLocal(
    //     "2021-05-18 10:09:22.987618",
    //     "yyyy-MM-DD HH:mm:ss.SSS"
    //   );
    //   datepickerOptions["date"] = "2021-05-18'T'10:09:22.987618";
    // }
    const date: any = await openDatePicker(datepickerOptions);
    if (date) {
      setStartDate(convertLocalToUTC(date, "yyyy-MM-DD HH:mm:ss.SSS"));
    }
  };
  const getSortedTodo = (data: any) => {
    // const sortArray = data.sort((a: any, b: any) => {
    //   return a.isCompleted - b.isCompleted;
    // });
    setPersonalData(data);
  };
  const addToDo = async () => {
    setIsSubmitting(true);
    let reqObj = {
      title: todoText,
      dueDate: startDate,
      type: "personal",
    };
    const resp: any = await addTask(reqObj);
    if (!resp.error) {
      if (startDate) {
        localnotificationService.schedule({
          id: resp.id,
          title: "Reminder",
          text: todoText,
          autoClear: true,
          data: { type: "personal" },
          trigger: { at: new Date(convertUTCToLocal(startDate)) },
          priority: 2,
        });
      }
      getSortedTodo([resp, ...personalData]);
      setTodoText("");
      setStartDate(null);
    } else {
      toastMessage(Messages.ADD_TODO_ERROR, "primary");
    }

    setIsSubmitting(false);
  };

  const editToDo = async () => {
    setIsSubmitting(true);
    let reqObj = {
      title: todoText,
      dueDate: startDate,
      type: "personal",
    };
    const resp: any = await updateTask(editObject.id, reqObj);
    if (!resp.error) {
      localnotificationService.cancel(editObject.id);
      if (startDate) {
        localnotificationService.schedule({
          id: editObject.id,
          title: "Reminder",
          text: todoText,
          autoClear: true,
          data: { type: "personal" },
          trigger: { at: new Date(convertUTCToLocal(startDate)) },
          priority: 2,
        });
      }
      const tempTodo: any = personalData.map((item: any) => {
        if (item.id == resp.id) {
          return resp;
        }
        return item;
      });
      getSortedTodo(tempTodo);
      setEditObject(null);
      setTodoText("");
      setStartDate(null);
    } else {
      toastMessage(Messages.UPDATE_TODO_ERROR, "primary");
    }

    setIsSubmitting(false);
  };

  const handleEditTodo = async (todoItem: any) => {
    setEditObject(todoItem);
    setTodoText(todoItem.title);
    if (todoItem.dueDate && todoItem.dueDate != "") {
      setStartDate(todoItem.dueDate);
    } else {
      setStartDate(null);
    }
    const temp = await inputRef.current.getInputElement();
    temp.focus();
    // console.log("ref", await inputRef.current.getInputElement().focus());
    // inputRef.setFocus();
  };

  const completeTodo = async (todoItem: any) => {
    setCompleteObject(todoItem);
    const tempTodo: any = personalData.map((item: any) => {
      if (item.id == todoItem.id) {
        item.isCompleted = true;
      }
      return item;
    });
    getSortedTodo(tempTodo);
    // setCompleteObject(null);

    if (interval) {
      clearInterval(interval);
      interval = null;
    }
    interval = setInterval(() => {
      changeTodoStatus(todoItem);
      localnotificationService.cancel(todoItem.id);
      setCompleteObject(null);
      completedLength = completedLength + 1;
      if (completedLength === 5) {
        playLottieAnimation(TodoAnimationData);
        setTimeout(() => {
          stopLottieAnimation();
        }, 2000);
      }
      clearInterval(interval);
      interval = null;
    }, 3000);
  };

  const undoCompleteTodo = async () => {
    if (interval) {
      clearInterval(interval);
      interval = null;
      const tempTodo: any = personalData.map((item: any) => {
        if (item.id == completeObject.id) {
          item.isCompleted = false;
        }
        return item;
      });
      getSortedTodo(tempTodo);
      setCompleteObject(null);
    }
  };

  const changeTodoStatus = async (todoItem: any) => {
    const resp = await changeTaskStatus({
      id: todoItem.id,
      isCompleted: true,
    });
  };

  const onOvanteTaskClick = (item: any) => {
    history.push({
      pathname: `/activity/${item.activityId}`,
    });
  };
  return (
    <IonPage
      className={`page-to-do ${
        !personalData || (personalData && personalData.length === 0)
          ? "page-to-do-orange"
          : ""
      } ${
        ovanteData.length === 0 && personalData.length === 0
          ? "page-to-do-white"
          : ""
      }`}
    >
      <RegisterHeader
        pageName="To-Do List"
        textKey={"to-do_list"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen={true} className="page-content">
        {loading ? (
          <Loader />
        ) : (
          <>
            {/* {ovanteData.length === 0 && personalData.length === 0 && (
              <div className="task__title text-green font-medium">
                No task to show
              </div>
            )} */}
            {/* {ovanteData && ovanteData.length > 0 && ( */}
            <div className="page-content__ovante-task-container stretch-container">
              <div className="page-content__ovante-task stretch-container">
                <div className="page-content__task mb-20">
                  <LanguageAppText
                    tag={"div"}
                    className="task__title text-green font-medium"
                    value={"Ovante tasks"}
                    textKey={"ovante_tasks"}
                  />
                  <div className="task__count">
                    <InputText
                      name="level"
                      value={ovanteData.length}
                      readonly
                    ></InputText>
                  </div>
                </div>
                {ovanteData.map((item: any, index: number) => {
                  return (
                    <div
                      className=" mb-10"
                      onClick={() => {
                        onOvanteTaskClick(item);
                      }}
                    >
                      <div key={index} className="ovante-task__list">
                        <p className="task__name text-light-black ion-no-margin">
                          {item.title}
                        </p>

                        <IonImg src={PolygonIcon} className="task__icon" />
                      </div>
                      {item.dueDate && item.dueDate != "" && (
                        <div className="task__date text-orange text-light-black ion-no-margin">
                          {convertUTCToLocal(
                            item.dueDate,
                            "ddd, DD MMM, hh:mm a"
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            {/* )} */}

            <div className="page-content__personal-task stretch-container ">
              {/* {personalData && personalData.length > 0 && ( */}
              <div className="bg-white">
                <div className="page-content__task mb-20">
                  <LanguageAppText
                    tag={"div"}
                    className="task__title text-green font-medium"
                    value={"Personal tasks"}
                    textKey={"personal_tasks"}
                  />
                  {/* {personalData && personalData.length > 0 && ( */}
                  <div className="task__count">
                    <InputText
                      name="level"
                      value={personalData.length}
                      readonly
                    ></InputText>
                  </div>
                  {/* )} */}
                </div>
                <div className="page-content__task-list">
                  {personalData.map((item: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className={`personal-task__list mb-20 ${
                          item.isCompleted ? "task__checked" : ""
                        }`}
                      >
                        <IonCheckbox
                          slot="start"
                          className="checkbox"
                          checked={item.isCompleted}
                          disabled={item.isCompleted}
                          onClick={() => {
                            completeTodo(item);
                          }}
                        ></IonCheckbox>
                        <div
                          className="task-list__container"
                          onClick={() => {
                            if (!item.isCompleted) {
                              handleEditTodo(item);
                            }
                          }}
                        >
                          <div className="task__name text-light-black ion-no-margin">
                            {item.title}
                          </div>
                          {item.dueDate && item.dueDate != "" && (
                            <div className="task__date text-orange text-light-black ion-no-margin">
                              {convertUTCToLocal(
                                item.dueDate,
                                "ddd, DD MMM, hh:mm a"
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* )} */}
              {completeObject && (
                <ButtonComponent
                  name="Undo"
                  textKey={"undo"}
                  outline
                  customClass="task-undo__btn"
                  onClick={() => {
                    undoCompleteTodo();
                  }}
                />
              )}

              <div className="page-content__add-task stretch-container bg-white">
                {(todoText.length > 0 || editObject) && (
                  <div className="add-task">
                    <IonImg
                      src={AddTaskOrange}
                      className="add-task__icon-orange"
                    />
                    {/* <DatePicker
                      showTimeSelect
                      selected={startDate}
                      onChange={(date: Date) => {
                        setStartDate(date);
                      }}
                      dateFormat={"dd-MM-yyyy hh:mm:ss a"}
                      isClearable
                      // value={formatDate(startDate, "ddd, DD MMM, hh:mm a")}
                      customInput={
                        <div className="add_due-date text-black">
                          {startDate
                            ? formatDate(startDate, "ddd, DD MMM, hh:mm a")
                            : "Add Due Date (Optional)"}
                        </div>
                      }
                    /> */}
                    <div
                      onClick={onDueDateClick}
                      className="add_due-date text-black"
                    >
                      {startDate
                        ? convertUTCToLocal(startDate, "ddd, DD MMM, hh:mm a")
                        : descFile["add_due_date"] ||
                          descEnFile["add_due_date"] ||
                          "Add Due Date (Optional)"}
                    </div>
                    {startDate && (
                      <div
                        onClick={() => {
                          setStartDate(null);
                        }}
                        className="close-date"
                      >
                        X
                      </div>
                    )}
                  </div>
                )}
                <div className="add-task">
                  {todoText.length == 0 && !editObject && (
                    <IonImg src={AddTask} className="add-task__icon" />
                  )}
                  <IonItem className="ion-item item-label-stacked">
                    <IonTextarea
                      ref={inputRef}
                      name="todo"
                      value={todoText}
                      placeholder={
                        descFile["add_a_task"] ||
                        descEnFile["add_a_task"] ||
                        "Add a task"
                      }
                      // multiple={true}
                      autoGrow
                      rows={1}
                      cols={3}
                      onKeyUp={(e: any) => {
                        if (e?.key === "Enter" && todoText.trim().length > 0) {
                          if (editObject) {
                            editToDo();
                          } else {
                            addToDo();
                          }
                        }
                      }}
                      className={`${
                        todoText.length == 0
                          ? "add-task__title text-gray-slate"
                          : "add-task__name text-black add-tick"
                      }`}
                      onIonChange={(e: any) => {
                        setTodoText(e.detail.value || "");
                      }}
                    ></IonTextarea>
                  </IonItem>

                  {todoText.trim().length > 0 &&
                    (isSubmitting ? (
                      <Loader />
                    ) : (
                      <IonImg
                        src={AddTick}
                        className="add-tick"
                        onClick={() => {
                          if (editObject) {
                            editToDo();
                          } else {
                            addToDo();
                          }
                        }}
                      />
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ToDo;

import { IonContent, IonImg, IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import LanguageAppText from "../../components/languageapptext";
import ErrorRetrySvg from "../../assets/images/error_retry.svg";
import "./style.scss";
const NetworkError: React.FC = () => {
  const history = useHistory();
  const onRetry = () => {
    history.goBack();
  };
  return (
    <IonPage className="page-network-error">
      <IonContent className="page-content">
        <div className="page-content-network-error">
          <IonImg src={ErrorRetrySvg} className="ion-popup__success-icon mb-20" />
          <LanguageAppText
            tag="div"
            value={"Unable to reach Ovante"}
            className="network-error__title text-deep-black font-bold mb-10"
            textKey={"unable_to_reach"}
          />
          <LanguageAppText
            tag="div"
            value={"There was a network problem. Please try again."}
            className="network-error__detail-text text-light-black font-medium mb-20"
            textKey={"network_try_again"}
          />

          <ButtonComponent
            name="Retry"
            textKey={"retry"}
            onClick={onRetry}
            customClass="network-error-btn"
          ></ButtonComponent>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NetworkError;

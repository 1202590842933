import { IonContent, IonImg, IonPage, IonThumbnail } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import Loader from "../../components/common/loader";
import Popup from "../../components/common/popup";
import { AppContext } from "../../context/app.context";
import { profileSummary } from "../../services/user.service";
import Success from "../../assets/images/icons/success-icon.svg";
import Coin from "../../assets/images/coin.svg";
import "./style.scss";
import BackButton from "../../components/common/button-component/back";
import { getRewardDetails, redeemReward } from "../../services/reward.service";
import LanguageText from "../../components/languagetext";
import { getFormattedUrl } from "../../utils/common.helper";
import LanguageAppText from "../../components/languageapptext";

const RewardDetails = () => {
  const { type, id }: any = useParams();
  const history: any = useHistory();
  const { toastMessage, language }: any = useContext(AppContext);
  const [userDetails, setUserDetails]: any = useState(null);
  const [rewardDetails, setRewardDetails]: any = useState(null);
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [bannerImage, setBannerImage]: any = useState(null);

  useEffect(() => {
    setTimeout(() => {
      Promise.all([fetchProfileDetails(), fetchRewardDetails()]).then(() => {
        setLoading(false);
      });
    }, 500);
  }, []);

  const fetchProfileDetails = async () => {
    const resp: any = await profileSummary();
    if (!resp.error) {
      setUserDetails(resp);
    } else {
      setLoading(false);
      toastMessage(resp.message, "primary");
    }
  };

  const fetchRewardDetails = async () => {
    const resp: any = await getRewardDetails(id);
    if (!resp.error) {
      setRewardDetails(resp);
      setBannerImage(JSON.parse(resp.bannerImage));
    } else {
      setLoading(false);
      toastMessage(resp.message, "primary");
    }
  };

  const handleConfirmClick = async () => {
    setIsConfirming(true);
    const resp: any = await redeemReward(rewardDetails.rewardId);
    if (!resp.error) {
      history.replace(`/rewardinfo/redeemed/${rewardDetails.rewardId}`);
    } else {
      setIsConfirming(false);
      handleToggleModal();
      toastMessage(resp.message, "primary");
    }
  };

  const handleToggleModal = () => {
    setShowConfirm(!showConfirm);
  };

  return (
    <IonPage className="page-reward-detail">
      <IonContent className="page-content pb-0">
        <div className="page-content__header-container stretch-container">
          <div className="page-content__header">
            <BackButton />
            {userDetails && (
              <div className="page-content__available-coins bg-white">
                <IonImg src={Coin} className="coin-img" />
                <LanguageAppText
                  tag={"div"}
                  className="available-coins__text text-black font-bold"
                  value={`Available Coins: ${userDetails.availableCoin}`}
                  textKey={"available_coins"}
                  metaData={{ userCoinsAvailable: userDetails.availableCoin }}
                />
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <Loader customClass="loader-page-center"></Loader>
        ) : (
          rewardDetails && (
            <>
              <div className="reward-brand__img-container">
                <IonThumbnail slot="start" className="reward-brand__img">
                  <img
                    className=""
                    src={getFormattedUrl(
                      bannerImage[language]
                        ? bannerImage[language]["uri"]
                        : bannerImage["en"]["uri"]
                    )}
                  />
                </IonThumbnail>
                {/* <IonImg
                  className="reward-brand__img"
                  src={getFormattedUrl(
                    bannerImage[language]
                      ? bannerImage[language]["uri"]
                      : bannerImage["en"]["uri"]
                  )}
                ></IonImg> */}
              </div>
              <div className="reward-detail__container mt-20">
                <LanguageText
                  tag={"div"}
                  className="reward-detail__name text-deep-black font-bold"
                  value={rewardDetails.name}
                ></LanguageText>
                {(userDetails.coinCount < rewardDetails.coinsRequired ||
                  type == "view") && (
                  <div className="reward-detail__coins mt-5">
                    <IonImg src={Coin} className="coin-img" />
                    <LanguageAppText
                      tag={"div"}
                      className="reward-detail__coins-text font-medium text-pitch-black"
                      value={`${rewardDetails.coinsRequired} Coins`}
                      textKey={"reward_coins"}
                      metaData={{ rewardCoins: rewardDetails.coinsRequired }}
                    />
                    {/* <div className="reward-detail__coins-text font-medium text-pitch-black">
                      {rewardDetails.coinsRequired} Coins
                    </div> */}
                  </div>
                )}
                <LanguageText
                  tag={"div"}
                  className="reward-detail__description text-light-black font-medium mt-10"
                  value={rewardDetails.description}
                ></LanguageText>
                {type != "view" ? (
                  userDetails.availableCoin >= rewardDetails.coinsRequired ? (
                    <div className="mt-10">
                      <ButtonComponent
                        name={`Redeem Now for ${rewardDetails.coinsRequired} Coins`}
                        customClass="reward-detail__btn font-medium"
                        onClick={handleToggleModal}
                        textKey={"redeem_reward_coins"}
                        metaData={{ rewardCoins: rewardDetails.coinsRequired }}
                      ></ButtonComponent>
                      <LanguageAppText
                        tag={"div"}
                        className="reward-detail__balance-text text-black mt-10 font-bold"
                        value={`Coin balance after redeeming:${" "} ${
                          userDetails.availableCoin -
                          rewardDetails.coinsRequired
                        }`}
                        textKey={"coins_after_redeeming"}
                        metaData={{
                          userCoinsBalance:
                            userDetails.availableCoin -
                            rewardDetails.coinsRequired,
                        }}
                      />
                      {/* <div className="">
                        Coin balance after redeeming:{" "}
                        {userDetails.availableCoin -
                          rewardDetails.coinsRequired}
                      </div> */}
                    </div>
                  ) : (
                    <LanguageAppText
                      tag={"div"}
                      className="reward-detail__enough-coin font-medium mt-10 mb-20 text-light-maroon"
                      value={
                        "You do not have enough coins yet to redeem this reward"
                      }
                      textKey={"not_enough_coins"}
                    />
                  )
                ) : null}
              </div>
            </>
          )
        )}
        {showConfirm && (
          <Popup customClass="reward-popup" onClose={handleToggleModal}>
            <IonImg src={Success} className="ion-popup__success-icon" />
            <LanguageAppText
              tag={"h1"}
              className="ion-popup__success-text m0 p0 mb-5 font-bold"
              value={"Confirm Purchase"}
              textKey={"confirm_purchase"}
            />
            <LanguageAppText
              tag={"span"}
              className="ion-popup__text m0 p0 font-medium"
              value={"You are about to redeem "}
              textKey={"redeem_reward"}
            />
            <LanguageText
              tag="span"
              className="font-bold"
              value={rewardDetails.name}
            ></LanguageText>
            <LanguageAppText
              tag={"span"}
              className="ion-popup__text m0 p0 font-medium"
              value={" for {rewardCoin} Coins."}
              textKey={"redeem_for"}
              metaData={{ rewardCoin: rewardDetails.coinsRequired }}
            />
            {!isConfirming ? (
              <ButtonComponent
                name="Confirm"
                textKey={"confirm"}
                onClick={handleConfirmClick}
                customClass="reward-popup-btn font-medium mb-15"
              ></ButtonComponent>
            ) : (
              <div className="mb-20">
                <Loader></Loader>
              </div>
            )}
            <div
              className="ion-popup__no-reward text-light-blue font-medium"
              onClick={handleToggleModal}
            >
              <LanguageAppText
                tag={"span"}
                value={"No, I don’t want this reward"}
                textKey={"dont_want_reward"}
              />
            </div>
          </Popup>
        )}
      </IonContent>
    </IonPage>
  );
};

export default RewardDetails;

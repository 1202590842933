import "./style.scss";

import {
  IonButton,
  IonContent,
  IonImg,
  IonLabel,
  IonList,
  IonPage,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import RegisterHeader from "../../components/common/register-header";

const MyRewards: React.FC = () => {
  return (
    <IonPage className="page-my-rewards">
        <RegisterHeader pageName="Rewards" textKey={"rewards"} backBtn={false}></RegisterHeader>
      <IonContent className="page-content">
      </IonContent>
    </IonPage>
  );
};

export default MyRewards;

import { IonContent, IonPage } from "@ionic/react";
import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Loader from "../../components/common/loader";
import {
  ACCESS_TOKEN,
  APP_DEFAULT_SCREEN,
  INIT_STATE_APP,
} from "../../config/appconfig";
import Messages from "../../config/messages";
import { AppContext } from "../../context/app.context";
import { verifyPartnerUser } from "../../services/auth.service";
import { getLangDescriptor } from "../../services/lang-descriptor.service";
import { isConnectedToInternet } from "../../services/network.service";
import storageSevice from "../../services/storage.sevice";
import {
  getPartner,
  userLoginCount,
  userProfile,
} from "../../services/user.service";
import { getQueryStrings } from "../../utils/common.helper";
import "./style.scss";

const Splash: React.FC = () => {
  const {
    hasToken,
    setAppState,
    language,
    getDescriptorFile,
    setUserDetails,
    toastMessage,
    setHomeConfig,
  }: any = useContext(AppContext);
  const history = useHistory();
  const { search }: any = useLocation();
  const queryParam = getQueryStrings(search);
  useEffect(() => {
    if (!isConnectedToInternet()) {
      history.push({
        pathname: "/networkerror",
      });
      return;
    }
    checkLang();
    loginCount();
  }, []);

  const checkLang = async () => {
    await getLangDescriptor(language);
    await getDescriptorFile(language);
    redirect();
  };

  const loginCount = async () => {
    if (hasToken === "true") {
      const resp = await userLoginCount();
    }
  };

  const redirect = () => {
    setTimeout(async () => {
      const resp: any = await verifyPartnerUser({
        token: queryParam.token,
        partnerId: queryParam.partnerId,
      });
      if (!resp.error) {
        await storageSevice.set(ACCESS_TOKEN, resp);
        const respProfile: any = await userProfile();
        const respPartner: any = await getPartner();
        if (!respProfile.error && !respPartner.error) {
          setHomeConfig(respPartner.homeConfig);
          setUserDetails(respProfile);
          setAppState(INIT_STATE_APP);
          history.replace({
            pathname: APP_DEFAULT_SCREEN,
          });
        } else {
          toastMessage(resp.message || Messages.SERVER_ERROR, "primary");
        }
      } else {
        toastMessage(resp.message || Messages.SERVER_ERROR, "primary");
      }
    }, 3000);
  };

  return (
    <IonPage className="page-splash">
      <IonContent fullscreen className="page-content">
        <div className="page-splash-content">
          {/* <IonImg src={SplashLogo} className="splash-logo mb-10" /> */}
          <div
            className="text-spider-black font-medium mb-10"
            style={{ color: "white" }}
          >
            Verifying User, Please Wait...
          </div>
          <Loader />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Splash;

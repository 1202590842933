import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import FormGroup from "../../components/common/formgroup";
import InputText from "../../components/common/inputtext";
import RegisterHeader from "../../components/common/register-header";
import ValidationMessage from "../../components/common/validation-message";
import LanguageAppText from "../../components/languageapptext";
import { AppContext } from "../../context/app.context";
import { CheckPhoneSchema } from "../../schemas/auth.schema";
import { changePreferredNumebr } from "../../services/user.service";
import {
  formatPhoneForForm,
  formatPhoneNumber,
  formatPhoneToReadable,
  handleOnValueChange,
  isObjNullOrEmpty,
} from "../../utils/common.helper";
import "./style.scss";

const ChangePreferredNumber: React.FC = () => {
  const { toastMessage, userDetails }: any = useContext(AppContext);
  const history: any = useHistory();
  const { state }: any = useLocation();
  const initialValues = {
    phoneNumber: state
      ? formatPhoneForForm(state.phoneNumber)
      : userDetails
      ? formatPhoneForForm(userDetails.phoneNumber1)
      : "",
  };
  const handleChangePreferredNumber = async (values: any, actions: any) => {
    const response: any = await changePreferredNumebr({
      phoneNumber: formatPhoneToReadable(values.phoneNumber),
    });
    if (!response.error) {
      history.replace({
        pathname: "/confirmotp",
        state: {
          from: "changenumber",
          otp: response,
          phoneNumber: formatPhoneToReadable(values.phoneNumber),
        },
      });
    } else {
      toastMessage(response.message, "primary");
      actions.setFieldError("phoneNumber", response.message);
    }
  };

  return (
    <IonPage className="page-login-with-phone">
      <RegisterHeader
        pageName="Change Number"
        textKey={"change_phone"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen className="page-content">
        <div className="page-content">
          <LanguageAppText
            tag={"h1"}
            className="page-content__title p0"
            value={"Change phone number"}
            textKey={"change_phone"}
          />
          <Formik
            enableReinitialize={false}
            initialValues={initialValues}
            validationSchema={CheckPhoneSchema}
            onSubmit={handleChangePreferredNumber}
          >
            {({ values, setFieldValue, isValid, dirty, errors }) => (
              <Form>
                <FormGroup>
                  <InputText
                    label="Enter your phone number"
                    labelKey={"enter_your_phone"}
                    name="phoneNumber"
                    value={formatPhoneNumber(values.phoneNumber)}
                    onChange={(val: string) => {
                      handleOnValueChange("phoneNumber", val, setFieldValue);
                    }}
                    placeholder="Enter phone number"
                    placeholderKey={"enter_phone"}
                    maxlength={11}
                    prefix={"+91"}
                    inputMode={"numeric"}
                  ></InputText>
                  <ValidationMessage name="phoneNumber"></ValidationMessage>
                </FormGroup>
                <FormGroup>
                  <div className="page-content__button">
                    <ButtonComponent
                      name="Next"
                      textKey={"next"}
                      isEnabled={
                        !isObjNullOrEmpty(errors) || values.phoneNumber == ""
                      }
                    />
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChangePreferredNumber;

import { IonAvatar, IonButton, IonImg, IonItemDivider } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import LeaderboardCardImg from "../../assets/images/icons/profile-placeholder.svg";

import Coin from "../../assets/images/coin.svg";
import ButtonComponent from "../common/button-component";
import { socialSharing } from "../../utils/common.helper";
import { getLeaderBoardShareImage } from "../../services/leaderboard.service";
import { AppContext } from "../../context/app.context";
import Messages from "../../config/messages";
import Loader from "../common/loader";
interface Props {
  isSelfPosition?: boolean;
  coinCount?: number;
  hideRank?: boolean;
  hideShare?: boolean;
  isFloating?: boolean;
  userName?: string;
  rank?: number;
  customClass?: string;
  imgPath?: any;
  observer?: any;
  id?: any;
}

const LeaderboardCard: React.FC<Props> = ({
  isSelfPosition,
  coinCount,
  hideRank,
  hideShare,
  isFloating,
  userName,
  rank,
  customClass,
  imgPath,
  observer,
  id,
}) => {
  const [mediaPath, setMediaPath] = useState(imgPath);
  const [ref, setRef]: any = React.useState(null);
  const { toastMessage }: any = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (imgPath) {
      setMediaPath(imgPath);
    }
  }, [imgPath]);

  // Observe and unobserve this paragraph
  React.useEffect(() => {
    if (ref && observer) {
      observer.observe(ref);
    }
    return () => {
      if (ref && observer) {
        observer.unobserve(ref);
      }
    };
  }, [observer, ref]);

  const onShareClick = async () => {
    setLoading(true);
    const resp: any = await getLeaderBoardShareImage(coinCount || 0);
    setLoading(false);
    if (!resp.error) {
      socialSharing(
        "I made it to the Ovante leaderboard!",
        undefined,
        resp,
        "https://ovante.org/redirect/3h4jks"
      );
    } else {
      toastMessage(Messages.SERVER_ERROR, "primary");
    }
  };

  return (
    <div
      ref={setRef}
      data-id={id}
      className={`leaderboard-card__container mb-10 ${
        isSelfPosition ? "leaderboard-card__my-profile" : ""
      } ${
        isSelfPosition && !hideShare ? "leaderboard-card__share mb-30" : ""
      } ${isSelfPosition && hideRank ? "leaderboard-card__hide-rank" : ""} ${
        isSelfPosition && isFloating ? "leaderboard-card__floated mb-0" : ""
      } ${customClass || ""}`}
    >
      {!hideRank && (
        <div className="leaderboard-card__number font-medium text-green mr-10">
          {rank}
        </div>
      )}
      <div className="leaderboard-card bg-white">
        <div className="leaderboard-card-img__container mr-10">
          <IonAvatar className="ion-avatar-leaderboard">
            <IonImg
              src={mediaPath || LeaderboardCardImg}
              className="leaderboard-card-img"
            />
          </IonAvatar>
        </div>
        <div className="leaderboard-user-name__container">
          {isSelfPosition && (
            <div className="leaderboard-user-my-name font-medium text-orange">
              You
            </div>
          )}
          <div className="leaderboard-user-name font-medium text-green">
            {userName}
          </div>
        </div>
        <div className="leaderboard-coin__container">
          <IonImg src={Coin} className="coin-img" />
          <div className="available-coins__text text-green font-bold ml-6">
            {coinCount}
          </div>
        </div>
        {isSelfPosition &&
          !hideShare &&
          (loading ? (
            <Loader customClass="leaderboard-share-btn" />
          ) : (
            <ButtonComponent
              name="Share"
              customClass="leaderboard-share-btn"
              onClick={() => {
                onShareClick();
              }}
              textKey={"share"}
            ></ButtonComponent>
          ))}
      </div>
    </div>
  );
};
export default LeaderboardCard;

import { IonAvatar, IonButton, IonImg, IonItemDivider, IonThumbnail } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import LeaderboardDefault from "../../assets/images/icons/profile-placeholder.svg";

interface Props {
  rank: number;
  customClass?: string;
  imgPath?: any;
}

const TopThreeProfile: React.FC<Props> = ({ rank, customClass, imgPath }) => {
  const [mediaPath, setMediaPath] = useState(imgPath);
  useEffect(() => {
    if (imgPath) {
      setMediaPath(imgPath);
    }
  }, [imgPath]);

  return (
    <div className={`leaderboard-profile__container  ${customClass || ""}`}>
      <div className="leaderboard-profile-img__container">
        <div className="mask">
          <IonThumbnail className="leaderboard-profile-img">
            <img src={mediaPath || LeaderboardDefault} />
          </IonThumbnail>
        </div>
        <div className="leaderboard-number font-bold text-white">{rank}</div>
        {/* <IonAvatar className="ion-avatar-profile-img">
          <IonImg
            src={mediaPath || LeaderboardDefault}
            className="leaderboard-profile-img"
          />
          <div className="leaderboard-number font-bold text-white">{rank}</div>
        </IonAvatar> */}
      </div>
    </div>
  );
};
export default TopThreeProfile;

import { IonContent, IonImg, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useIonRouter } from "@ionic/react";
import { Plugins } from "@capacitor/core";
import RegisterHeader from "../../../components/common/register-header";
import Loader from "../../../components/common/loader";
import { profileSummary } from "../../../services/user.service";
import { AppContext } from "../../../context/app.context";
import RewardsCard from "../../../components/rewardcard";
import { getAllRewards } from "../../../services/reward.service";
import Coin from "../../../assets/images/coin.svg";
import ViewReward from "../../../assets/images/quick-link-rewards.svg";
import { useHistory } from "react-router";
import LanguageAppText from "../../../components/languageapptext";
const { App } = Plugins;

const Rewards: React.FC = () => {
  const history: any = useHistory();
  const { toastMessage }: any = useContext(AppContext);
  const [userDetails, setUserDetails]: any = useState(null);
  const [lockedRewards, setLockedRewards]: any = useState([]);
  const [availableRewards, setAvailableRewards]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [redeemCount, setRedeemCount] = useState(0);

  const ionRouter = useIonRouter();
  useEffect(() => {
    setTimeout(() => {
      Promise.all([fetchProfileDetails(), fetchRewards()]).then(() => {
        setLoading(false);
      });
    }, 500);

    document.addEventListener("ionBackButton", handleBack);

    return () => {
      document.removeEventListener("ionBackButton", handleBack);
    };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(100, () => {
      App.exitApp();
    });
  };

  const fetchProfileDetails = async () => {
    const resp: any = await profileSummary();
    if (!resp.error) {
      setUserDetails(resp);
    } else {
      setLoading(false);
      toastMessage(resp.message, "primary");
    }
  };

  const fetchRewards = async () => {
    const resp: any = await getAllRewards();
    if (!resp.error) {
      const availableRewards = resp.availableRewards.filter(
        (item: any) => item.redeemedStatus == "false"
      );
      console.log(resp.availableRewards.length - availableRewards.length);
      setRedeemCount(resp.availableRewards.length - availableRewards.length);
      setAvailableRewards(availableRewards);
      setLockedRewards(resp.lockedRewards);
    } else {
      setLoading(false);
      toastMessage(resp.message, "primary");
    }
  };

  const handleRewardClick = (id: number, type: string) => {
    history.push(`/rewarddetails/${type}/${id}`);
  };

  const viewReedemedRewards = () => {
    history.push(`/redeemedreward`);
  };

  const renderAvailableRewards = () => {
    return availableRewards.map((item: any, index: number) => {
      return (
        <RewardsCard
          key={item.rewardId}
          name={item.name}
          coinsRequired={item.coinsRequired}
          imgPath={item.iconImage}
          onClick={() => {
            handleRewardClick(item.rewardId, "redeem");
          }}
        ></RewardsCard>
      );
    });
  };

  const renderLockedRewards = () => {
    return lockedRewards.map((item: any, index: number) => {
      return (
        <RewardsCard
          key={item.rewardId}
          name={item.name}
          coinsRequired={item.coinsRequired}
          imgPath={item.iconImage}
          onClick={() => {
            handleRewardClick(item.rewardId, "view");
          }}
          isLocked={true}
        ></RewardsCard>
      );
    });
  };

  return (
    <IonPage
      className={`page-rewards ${
        lockedRewards.length === 0 ? "page-rewards-bg-light-orange" : ""
      }`}
    >
      {/* <RegisterHeader pageName={"Rewards"}></RegisterHeader> */}
      <IonContent className="page-content pb-85 pt-0">
        <RegisterHeader
          pageName={"Rewards"}
          textKey={"rewards"}
          customClass="page-rewards__register-header stretch-container"
        ></RegisterHeader>
        {loading ? (
          <Loader customClass="loader-page-center"></Loader>
        ) : (
          <>
            <div className="page-content__available-coins bg-white">
              <IonImg src={Coin} className="coin-img" />
              <LanguageAppText
                tag={"div"}
                className="available-coins__text text-black font-bold"
                value={`Available Coins: ${userDetails.availableCoin}`}
                textKey={"available_coins"}
                metaData={{ userCoinsAvailable: userDetails.availableCoin }}
              />
            </div>
            <div style={{ position: "relative", top: "60px" }}>
              <div className="page-rewards-container stretch-container">
                <LanguageAppText
                  tag={"div"}
                  className="rewards-available font-medium text-blue mb-5"
                  value={"Available rewards"}
                  textKey={"available_rewards"}
                />
                <LanguageAppText
                  tag={"div"}
                  className="rewards-available__description text-gray mb-20"
                  value={"Use your coins to redeem these rewards!"}
                  textKey={"redeem_these_rewards"}
                />
                <>{renderAvailableRewards()}</>
                {redeemCount > 0 && (
                  <div onClick={viewReedemedRewards}>
                    <RewardsCard
                      name="View your rewards"
                      nameKey="view_your_rewards"
                      isViewCard={true}
                      redeemedCount={redeemCount}
                      imgPath={ViewReward}
                      customClass="view-reward"
                    ></RewardsCard>
                  </div>
                )}
              </div>
              {lockedRewards.length > 0 && (
                <div className="page-rewards-lock-container stretch-container bg-white">
                  <LanguageAppText
                    tag={"div"}
                    className="lock-reward font-medium text-green mb-10"
                    value={"Locked rewards"}
                    textKey={"locked_rewards"}
                  />
                  <LanguageAppText
                    tag={"div"}
                    className="lock-rewards__description text-gray mb-15"
                    value={`Access these rewards once you reach level ${" "} ${
                      userDetails.level + 1
                    }!`}
                    textKey={"level_next_rewards"}
                    metaData={{ levelNext: userDetails.level + 1 }}
                  />
                  <>{renderLockedRewards()}</>
                </div>
              )}
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Rewards;

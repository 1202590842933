import { IonContent, IonPage } from "@ionic/react";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import ButtonComponent from "../../components/common/button-component";
import FormGroup from "../../components/common/formgroup";
import InputText from "../../components/common/inputtext";
import RegisterHeader from "../../components/common/register-header";
import ValidationMessage from "../../components/common/validation-message";
import LanguageAppText from "../../components/languageapptext";
import { ChangePhoneNumbersSchema } from "../../schemas/auth.schema";
import {
  formatPhoneForForm,
  formatPhoneNumber,
  formatPhoneToReadable,
  handleOnValueChange,
  isObjNullOrEmpty,
} from "../../utils/common.helper";
import "./style.scss";

const ChangeNumbers: React.FC = () => {
  const history: any = useHistory();
  const { state }: any = useLocation();
  const phoneNumbers = state.phoneNumbers && JSON.parse(state.phoneNumbers);
  const [btnPos, setBtnPos] = useState(false);
  console.log("change", phoneNumbers, state.phoneNumbers);
  const initialValues = {
    validatePhone1: phoneNumbers && phoneNumbers.phoneNumber1 ? true : false,
    phoneNumber1:
      phoneNumbers && phoneNumbers.phoneNumber1
        ? formatPhoneForForm(phoneNumbers.phoneNumber1)
        : "",
    validatePhone2: phoneNumbers && phoneNumbers.phoneNumber2 ? true : false,
    phoneNumber2:
      phoneNumbers && phoneNumbers.phoneNumber2
        ? formatPhoneForForm(phoneNumbers.phoneNumber2)
        : "",
    validatePhone3: phoneNumbers && phoneNumbers.phoneNumber3 ? true : false,
    phoneNumber3:
      phoneNumbers && phoneNumbers.phoneNumber3
        ? formatPhoneForForm(phoneNumbers.phoneNumber3)
        : "",
  };
  const handleNext = (values: any, actions: any) => {
    const phoneNumbers: any = {
      phoneNumber1: formatPhoneToReadable(values.phoneNumber1),
    };
    if (values.phoneNumber2 != "") {
      phoneNumbers["phoneNumber2"] = formatPhoneToReadable(values.phoneNumber2);
    }
    if (values.phoneNumber3 != "") {
      phoneNumbers["phoneNumber3"] = formatPhoneToReadable(values.phoneNumber3);
    }
    history.replace({
      pathname: "/prefferednumber",
      state: {
        phoneNumbers: JSON.stringify(phoneNumbers),
      },
    });
  };

  const bottomBtnPos = () => {
    setBtnPos(true);
  };
  const hideBtnPos = () => {
    setBtnPos(false);
  };

  return (
    <IonPage className={`page-change-number ${btnPos ? "pos-btn" : ""}`}>
      <RegisterHeader
        pageName="Get started"
        textKey={"get_started"}
        backBtn={true}
      ></RegisterHeader>
      <IonContent fullscreen className="page-content">
        <div>
          <LanguageAppText
            tag={"h1"}
            className="page-content__title"
            value={"Please check all your phone numbers."}
            textKey={"check_all_phone"}
          />
          <Formik
            enableReinitialize={false}
            initialValues={initialValues}
            validationSchema={ChangePhoneNumbersSchema}
            onSubmit={handleNext}
          >
            {({ values, setFieldValue, isValid, dirty, errors }) => (
              <Form>
                <FormGroup>
                  <InputText
                    label="Phone number 1"
                    labelKey={"phone_number_1"}
                    name="phoneNumber1"
                    value={formatPhoneNumber(values.phoneNumber1)}
                    onChange={(val: string) => {
                      handleOnValueChange("phoneNumber1", val, setFieldValue);
                    }}
                    maxlength={11}
                    prefix={"+91"}
                    inputMode={"numeric"}
                    onFocus={bottomBtnPos}
                    onBlur={hideBtnPos}
                  ></InputText>
                  <ValidationMessage name="phoneNumber1"></ValidationMessage>
                </FormGroup>

                <FormGroup>
                  <InputText
                    label="Phone number 2"
                    labelKey={"phone_number_2"}
                    name="phoneNumber2"
                    value={formatPhoneNumber(values.phoneNumber2)}
                    onChange={(val: string) => {
                      handleOnValueChange("phoneNumber2", val, setFieldValue);
                    }}
                    maxlength={11}
                    prefix={"+91"}
                    inputMode={"numeric"}
                    onFocus={bottomBtnPos}
                    onBlur={hideBtnPos}
                  ></InputText>
                  <ValidationMessage name="phoneNumber2"></ValidationMessage>
                </FormGroup>

                <FormGroup>
                  <InputText
                    label="Phone number 3"
                    labelKey={"phone_number_3"}
                    name="phoneNumber3"
                    value={formatPhoneNumber(values.phoneNumber3)}
                    onChange={(val: string) => {
                      handleOnValueChange("phoneNumber3", val, setFieldValue);
                    }}
                    maxlength={11}
                    prefix={"+91"}
                    inputMode={"numeric"}
                    onFocus={bottomBtnPos}
                    onBlur={hideBtnPos}
                  ></InputText>
                  <ValidationMessage name="phoneNumber3"></ValidationMessage>
                </FormGroup>

                <FormGroup>
                  <div className="page-content__button">
                    <ButtonComponent
                      name="Next"
                      isEnabled={!isObjNullOrEmpty(errors)}
                      textKey={"next"}
                    />
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChangeNumbers;

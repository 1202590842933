import React, { useEffect, useState } from "react";
import { useIonToast } from "@ionic/react";
import storageSevice from "../services/storage.sevice";
import {
  ACCESS_TOKEN,
  INIT_STATE_APP,
  INIT_STATE_ONBOARD,
  SELECTED_LANG,
  USER_SETTINGS,
} from "../config/appconfig";
import { Capacitor } from "@capacitor/core";
import { getPartner, userProfile } from "../services/user.service";
// import { getSelectedLang, seti18nLocal } from '../services/translation.service'
import { Plugins } from "@capacitor/core";
import { getFileDirectory, getStringByLanguage } from "../utils/common.helper";
import { File } from "@ionic-native/file";
import { getDescriptor } from "../services/lang-descriptor.service";
import httpService from "../services/http.service";
import { getLanguages } from "../services/app.service";

const { App } = Plugins;
export const AppContext = React.createContext({});

type Props = {
  children: any;
};

export const AppProvider = (props: Props) => {
  const [present, dismiss] = useIonToast();
  const [themeType, setThemeType] = useState("light");
  const [language, setLanguage] = useState("en");
  const [settings, setSettings] = useState(null);
  const [lottieOption, setLottieOption]: any = useState(null);
  const [isLottiePlay, setIsLottiePlay] = useState(false);
  const [hasToken, setHasToken] = useState("");
  const [initAppState, setInitAppState] = useState(INIT_STATE_ONBOARD);
  const [userDetails, setUserDetails]: any = useState(null);
  const [isBackground, setIsBackground] = useState(false);
  const [descEnFile, setDescEnFile]: any = useState({});
  const [descFile, setDescFile]: any = useState({});
  const [homeConfig, setHomeConfig]: any = useState({});
  const [languagesList, setLanguagesList] = useState([]);

  useEffect(() => {
    // getUserDetails();
    fetchLangauages();
    getLang();
    userSettings();
    checkTokenAvailable();
    getHomeConfig();
    App.addListener("appStateChange", ({ isActive }) => {
      setIsBackground(!isActive);
    });
  }, []);

  useEffect(() => {
    getHomeConfig();
  }, [hasToken]);

  const fetchLangauages = async () => {
    let resp: any = await getLanguages();
    if (!resp.error) {
      resp = resp.filter((item: any) => {
        return item.isActive;
      });
      setLanguagesList(resp);
    }
  };

  const getHomeConfig = async () => {
    if (hasToken == "true") {
      const respPartner: any = await getPartner();
      if (!respPartner.error) {
        setHomeConfig(respPartner.homeConfig);
      }
    }
  };
  const getDescriptorFile = async (langaugeCode: string) => {
    try {
      const descResp: any = await getDescriptor("en");
      if (!descResp.error) {
        let parsedFilePath = JSON.parse(descResp.filePath);
        let desc_url = `/file/download/${parsedFilePath.uri}`;
        const resp: any = await httpService.execute({ url: desc_url });

        setDescEnFile(resp);
      }
    } catch (error) {
      console.log("en lang error", error);
    }

    try {
      const descResp: any = await getDescriptor(langaugeCode);
      if (!descResp.error) {
        let parsedFilePath = JSON.parse(descResp.filePath);
        let desc_url = `/file/download/${parsedFilePath.uri}`;
        const resp: any = await httpService.execute({ url: desc_url });

        setDescFile(resp);
      }
    } catch (error) {
      console.log("en lang error", error);
    }
  };
  const checkTokenAvailable = async () => {
    const token = await storageSevice.get(ACCESS_TOKEN);
    setHasToken(token ? "true" : "false");
  };

  const userSettings = async () => {
    const settingsVal = await storageSevice.get(USER_SETTINGS);
    if (settingsVal) {
      setSettings(JSON.parse(settingsVal));
    }
  };

  const toastMessage = (message: string, variant = "default") => {
    message = getStringByLanguage(message, descFile, descEnFile);
    present({
      message: message,
      color: variant,
      duration: 2000,
      cssClass: Capacitor.getPlatform() != "web" ? "toast-message" : "",
    });
  };

  const playLottieAnimation = (animationData: any) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    setLottieOption(defaultOptions);
    setIsLottiePlay(true);
  };

  const stopLottieAnimation = () => {
    setLottieOption(null);
    setIsLottiePlay(false);
  };

  const getLang = async () => {
    const lang = await storageSevice.get(SELECTED_LANG);
    if (lang) {
      setLanguage(lang);
      await getDescriptorFile(lang);
    } else {
      setLanguage("en");
      await getDescriptorFile("en");
    }
  };

  const lanChange = async (lang: string) => {
    // seti18nLocal(lang)
    await storageSevice.set(SELECTED_LANG, lang);
    setLanguage(lang);
  };

  const setAppState = (state: string) => {
    setInitAppState(state);
  };
  return (
    <>
      {hasToken !== "" && (
        <AppContext.Provider
          value={{
            themeType,
            setThemeType,
            toastMessage,
            settings,
            setSettings,
            language,
            lanChange,
            isLottiePlay,
            lottieOption,
            playLottieAnimation,
            stopLottieAnimation,
            hasToken,
            setHasToken,
            initAppState,
            setAppState,
            userDetails,
            setUserDetails,
            isBackground,
            descEnFile,
            descFile,
            getDescriptorFile,
            setInitAppState,
            homeConfig,
            setHomeConfig,
            languagesList,
          }}
        >
          {props.children}
        </AppContext.Provider>
      )}
    </>
  );
};

import { Capacitor } from "@capacitor/core";
import {
  IonCard,
  IonContent,
  IonImg,
  IonItem,
  IonList,
  IonPage,
  IonThumbnail,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import PlayOrange from "../../assets/images/icons/play-orange.svg";
import Loader from "../../components/common/loader";
import RegisterHeader from "../../components/common/register-header";
import ExtraActivityCard from "../../components/extraactivitycard";
import LanguageAppText from "../../components/languageapptext";
import LanguageImage from "../../components/languageimage";
import LanguageText from "../../components/languagetext";
import VideoPlayer from "../../components/videoplayer";
import { AppContext } from "../../context/app.context";
import { isConnectedToInternet } from "../../services/network.service";
import { getTopicById } from "../../services/program.service";
import storageSevice from "../../services/storage.sevice";
import { INTRO_VIDEO_PLAYED } from "../../config/appconfig";

let isFullVideo = false;
const Topic: React.FC = () => {
  const { language, toastMessage }: any = useContext(AppContext);
  const history: any = useHistory();
  let { id }: any = useParams();
  const [loading, setLoading] = useState(true);
  const [activities, setActivities]: any = useState([]);
  const [completedActivities, setCompletedActivities]: any = useState([]);
  const [introVideo, setIntroVideo]: any = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    getDetails();

    // document.addEventListener("ionBackButton", handleBack);

    // return () => {
    //   document.removeEventListener("ionBackButton", handleBack);
    //   isFullVideo = false;
    // };
  }, []);

  const handleBack = (ev: any) => {
    ev.detail.register(101, () => {
      if (isFullVideo) {
        isFullVideo = false;
        setIsFullScreen(false);
      } else {
        history.goBack();
      }
    });
  };

  const getDetails = async () => {
    let resp: any = await getTopicById(id);
    if (resp && !resp.error) {
      const activities = resp.activities;
      const activitiesCompleted: any = resp.progress.activitiesCompleted;
      let activitiesCompletedArray: any[] = [];
      if (activitiesCompleted && activitiesCompleted != "") {
        activitiesCompletedArray = activitiesCompleted.split(",");
      }
      let otherActivities: any = [];
      let completedActivities: any = [];
      if (activities) {
        activities.map((activity: any) => {
          if (!activitiesCompletedArray.includes(activity.id.toString())) {
            otherActivities.push(activity);
          } else {
            completedActivities.push(activity);
          }
        });
      }
      let title = "",
        introVideo = "";
      if (resp.topic) {
        try {
          let parsedData = JSON.parse(resp.topic.nodeFieldData.title);
          title = parsedData[language] || parsedData["en"];

          let parsedIntroVideo = JSON.parse(resp.topic.nodeFieldData.video);
          introVideo = parsedIntroVideo[language] || parsedIntroVideo["en"];
        } catch (error) {}
      }
      setHeaderTitle(title);
      setActivities(otherActivities);
      setCompletedActivities(completedActivities);
      setIntroVideo(introVideo);
      const introVideoPlayed = await storageSevice.get(INTRO_VIDEO_PLAYED);
      let showVideo = true;
      if (introVideoPlayed) {
        try {
          const parsedData = JSON.parse(introVideoPlayed);
          showVideo = !parsedData.includes(id);
        } catch (error) {}
      }
      setShowVideo(showVideo);

      // await onLoadTopic(packageFile);
    }
    setLoading(false);
  };

  const onActivityClick = (activity: any) => {
    history.push({
      pathname: `/activity/${activity.id}`,
    });
  };
  return (
    <IonPage
      className="page-topic "
      style={showVideo ? { backgroundColor: "black" } : {}}
    >
      {!showVideo && (
        <RegisterHeader
          pageName={headerTitle}
          backBtn={true}
          customClass="header-green"
        ></RegisterHeader>
      )}
      <IonContent
        className="page-content pb-0 bg-light-orange"
        style={showVideo ? { maxWidth: "none" } : {}}
      >
        {loading ? (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Loader></Loader>
            <br />
            <LanguageAppText
              tag={"span"}
              value={"Loading, Please Wait..."}
              textKey={"loading_please_wait"}
            />
          </div>
        ) : (
          <div className="bg-light-orange">
            {showVideo && (
              <div className="page-reward-video stretch-container">
                <VideoPlayer
                  url={introVideo}
                  hideLike={true}
                  isFullScreen={true}
                  hideFullScreen
                  hideControl
                  hideProgress
                  showBottomBack={false}
                  onVideoEnd={async () => {
                    const introVideoPlayed = await storageSevice.get(
                      INTRO_VIDEO_PLAYED
                    );
                    if (introVideoPlayed) {
                      try {
                        const parsedData = JSON.parse(introVideoPlayed);
                        if (!parsedData.includes(id)) {
                          parsedData.push(id);
                          await storageSevice.set(
                            INTRO_VIDEO_PLAYED,
                            JSON.stringify(parsedData)
                          );
                        }
                      } catch (error) {}
                    } else {
                      await storageSevice.set(
                        INTRO_VIDEO_PLAYED,
                        JSON.stringify([id])
                      );
                    }
                    setShowVideo(false);
                  }}
                />
              </div>
            )}
            {!showVideo && (
              <>
                {activities && activities.length > 0 && (
                  <div className="bg-light-orange stretch-container">
                    <LanguageAppText
                      tag={"div"}
                      className="page__section-title mb-10"
                      value={"Choose an activity"}
                      textKey={"choose_activity"}
                    />
                    {activities.map((activity: any, index: number) => {
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            onActivityClick(activity);
                          }}
                        >
                          <ExtraActivityCard
                            label={activity.nodeFieldData.title}
                            thumbnail={activity.nodeFieldData.teaserImage}
                            //   midIcon={Coins}
                            //   rightIcon={PlayOrange}
                          ></ExtraActivityCard>
                        </div>
                      );
                    })}
                  </div>
                )}
                {completedActivities && completedActivities.length > 0 && (
                  <div className="mt-30">
                    <LanguageAppText
                      tag={"div"}
                      className="page__section-title"
                      value={"Completed activities"}
                      textKey={"completed_activities"}
                    />
                    <LanguageAppText
                      tag={"p"}
                      className="page-content__infotext"
                      value={"Go back to activities you already completed"}
                      textKey={"back_to_activity"}
                    />
                    {/* <div className="page__section-title">
                        Completed activities
                      </div>
                      <p className="page-content__infotext">
                        Go back to activities you already completed
                      </p> */}
                    {completedActivities.map((activity: any, index: number) => {
                      return (
                        <IonCard
                          key={index}
                          className="ion-no-margin capsul-card capsul-card__extra-activities mb-20"
                          onClick={() => {
                            onActivityClick(activity);
                          }}
                        >
                          <IonList className="ion-no-padding">
                            <IonItem>
                              <div className="capsul-card__thum-group">
                                <IonThumbnail
                                  slot="start"
                                  className="ion-no-padding ion-no-margin ion-margin-end capsul-card__thum-group_overlay relative"
                                >
                                  <LanguageImage
                                    value={activity.nodeFieldData.teaserImage}
                                  />
                                </IonThumbnail>
                                <div className="icon__completed"></div>
                              </div>

                              <div className="capsul-card__mid">
                                <LanguageText
                                  tag={"label"}
                                  className="capsul-card__label"
                                  value={activity.nodeFieldData.title}
                                ></LanguageText>
                              </div>
                              <div className="capsul-card__last">
                                <img src={PlayOrange} />
                              </div>
                            </IonItem>
                          </IonList>
                        </IonCard>
                      );
                    })}
                  </div>
                )}
                <div
                  className="page-content__link"
                  style={{ textAlign: "center" }}
                  onClick={() => {
                    history.replace("/tabs/experts");
                  }}
                >
                  <LanguageAppText
                    tag={"span"}
                    value={"Go Back To Home"}
                    textKey={"back_to_home"}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
export default Topic;

import HttpService, { HttpMethod } from './http.service'

export const register = async (data: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/user/register`,
    data: data
  })
  return response
}

export const phoneCheck = async (data: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/user/phone-check`,
    data: data
  })
  return response
}

export const loginPhoneCheck = async (data: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/user/login/phone-check`,
    data: data
  })
  return response
}

export const verifyOTP = async (data: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/auth/otp-verify`,
    data: data
  })
  return response
}

export const loginVerifyOTP = async (data: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/auth/login/otp-verify`,
    data: data
  })
  return response
}

export const resendOTP = async (data: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/user/resend-otp`,
    data: data
  })
  return response
}

export const partnersList = async() =>{
  const response = await HttpService.execute({
    url: '/partners/list'
  })
  return response
}

export const hearaboutus = async(data: any)=>{
    const response = await HttpService.execute({
      method: HttpMethod.POST,
      url: `/user/hear-about-us`,
      data: data
    })
    return response
}

export const socialLogin = async(data: any)=>{
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: '/auth/login/social',
    data: data
  })
  return response
}

export const verifyPartnerUser = async (data: any) => {
  const response = await HttpService.execute({
    method: HttpMethod.POST,
    url: `/auth/partner/verify`,
    data: data
  })
  return response
}

import "./style.scss";

import { IonContent, IonPage } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import RegisterHeader from "../../components/common/register-header";
import { userStories } from "../../services/user.service";
import StoryCard from "../../components/storycard";
import { AppContext } from "../../context/app.context";
import { getFormattedUrl, sliceIntoChunks } from "../../utils/common.helper";
import { useHistory } from "react-router";
import Loader from "../../components/common/loader";

const MyStories: React.FC = () => {
  const history = useHistory();
  const { toastMessage }: any = useContext(AppContext);
  const [stories, setStories]: any = useState([]);
  const [pendingStories, setPendingStories]: any = useState([]);
  const [loading, setLoading]: any = useState(true);
  useEffect(() => {
    fetchUserStories();
  }, []);

  const fetchUserStories = async () => {
    const res: any = await userStories();
    if (!res.error) {
      const temp = res.stories;
      setStories(temp.concat(res.submissions));
      // setPendingStories(res.submissions)
    } else {
      toastMessage(res.message, "primary");
    }
    setLoading(false);
  };

  const handlePageRedirect = (route: any) => {
    history.push({
      pathname: route,
    });
  };

  const renderStories = () => {
    const dataChunk = sliceIntoChunks(stories, 2);
    return dataChunk.map((data: any, dataIndex: number) => {
      return (
        <div key={"row-" + dataIndex} className="page-stories__card-container">
          {data.map((item: any, index: number) => {
            return (
              <StoryCard
                key={item.title + "-" + index}
                imgPath={item.thumbnail && getFormattedUrl(item.thumbnail.uri)}
                title={item.title}
                likeCount={item.likeCount}
                status={item.status}
                isActive={item.isActive}
                onClick={() => {
                  if (
                    (item.status &&
                      (item.status === "pending" ||
                        item.status === "rejected" ||
                        item.status === "approved" ||
                        item.status === "draft")) ||
                    (item.isActive != null &&
                      !item.isActive &&
                      item.status === "published")
                  ) {
                    history.push({
                      pathname: "/reviewStory",
                      state: {
                        storyDetails: item,
                      },
                    });
                  } else {
                    handlePageRedirect(`/communityStory/${item.id}`);
                  }
                }}
              ></StoryCard>
            );
          })}
        </div>
      );
    });
  };

  return (
    <>
      <IonPage className="page-my-stories">
        <RegisterHeader
          pageName="My stories"
          textKey={"my_stories"}
          backBtn={true}
        ></RegisterHeader>
        <IonContent fullscreen={true} className="page-content">
          {loading ? (
            <Loader customClass="loader-page-center" />
          ) : stories && stories.length > 0 ? (
            <div>{renderStories()}</div>
          ) : (
            <div className="no-data-container">No stories available!</div>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default MyStories;

import { IonPage, IonContent, IonImg } from "@ionic/react";
import ButtonComponent from "../common/button-component";
import RegisterHeader from "../common/register-header";
import LanguageAppText from "../languageapptext";
import Coin from "../../assets/images/coins.png";
import { useHistory } from "react-router";
import observerService from "../../services/observer.service";
import LanguageText from "../languagetext";
import { getFormattedUrl } from "../../utils/common.helper";

interface Props {
  data?: any;
}
const Reward = (props: Props) => {
  const { data } = props;
  let rewardData: any = null;
  try {
    rewardData = JSON.parse(data.data);
  } catch (error) {}
  const history: any = useHistory();
  const onContinueClick = () => {
    observerService.handleEvents("reward", { show: false });
    // if (rewardData) {
    //   if (rewardData.type === "BADGE" || rewardData.type === "COIN") {
    //     history.push({
    //       pathname: "/userprofile",
    //     });
    //   } else {
    //     history.push({
    //       pathname: "/tabs/tools",
    //     });
    //   }
    // }
  };

  return (
    <div>
      <IonImg
        src={
          rewardData
            ? rewardData.type === "COIN"
              ? Coin
              : rewardData.type === "TOOL"
              ? rewardData.iconImage
                ? getFormattedUrl(JSON.parse(rewardData.iconImage).uri)
                : ""
              : rewardData.image
              ? getFormattedUrl(rewardData.image.uri)
              : ""
            : ""
        }
        className="ion-popup__success-icon mb-20"
        style={{ width: "80%" }}
      />
      <LanguageAppText
        tag={"div"}
        className="tools-popup__title text-deep-black font-bold mb-5"
        value={"Congratulation"}
        textKey={"congratulations"}
      />
      <div className="tools-popup__detail-text text-light-black font-medium mb-20">
        {data.message}
      </div>

      <ButtonComponent
        name="Continue"
        textKey={"continue"}
        onClick={onContinueClick}
        customClass="page-congratulations__btn"
      ></ButtonComponent>
    </div>
  );
};
export default Reward;
